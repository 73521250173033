import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";

import styles from "./atlas-breadcrumb.scss";

export type BreadcrumbItemProps = object;

/**
 * @tag atlas-breadcrumb
 */
@customElement("atlas-breadcrumb")
export default class AtlasBreadcrumb extends LitElement {
    static styles = styles;

    private onSlotChange() {
        const items = this.shadowRoot.querySelector("slot").assignedElements();

        items.forEach((item: HTMLElement, index: number) => {
            item.toggleAttribute("is-first-item", index === 0);
            item.toggleAttribute("is-last-item", index === items.length - 1);
        });
    }

    /** @internal */
    public render() {
        return html`
            <div class="breadcrumb">
                <div class="breadcrumb-items">
                    <slot @slotchange=${this.onSlotChange}></slot>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-breadcrumb": AtlasBreadcrumb;
    }
}
