import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import type AtlasFilterForm from "@/components/form/atlas-filter-form/atlas-filter-form";
import type AtlasRadio from "@/components/form/atlas-radio/atlas-radio";

import { OverlayPlacement } from "@/components/display/overlay";
import DeviceController from "@/controllers/device-controller";
import { getSlotTextContent } from "@/internals/slot";
import { emit } from "@/internals/events";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import type FormControl from "@/components/form/form-control";

import "@/components/display/atlas-dropdown-button/atlas-dropdown-button";
import "@/components/display/atlas-button/atlas-button";
import "@/components/display/atlas-icon-button/atlas-icon-button";
import "@/components/display/atlas-offcanvas/atlas-offcanvas";

export type FilterProps = AtlasElementProps & {
    "description": string;
    "header": string;
    "date": boolean;
    "block": boolean;
    "dropdown-placement": OverlayPlacement;
};

/**
 * @dependency atlas-dropdown-button
 * @dependency atlas-button
 * @dependency atlas-icon-button
 * @dependency atlas-offcanvas
 *
 * @event {CustomEvent} atlas-clean-filter - Evento disparado quando o botão de limpar filtro é clicado
 * @event {CustomEvent} atlas-apply-filter - Evento disparado quando o botão de aplicar filtro é clicado
 *
 * @tag atlas-filter
 */
@customElement("atlas-filter")
export default class AtlasFilter extends AtlasElement {
    /** Define o header do filtro, será mostrado na versão mobile e no filtros avançados.  */
    @property({ type: String }) header: string;

    /** Define o texto que será apresentado no botão */
    @property({ type: String }) description = "Filtros";

    /** Altera o filtro para o comportamento específico de filtro de data. */
    @property({ type: Boolean }) date = false;

    /** Indica se o botão de filtros aparece com o estilo block */
    @property({ type: Boolean }) block = false;

    /** Define a posição do dropdown */
    @property({ type: String, attribute: "dropdown-placement" }) dropdownPlacement: OverlayPlacement;

    @state() private _openAdvanced = false;

    @state() private _counter = 0;

    private _advancedFilterCounter: number = 0;

    private _simpleFilterCounter: number = 0;

    private _startDate: string;

    private _finalDate: string;

    private _deviceController = new DeviceController(this);

    private simpleFilterForm: AtlasFilterForm;

    private advancedFilterForm: AtlasFilterForm;

    /** @internal */
    constructor() {
        super();

        this.openAdvancedFilter = this.openAdvancedFilter.bind(this);
        this.cleanFilter = this.cleanFilter.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.hideAdvancedFilter = this.hideAdvancedFilter.bind(this);
        this.updateCounter = this.updateCounter.bind(this);

        this.syncFilterForms();
    }

    public connectedCallback(): void {
        super.connectedCallback?.();

        this.updateComplete.then(() => {
            this.buildDescription();
        });
    }

    public disconnectedCallback(): void {
        super.disconnectedCallback?.();
        this.clearFilterEvents();
    }

    /** @internal */
    public async getUpdateComplete() {
        await super.getUpdateComplete();
        await Promise.all([this.simpleFilterForm?.updateComplete, this.advancedFilterForm?.updateComplete]);
        return true;
    }

    /**
     * Retorna os dados dos filtros
     * @param asFormData - Retorna os dados dos filtros em FormData
     * @returns {object | FormData} - Os dados dos filtros
     */
    public getFilterData(asFormData: boolean = false) {
        if (this.advancedFilterForm) {
            return this.getAdvancedFilterData(asFormData);
        }

        return this.getSimpleFilterData(asFormData);
    }

    /**
     * Retorna os dados do filtro simples
     * @param asFormData - Retorna os dados dos filtros em FormData
     * @returns {object | FormData} - Os dados do filtro
     */
    public getSimpleFilterData(asFormData: boolean = false) {
        return this.simpleFilterForm?.getFilterFormData(asFormData);
    }

    /**
     * Retorna os dados dos filtro avançado
     * @param asFormData - Retorna os dados dos filtros em FormData
     * @returns {object | FormData} - Os dados do filtro
     */
    public getAdvancedFilterData(asFormData: boolean = false) {
        return this.advancedFilterForm?.getFilterFormData(asFormData);
    }

    /**
     * Retorna os dados agrupados por grupo do filtro simples
     * @returns {object} - Os dados agrupados por grupo do filtro
     */
    public getGroupedSimpleFilterData() {
        return this.simpleFilterForm?.getGroupedFilterData();
    }

    /**
     * Retorna os dados agrupados por grupo do filtro avançado
     * @returns {object} - Os dados agrupados por grupo do filtro
     */
    public getGroupedAdvancedFilterData() {
        return this.advancedFilterForm?.getGroupedFilterData();
    }

    /**
     * Habilita os botões do filtro
     */
    public enableButtons() {
        this.simpleFilterForm?.enableButtons();
        this.advancedFilterForm?.enableButtons();
    }

    /**
     * Desabilita os botões do filtro
     */
    public disableButtons() {
        this.simpleFilterForm?.disableButtons();
        this.advancedFilterForm?.disableButtons();
    }

    private async syncFilterForms() {
        await this.updateComplete;

        const simpleFilterSlot = this.shadowRoot.querySelector("slot[name=simple-filter]") as HTMLSlotElement;
        const advancedFilterSlot = this.shadowRoot.querySelector("slot[name=advanced-filter]") as HTMLSlotElement;

        if (simpleFilterSlot.assignedElements()[0]) {
            this.simpleFilterForm = simpleFilterSlot.assignedElements()[0] as AtlasFilterForm;
            this.simpleFilterForm.type = "simple";
        }

        if (advancedFilterSlot.assignedElements()[0]) {
            this.advancedFilterForm = advancedFilterSlot.assignedElements()[0] as AtlasFilterForm;
            this.advancedFilterForm.type = "advanced";

            if (this._deviceController.isMobile) {
                this.simpleFilterForm.hidden = true;
            }

            this.simpleFilterForm.type = "simple";
            this.simpleFilterForm.hasAdvancedFilter = true;
        }

        this.bindFilterEvents();
    }

    private bindFilterEvents() {
        this.simpleFilterForm?.addEventListener("atlas-open-advanced-filter", this.openAdvancedFilter);

        this.simpleFilterForm?.addEventListener("atlas-clean-filter", this.cleanFilter);
        this.simpleFilterForm?.addEventListener("atlas-apply-filter", this.applyFilter);
        this.simpleFilterForm?.addEventListener("atlas-filter-close", this.hideAdvancedFilter);
        this.simpleFilterForm?.addEventListener("atlas-filter-form-count", this.updateCounter);

        this.advancedFilterForm?.addEventListener("atlas-clean-filter", this.cleanFilter);
        this.advancedFilterForm?.addEventListener("atlas-apply-filter", this.applyFilter);
        this.advancedFilterForm?.addEventListener("atlas-filter-close", this.hideAdvancedFilter);
        this.advancedFilterForm?.addEventListener("atlas-filter-form-count", this.updateCounter);

        this.addEventListener("atlas-offcanvas-close", this.hideAdvancedFilter);
    }

    private clearFilterEvents() {
        this.simpleFilterForm?.removeEventListener("atlas-open-advanced-filter", this.openAdvancedFilter);

        this.simpleFilterForm?.removeEventListener("atlas-clean-filter", this.cleanFilter);
        this.simpleFilterForm?.removeEventListener("atlas-apply-filter", this.applyFilter);
        this.simpleFilterForm?.removeEventListener("atlas-filter-close", this.hideAdvancedFilter);
        this.simpleFilterForm?.removeEventListener("atlas-filter-form-count", this.updateCounter);

        this.advancedFilterForm?.removeEventListener("atlas-clean-filter", this.cleanFilter);
        this.advancedFilterForm?.removeEventListener("atlas-apply-filter", this.applyFilter);
        this.advancedFilterForm?.removeEventListener("atlas-filter-close", this.hideAdvancedFilter);
        this.advancedFilterForm?.removeEventListener("atlas-filter-form-count", this.updateCounter);

        this.removeEventListener("atlas-offcanvas-close", this.hideAdvancedFilter);
    }

    private async cleanFilter(event: CustomEvent) {
        this.simpleFilterForm?.resetFilterForm();
        this.advancedFilterForm?.resetFilterForm();

        await this.updateComplete;

        this.updateDescription();

        event.stopPropagation();
        emit(this, "atlas-clean-filter", { detail: event.detail });
    }

    private applyFilter(event: CustomEvent) {
        const filterForm = event.target as AtlasFilterForm;

        if (filterForm.type === "advanced") {
            this.simpleFilterForm?.mergeFilterForm(this.advancedFilterForm?.getFilterFormElements());
        }

        if (filterForm.type === "simple") {
            this.advancedFilterForm?.mergeFilterForm(this.simpleFilterForm?.getFilterFormElements());
        }

        this.updateDescription();

        event.stopPropagation();
        emit(this, "atlas-apply-filter", { detail: event.detail });
    }

    private openAdvancedFilter() {
        this._openAdvanced = true;
        this.advancedFilterForm?.mergeFilterForm(this.simpleFilterForm?.getFilterFormElements());
    }

    private updateDescription() {
        this.simpleFilterForm?.countFilters();
        this.advancedFilterForm?.countFilters();
    }

    private updateCounter(event: CustomEvent) {
        const filterForm = event.target as AtlasFilterForm;

        if (filterForm.type === "advanced") {
            this._advancedFilterCounter = event.detail.counter;
        }

        if (filterForm.type === "simple") {
            this._simpleFilterCounter = event.detail.counter;
        }

        this._counter =
            this._advancedFilterCounter > this._simpleFilterCounter
                ? this._advancedFilterCounter
                : this._simpleFilterCounter;

        if (this.advancedFilterForm) {
            this.simpleFilterForm.advancedFilterCounter = this._advancedFilterCounter - this._simpleFilterCounter;
        }

        this.buildDescription();
    }

    private hideAdvancedFilter() {
        this._openAdvanced = false;
    }

    private buildDescription() {
        if (this.date) {
            this.buildDescriptionForDateFilter();
            return;
        }

        this.description = `Filtros ${this._counter > 0 ? `(${this._counter})` : ``}`;
    }

    private buildDescriptionForDateFilter() {
        const selectedRadioElement = Array.from(this.simpleFilterForm.querySelectorAll("atlas-radio")).find(
            (radioElement: AtlasRadio) => radioElement.checked
        );

        if (!selectedRadioElement) {
            this.description = `Filtros`;
            return;
        }

        if (selectedRadioElement.value === "custom") {
            const datepickers = this.querySelectorAll("atlas-date-picker") as NodeListOf<FormControl>;

            this._startDate = datepickers[0].value;
            this._finalDate = datepickers[1].value;

            this.description = `${this._startDate} - ${this._finalDate}`;
        } else {
            this.description = getSlotTextContent(selectedRadioElement.shadowRoot.querySelector("slot"));
        }
    }

    private getIcon() {
        return this.date ? "calendar" : "filter";
    }

    private renderMobileButton() {
        return html`
            <atlas-button
                description="${this.description}"
                icon="${this.getIcon()}"
                type="outlined"
                @atlas-button-click=${this.openAdvancedFilter}
                ?block=${this.block}
            ></atlas-button>

            <atlas-offcanvas class="dropdown-wrapper" header=${this.header} ?open=${this._openAdvanced} filter>
                <slot name="advanced-filter"></slot>
                <slot name="simple-filter"></slot>
            </atlas-offcanvas>
        `;
    }

    private renderButton() {
        return html`
            <atlas-dropdown-button
                description="${this.description}"
                icon="${this.getIcon()}"
                type="outlined"
                ?block=${this.block}
                dropdown-placement=${this.dropdownPlacement}
                filter
            >
                <slot name="simple-filter"></slot>
            </atlas-dropdown-button>

            <atlas-offcanvas header=${this.header} ?open=${this._openAdvanced} filter>
                <slot name="advanced-filter"></slot>
            </atlas-offcanvas>
        `;
    }

    /** @internal */
    public render() {
        const button = when(
            !this._deviceController.isMobile,
            () => this.renderButton(),
            () => this.renderMobileButton()
        );

        return html` <div class="atlas-filter">${button}</div> `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-filter": AtlasFilter;
    }
}
