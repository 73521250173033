import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import AtlasElement, { type AtlasElementProps } from "@/components/atlas-element";
import type { TableSort } from "@/components/table/atlas-table/types";
import { showAlert } from "@/helpers/notifications";
import { get } from "@/helpers/request";
import { emit } from "@/internals/events";

import type { TableColumn, TableFetchParams, TableSortingFetchParams } from "./types";

import styles from "./atlas-easy-table.scss";

import "@/components/display/atlas-empty-state/atlas-empty-state";
import "@/components/table/atlas-table/atlas-table";
import "@/components/table/atlas-table-header/atlas-table-header";
import "@/components/table/atlas-table-body/atlas-table-body";
import "@/components/table/atlas-table-footer/atlas-table-footer";
import "@/components/table/atlas-table-col/atlas-table-col";

export type EasyTableProps = AtlasElementProps & {
    "selectable": boolean;
    "has-actions": boolean;
    "hide-footer": boolean;
    "hide-footer-text": boolean;
    "search-on-render": boolean;
    "url": string;
    "params": object;
    "items-per-page": number;
    "current-page": number;
    "total-records": number;
    "footer-text": string;
    "content-name": string;
    "content-name-plural": string;
    "columns": TableColumn[];
    "empty-state-icon": string;
    "empty-state-header": string;
    "empty-state-description": string;
    "sequenced-pagination": boolean;
    "enable-line-break": boolean;
};

/**
 * @dependency atlas-empty-state
 * @dependency atlas-table
 * @dependency atlas-table-header
 * @dependency atlas-table-body
 * @dependency atlas-table-footer
 * @dependency atlas-table-col

 * @event {CustomEvent} atlas-table-before-search - Evento disparado logo antes de uma pesquisa ser feita
 * @event {CustomEvent} atlas-table-after-search - Evento disparado logo após uma pesquisa ser feita
 *
 * @slot default - No slot padrão é possível passar as linhas do primeiro carregamento, para que seja feito de forma síncrona, evitando uma nova requisição ao servidor
 * @slot empty-state-template - Nesse slot é possível informar o template do empty-state, permitindo enviar um empty-state com mais opções de personalização
 *
 * @tag atlas-easy-table
 */
@customElement("atlas-easy-table")
export default class AtlasEasyTable extends AtlasElement {
    static styles = styles;

    /** Indica que a tabela permite múltipla seleção (as linhas podem ser selecionadas) */
    @property({ type: Boolean }) selectable: boolean;

    /** Indica que a tabela contém ações associadas (renderiza a coluna de ações) */
    @property({ type: Boolean, attribute: "has-actions" }) hasActions: boolean;

    /** Indica que o rodapé da tabela não deve ser exibido */
    @property({ type: Boolean, attribute: "hide-footer" }) hideFooter: boolean;

    /** Indica que o texto do rodapé da tabela não deve ser exibido */
    @property({ type: Boolean, attribute: "hide-footer-text" }) hideFooterText: boolean;

    /** Indica que a busca dos registros deve ser feita ao renderizar a tabela */
    @property({ type: Boolean, attribute: "search-on-render" }) searchOnRender: boolean;

    /** Endereço do servidor para a busca dos registros */
    @property({ type: String }) url: string;

    /** Parâmetros de busca que serão enviados na requisição de busca dos registros */
    @property({ type: Object }) params: { [key: string]: any } = {};

    /** Número de registros (linhas) que serão carregados por página */
    @property({ type: Number, attribute: "items-per-page" }) itemsPerPage = 10;

    /** Página atual da tabela */
    @property({ type: Number, attribute: "current-page", reflect: true }) currentPage = 1;

    /** Total de registros existentes (incluindo todas as páginas) */
    @property({ type: Number, attribute: "total-records", reflect: true }) totalRecords = 0;

    /** Texto que aparece no rodapé (substitui a informação sobre o número de registros da página) */
    @property({ type: String, attribute: "footer-text", reflect: true }) footerText: string;

    /** Nome do conteúdo que será exibido no rodapé da tabela */
    @property({ type: String, attribute: "content-name" }) contentName: string;

    /** Plural do nome do conteúdo que será exibido no rodapé da tabela */
    @property({ type: String, attribute: "content-name-plural" }) contentNamePlural: string;

    /** Um array com as configurações das colunas da tabela */
    @property({ type: Array }) columns: TableColumn[] = [];

    /** Ícone do empty-state que é exibido quando a tabela está vazia */
    @property({ type: String, attribute: "empty-state-icon" }) emptyStateIcon: string;

    /** Título do empty-state que é exibido quando a tabela está vazia */
    @property({ type: String, attribute: "empty-state-header" }) emptyStateHeader: string;

    /** Descrição do empty-state que é exibido quando a tabela está vazia */
    @property({ type: String, attribute: "empty-state-description" }) emptyStateDescription: string;

    /** Nome da coluna que está sendo ordenada */
    @property({ type: String, attribute: "sorted-column" }) sortedColumn: string;

    /** Ordenação da coluna (asc ou desc) */
    @property({ type: String, attribute: "sorted-order" }) sortedOrder: TableSort;

    /** Indica que a paginação deve ser sequencial (é possível navegar apenas para a página anterior e a próxima) */
    @property({ type: Boolean, attribute: "sequenced-pagination" }) sequencedPagination: boolean;

    /** Indica que a quebra de linha no conteúdo das colunas da tabela deve ser habilitada */
    @property({ type: Boolean, attribute: "enable-line-break" }) enableLineBreak: boolean = false;

    /** @internal */
    public connectedCallback(): void {
        super.connectedCallback?.();

        if (!this.currentPage || isNaN(this.currentPage)) this.currentPage = 1;

        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.addEventListener("atlas-table-page-change", this.onPageChange);
        this.addEventListener("atlas-table-change-sorting", this.onSortChange);

        if (this.searchOnRender) {
            setTimeout(() => {
                this.fetchRecords();
            }, 1000);
        }
    }

    /** @internal */
    public disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-table-page-change", this.onPageChange);
        this.removeEventListener("atlas-table-change-sorting", this.onSortChange);
    }

    /**
     * Realiza a busca dos registros e atualiza o conteúdo da tabela
     *
     * @param {boolean} shouldGoToFirstPage - Indica se a tabela deve ir para a primeira página após a busca
     * @param {number} previousPage - Página anterior
     * @param {boolean} recordWasRemoved - Indica se algum registro foi removido (para ajustar a paginação conforme necessário)
     *
     * @returns {Promise<void>}
     */
    public async fetchRecords(
        shouldGoToFirstPage?: boolean,
        previousPage?: number,
        recordWasRemoved?: boolean
    ): Promise<void> {
        await this.updateComplete;

        this.skeletonLoading = true;
        emit(this, "atlas-table-before-search");

        setTimeout(() => {
            const params = this.getFetchParams();

            const totalRecords = recordWasRemoved ? this.totalRecords - 1 : this.totalRecords;
            const totalPages = Math.ceil(totalRecords / this.itemsPerPage);
            const shouldGoToPreviousPage = recordWasRemoved && this.currentPage > 1 && this.currentPage > totalPages;

            if (shouldGoToFirstPage) {
                params.page = 1;
            } else if (shouldGoToPreviousPage) {
                params.page = this.currentPage - 1;
            }

            get(this.url, params).then((response) => {
                if (!response.success) {
                    this.onFetchError(response, previousPage);
                    return;
                }

                const defaultSlot = this.shadowRoot.querySelector("slot:not([name])") as HTMLSlotElement;
                defaultSlot.assignedElements({ flatten: true }).forEach((element) => element.remove());

                this.innerHTML += response.content;
                this.totalRecords = response.totalRecords;

                if (shouldGoToFirstPage) {
                    this.currentPage = 1;
                } else if (shouldGoToPreviousPage) {
                    this.currentPage -= 1;
                }

                setTimeout(() => {
                    this.skeletonLoading = false;

                    emit(this, "atlas-table-after-search", {
                        detail: { response }
                    });
                }, 1000);
            });
        }, 500);
    }

    /**
     * Retorna os parâmetros que serão enviados para o backend para a busca dos registros
     *
     * @returns {TableFetchParams} - Objeto com os parâmetros de busca
     */
    public getFetchParams(): TableFetchParams {
        return {
            ...this.params,
            page: this.currentPage,
            itemsPerPage: this.itemsPerPage,
            ...this.getSortingParams()
        };
    }

    /**
     * Retorna os parâmetros de ordenação que serão enviados para o backend para a busca dos registros
     *
     * @returns {TableSortingFetchParams} - Objeto com os parâmetros de ordenação
     */
    public getSortingParams(): TableSortingFetchParams {
        if (!this.sortedColumn || !this.sortedOrder || this.sortedOrder === "none") return {};

        return {
            sort: this.sortedColumn,
            order: this.sortedOrder
        };
    }

    private onPageChange(event: CustomEvent) {
        const { page, previousPage } = event.detail;

        this.currentPage = page;
        this.fetchRecords(false, previousPage);
    }

    private onSortChange(event: CustomEvent) {
        const { column, order } = event.detail;

        this.sortedColumn = column;
        this.sortedOrder = order;
        this.fetchRecords();
    }

    private onFetchError(response?: any, previousPage?: number) {
        const feedbackMessage = response.message || "Não foi possível carregar a listagem, tente novamente.";

        if (previousPage) this.currentPage = previousPage;
        this.skeletonLoading = false;
        showAlert(feedbackMessage, "ERROR");

        emit(this, "atlas-table-after-search", {
            detail: { response }
        });
    }

    private renderHeaderContent() {
        return this.columns.map(
            (column) => html`
                <atlas-table-col
                    name=${column.name}
                    size=${column.size || "md"}
                    ?sortable=${column.sortable}
                    ?ellipsis=${column.ellipsis}
                    ?disable-line-break=${column.disableLineBreak}
                    sort=${column.name === this.sortedColumn ? this.sortedOrder || "none" : "none"}
                    popover-title=${ifDefined(column.popoverTitle)}
                    popover-content=${ifDefined(column.popoverContent)}
                >
                    ${column.label}
                </atlas-table-col>
            `
        );
    }

    private renderTable() {
        return html`
            <atlas-table
                ?selectable=${this.selectable}
                ?has-actions=${this.hasActions}
                ?enable-line-break=${this.enableLineBreak}
            >
                <atlas-table-header slot="header">${this.renderHeaderContent()}</atlas-table-header>
                <atlas-table-body slot="body">
                    <slot></slot>
                </atlas-table-body>
                <atlas-table-footer
                    slot="footer"
                    items-per-page=${this.itemsPerPage}
                    current-page=${this.currentPage}
                    total-items=${this.totalRecords}
                    footer-text=${this.footerText}
                    content-name=${this.contentName}
                    content-name-plural=${this.contentNamePlural}
                    ?hide-footer-text=${this.hideFooterText}
                    ?hidden=${this.hideFooter}
                    ?sequenced-pagination=${this.sequencedPagination}
                ></atlas-table-footer>
            </atlas-table>
        `;
    }

    private renderEmptyState() {
        return when(
            !this.emptyStateIcon || !this.emptyStateHeader || !this.emptyStateDescription,
            () => html`<slot name="empty-state-template"></slot>`,
            () => html`
                <atlas-empty-state
                    icon=${this.emptyStateIcon}
                    header=${this.emptyStateHeader}
                    description=${this.emptyStateDescription}
                ></atlas-empty-state>
            `
        );
    }

    /** @internal @override */
    public render() {
        return html`
            <div class="easy-table">
                ${when(
                    this.totalRecords > 0 || this.skeletonLoading,
                    () => this.renderTable(),
                    () => this.renderEmptyState()
                )}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-easy-table": AtlasEasyTable;
    }
}
