import {css} from "lit-element/lit-element.js";
export default css`:host {
  display: none;
  width: 100%;
}

:host([visible]) {
  display: inline-flex;
}

.step-content {
  display: block;
  width: 100%;
}
.step-content:not(.is-modal-wizard).off-step {
  margin-top: 0.5rem;
}

::slotted(atlas-section:last-child) {
  margin-bottom: 0;
}

@media screen and (min-width: 992px) {
  .step-content:not(.is-modal-wizard).off-step {
    margin-top: 2rem;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2NvZGVidWlsZC9vdXRwdXQvc3JjNTIxMzU0MzQxL3NyYy9wYWNrYWdlcy93ZWJjb21wb25lbnRzL3NyYy9jb21wb25lbnRzL3dpemFyZC9hdGxhcy13aXphcmQtc3RlcCIsInNvdXJjZXMiOlsiYXRsYXMtd2l6YXJkLXN0ZXAuc2NzcyIsIi4uLy4uLy4uL3N0eWxlcy9taXhpbnMvX2JyZWFrcG9pbnRzLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDSTtFQUNBOzs7QUFHSjtFQUNJOzs7QUFHSjtFQUNJO0VBQ0E7O0FBRUE7RUFDSTs7O0FBSVI7RUFDSTs7O0FDUkE7RURZQTtJQUNJIiwic291cmNlc0NvbnRlbnQiOlsiQHVzZSBcIi4uLy4uLy4uL3N0eWxlcy9taXhpbnMvYnJlYWtwb2ludHNcIjtcblxuOmhvc3Qge1xuICAgIGRpc3BsYXk6IG5vbmU7XG4gICAgd2lkdGg6IDEwMCU7XG59XG5cbjpob3N0KFt2aXNpYmxlXSkge1xuICAgIGRpc3BsYXk6IGlubGluZS1mbGV4O1xufVxuXG4uc3RlcC1jb250ZW50IHtcbiAgICBkaXNwbGF5OiBibG9jaztcbiAgICB3aWR0aDogMTAwJTtcblxuICAgICY6bm90KC5pcy1tb2RhbC13aXphcmQpLm9mZi1zdGVwIHtcbiAgICAgICAgbWFyZ2luLXRvcDogMC41cmVtO1xuICAgIH1cbn1cblxuOjpzbG90dGVkKGF0bGFzLXNlY3Rpb246bGFzdC1jaGlsZCkge1xuICAgIG1hcmdpbi1ib3R0b206IDA7XG59XG5cbkBpbmNsdWRlIGJyZWFrcG9pbnRzLmJyZWFrcG9pbnQtdXAtbGcge1xuICAgIC5zdGVwLWNvbnRlbnQ6bm90KC5pcy1tb2RhbC13aXphcmQpLm9mZi1zdGVwIHtcbiAgICAgICAgbWFyZ2luLXRvcDogMnJlbTtcbiAgICB9XG59XG4iLCJAbWl4aW4gYnJlYWtwb2ludC11cC1zbSB7XG4gICAgQG1lZGlhIHNjcmVlbiBhbmQgKG1pbi13aWR0aDogNTc2cHgpIHtcbiAgICAgICAgQGNvbnRlbnQ7XG4gICAgfVxufVxuXG5AbWl4aW4gYnJlYWtwb2ludC11cC1tZCB7XG4gICAgQG1lZGlhIHNjcmVlbiBhbmQgKG1pbi13aWR0aDogNzY4cHgpIHtcbiAgICAgICAgQGNvbnRlbnQ7XG4gICAgfVxufVxuXG5AbWl4aW4gYnJlYWtwb2ludC11cC1sZyB7XG4gICAgQG1lZGlhIHNjcmVlbiBhbmQgKG1pbi13aWR0aDogOTkycHgpIHtcbiAgICAgICAgQGNvbnRlbnQ7XG4gICAgfVxufVxuXG5AbWl4aW4gYnJlYWtwb2ludC11cC14bCB7XG4gICAgQG1lZGlhIHNjcmVlbiBhbmQgKG1pbi13aWR0aDogMTIwMHB4KSB7XG4gICAgICAgIEBjb250ZW50O1xuICAgIH1cbn1cblxuQG1peGluIGJyZWFrcG9pbnQtdXAteHhsIHtcbiAgICBAbWVkaWEgc2NyZWVuIGFuZCAobWluLXdpZHRoOiAxNDAwcHgpIHtcbiAgICAgICAgQGNvbnRlbnQ7XG4gICAgfVxufVxuXG5AbWl4aW4gYnJlYWtwb2ludC1kb3duLWxnIHtcbiAgICBAbWVkaWEgc2NyZWVuIGFuZCAobWF4LXdpZHRoOiA5OTJweCkge1xuICAgICAgICBAY29udGVudDtcbiAgICB9XG59XG4iXX0= */`;
