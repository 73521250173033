import { html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { arrow, Middleware, offset } from "@floating-ui/dom";

import DeviceController from "@/controllers/device-controller";

import OverlayElement from "@/components/display/overlay-element";
import styles from "./atlas-popover.scss";
import "@/components/display/atlas-icon/atlas-icon";

/**
 * @tag atlas-popover
 */
@customElement("atlas-popover")
export default class AtlasPopover extends OverlayElement {
    static styles = styles;

    /** Título do popover */
    @property({ type: String }) header: string;

    /** Largura do popover */
    @property({ type: Number }) width: number;

    /** Altura máxima do popover */
    @property({ type: Number, attribute: "max-height" }) maxHeight: number;

    /** String que indica onde deve ser clicado para fechar o overlay (Apenas quando a flag de auto-close está habilitada) */
    @property({ type: String, attribute: "auto-close-trigger" }) autoCloseTrigger: "any" | "inside" | "outside" =
        "outside";

    /** Booleano que indica se o comportamento de fechamento automático está habilitado */
    @property({ type: Boolean, attribute: "disable-auto-close" }) disableAutoClose: boolean;

    /**
     * @internal
     * Indica que o popover está sendo utilizado em um contexto de menu,
     * adicionando estilos específicos para este caso.
     */
    @property({ type: Boolean, attribute: "in-menu-context" }) inMenuContext = false;

    @state() private hasImage = false;

    @state() private hasButtonLink = false;

    @query(".popover")
    private _popover?: HTMLElement;

    private _deviceController = new DeviceController(this);

    constructor() {
        super("click", "top");

        this._floatingUiController.getCustomMiddlewares = this.getFloatingCustomMiddlewares.bind(this);
        this.onDocumentClick = this.onDocumentClick.bind(this);
    }

    public getFloatingElement(): HTMLElement {
        return this._popover || this;
    }

    private getFloatingCustomMiddlewares() {
        const middlewares: Middleware[] = [];

        if (this.inMenuContext) middlewares.push(offset({ mainAxis: -8 }));

        middlewares.push(arrow({ element: this.shadowRoot.querySelector(".popover-arrow") }));

        return middlewares;
    }

    protected async onOpenOverlay() {
        await super.onOpenOverlay();

        document.addEventListener("click", this.onDocumentClick);

        this._triggerController.triggerElement.setAttribute("active", "");
        this._triggerController.triggerElement.setAttribute("aria-expanded", "true");
    }

    protected async onCloseOverlay() {
        await super.onCloseOverlay();

        document.removeEventListener("click", this.onDocumentClick);

        this._triggerController.triggerElement.removeAttribute("active");
        this._triggerController.triggerElement.setAttribute("aria-expanded", "false");
    }

    private onDocumentClick(event: PointerEvent) {
        if (this.disableAutoClose || !this.open) {
            return;
        }

        const composedPath = event.composedPath();
        const isToggleTarget = composedPath.includes(this._triggerController.triggerElement);
        const isMenuTarget = composedPath.includes(this._popover);

        if (
            isToggleTarget ||
            (this.autoCloseTrigger === "inside" && !isMenuTarget) ||
            (this.autoCloseTrigger === "outside" && isMenuTarget)
        ) {
            return;
        }

        this.hide();
    }

    private renderImage() {
        this.hasImage = true;
    }

    private renderButtonLink() {
        this.hasButtonLink = true;
    }

    private renderCloseButton() {
        return when(
            !this.inMenuContext,
            () => html`
                <button class="popover-close" @click=${this.hide}>
                    <atlas-icon name="x" size="2x"></atlas-icon>
                </button>
            `
        );
    }

    public render() {
        const maxHeight = this.maxHeight > 180 ? `${this.maxHeight}px` : "none";
        const width = this.width > 0 ? `${this.width}px` : "max-content";

        const popoverClasses = {
            "popover": true,
            "popover-auto": true,
            "has-image": this.hasImage,
            "has-button-link": this.hasButtonLink,
            "mobile-popover": this._deviceController.isMobile,
            "fade": true,
            "show": this.open,
            "in-menu-context": this.inMenuContext
        };

        const bodyClasses = {
            "popover-body": true,
            "has-title": !!this.header
        };

        const backdropClass = {
            "atlas-popover-backdrop": true,
            "show": this.open && this._deviceController.isMobile
        };

        return html`
            <div class="${classMap(popoverClasses)}" style="max-height: ${maxHeight}; width: ${width}">
                <div class="popover-arrow"></div>
                ${this.renderCloseButton()}
                <div class="popover-content">
                    <div class="popover-header" ?hidden=${!this.header}>${this.header}</div>
                    <div class="popover-image">
                        <slot name="image" @slotchange="${this.renderImage}"></slot>
                    </div>
                    <div class="${classMap(bodyClasses)}">
                        <slot></slot>

                        <div class="popover-button-link">
                            <slot name="button-link" @slotchange="${this.renderButtonLink}"></slot>
                        </div>
                    </div>
                </div>
            </div>
            <div class="${classMap(backdropClass)}"></div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-popover": AtlasPopover;
    }
}
