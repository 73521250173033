import { LitElement, html, nothing } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";
import { Map as ImmutableMap } from "immutable";

import BaseController from "@/internals/base-controller";
import { emit } from "@/internals/events";
import { closeAllAlerts } from "@/components/display/atlas-alert/alert-system";

import type { Theme } from "@/internals/theme";
import type { BaseStepController, CustomStepButton } from "./types";
import type AtlasButton from "@/components/display/atlas-button/atlas-button";
import type AtlasWizardStep from "@/components/wizard/atlas-wizard-step/atlas-wizard-step";
import type AtlasWizardFinishStep from "@/components/wizard/atlas-wizard-finish-step/atlas-wizard-finish-step";

import styles from "./atlas-wizard.scss";
import "@/components/display/atlas-button/atlas-button";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/wizard/atlas-stepper/atlas-stepper";

/**
 * @dependency atlas-button
 * @dependency atlas-icon
 * @dependency atlas-stepper
 *
 * @slot - Usado para incluir o conteúdo da wizard
 *
 * @event {CustomEvent} atlas-wizard-change-step - Evento disparado quando é alterado de passo
 * @event {CustomEvent} atlas-wizard-previous-step - Evento disparado quando é clicado no botão "Anterior" para voltar um passo
 * @event {CustomEvent} atlas-wizard-next-step - Evento disparado quando é clicado no botão "Próximo" para avançar um passo
 * @event {CustomEvent} atlas-wizard-close - Evento disparado quando é clicado nos botões de fechar da wizard
 * @event {CustomEvent} atlas-wizard-finish - Evento disparado quando é clicado nos botões de "Finalizar" da wizard
 *
 * @tag atlas-wizard
 */
@customElement("atlas-wizard")
export default class AtlasWizard extends LitElement {
    static styles = styles;

    /** Título da wizard */
    @property({ type: String }) header: string;

    /** Imagem que irá aparecer no cabeçalho da wizard, no lugar do título */
    @property({ type: String, attribute: "header-image" }) headerImage: string;

    /** Texto descritivo da imagem do cabeçalho */
    @property({ type: String, attribute: "header-image-description" }) headerImageDescription: string;

    /** Indica se o stepper da wizard vai ficar escondido */
    @property({ type: Boolean, attribute: "hide-stepper" }) hideStepper = false;

    /** Indica se o botão de fechar a wizard, que aparece no rodapé, deve ficar oculto */
    @property({ type: Boolean, attribute: "hide-close-button" }) hideCloseButton = false;

    /** Indica se o botão de fechar a wizard, que aparece no cabeçalho, deve ficar oculto */
    @property({ type: Boolean, attribute: "hide-header-close-button" }) hideHeaderCloseButton = false;

    /** Indica que o o cabeçalho da wizard deve ter apenas o botão de fechar (apenas para os passos inicial e final) */
    @property({ type: Boolean, attribute: "only-header-close-button" }) onlyHeaderCloseButton = false;

    /** Indica se as ações de voltar da wizard serão desabilitadas */
    @property({ type: Boolean, attribute: "disable-return" }) disableReturn: boolean = false;

    /** Texto do botão de "Fechar" da wizard */
    @property({ type: String, attribute: "close-button-label" }) closeButtonLabel: string = "Fechar";

    /** Tema do botão de "Fechar" da wizard */
    @property({ type: String, attribute: "close-button-theme" }) closeButtonTheme: Theme = "secondary";

    /** Texto do botão de "Finalizar" da wizard */
    @property({ type: String, attribute: "finish-button-label" }) finishButtonLabel: string = "Finalizar";

    /** Tema do botão de "Finalizar" da wizard */
    @property({ type: String, attribute: "finish-button-theme" }) finishButtonTheme: Theme = "success";

    /** Indica se o cabeçalho da wizard será ocultado */
    @property({ type: Boolean, attribute: "hide-header" }) hideHeader = false;

    /**
     * @internal
     * Indica se a wizard será exibida dentro de um modal
     */
    @property({ type: Boolean, attribute: "is-modal-wizard" }) isModalWizard = false;

    @state() private _steps: AtlasWizardStep[] = [];

    @state() private _currentStep: string;

    @state() private _currentStepIndex: number = 0;

    @state() private _stepControllers: Map<string, BaseController> = new Map();

    @state() private _wizardConfig: ImmutableMap<string, any> = ImmutableMap();

    public connectedCallback(): void {
        super.connectedCallback?.();

        this.addEventListener("atlas-wizard-step-go", this.goToStep);
        this.addEventListener("atlas-wizard-step-changed", this.syncSteps);
    }

    public disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-wizard-step-go", this.goToStep);
        this.removeEventListener("atlas-wizard-step-changed", this.syncSteps);
    }

    /**
     * Define as configurações da wizard em um objeto imutável
     * @param config - Configurações da wizard
     */
    public setWizardConfig(config: object) {
        this._wizardConfig = ImmutableMap(config);
    }

    /**
     * Atualiza as configurações do wizard
     * @param config - Configurações da wizard
     */
    public updateWizardConfig(config: object) {
        this._wizardConfig = this._wizardConfig.merge(config);
    }

    /**
     * Retorna as configurações da wizard
     * @returns {ImmutableMap<string, any>} - Configurações da wizard
     */
    public getWizardConfig() {
        return this._wizardConfig;
    }

    /**
     * Retorna o índice do passo a partir do nome
     * @param stepName - Nome do passo
     * @returns {number} - Índice do passo
     */
    public getStepIndex(stepName: string) {
        return this._steps.findIndex((step) => step.name === stepName);
    }

    /**
     * Define um valor no state de um passo
     * @param stepName - Nome do passo
     * @param key - Chave do state ou um objeto com as chaves e valores que serão setados
     * @param value - Valor do state
     */
    public setStepState(stepName: string, key: string | { [key: string]: any }, value?: any) {
        this.getStepController(stepName)?.setState(key, value);
    }

    /**
     * Retorna o state de um passo
     * @param stepName - Nome do passo
     * @returns {object} - State do passo
     */
    public getStepState(stepName: string) {
        return this.getStepController(stepName)?.getState();
    }

    /**
     * Limpa o state de um passo
     * @param stepName - Nome do passo
     * @param key - Chave do state ou um array de chaves
     */
    public clearStepState(stepName: string, key: string | string[]) {
        this.getStepController(stepName)?.clearState(key);
    }

    /**
     * Retorna o state completo da wizard
     * @param merged - Indica se o state dos passos será retornado em um único objeto
     * @returns {object} - State da wizard
     */
    public getFullState(merged?: boolean) {
        const fullState: { [key: string]: any } = {};

        this._stepControllers.forEach((stepController) => {
            fullState[stepController.stepReference.name] = stepController.stepState;
        });

        return !merged ? fullState : Object.values(fullState).reduce((acc, cur) => ({ ...acc, ...cur }), {});
    }

    /**
     * Retorna o controller de um passo
     * @param stepName - Nome do passo
     * @returns {BaseController} - Controller do passo
     */
    public getStepController(stepName: string) {
        return this._stepControllers.get(stepName);
    }

    /**
     * Retorna o controller de um passo a partir do índice
     * @param stepIndex - Índice do passo
     * @returns {BaseController} - Controller do passo
     */
    public getStepControllerByIndex(stepIndex: number) {
        return this.getStepController(this._steps[stepIndex].name);
    }

    /**
     * Retorna todos os controllers dos passos
     * @returns {Record<string, BaseController>} - Mapa com os controllers de todos os passos
     */
    public getStepsControllers() {
        const controllers: Record<string, BaseController> = {};

        this._stepControllers.forEach((controller, stepName) => {
            controllers[stepName] = controller;
        });

        return controllers;
    }

    /**
     * Retorna todos os custom controllers dos passos
     * @returns {Record<string, BaseController>} - Mapa com os custom controllers de todos os passos
     */
    public getStepsCustomControllers() {
        const customControllers: Record<string, BaseStepController> = {};

        this._stepControllers.forEach((controller, stepName) => {
            if (!controller.customController) return;

            customControllers[stepName] = controller.customController;
        });

        return customControllers;
    }

    /**
     * Adiciona um controller customizado a um passo
     * @param stepName - Nome do passo
     * @param customController - Controller customizado
     */
    public addStepController(stepName: string, customController: BaseStepController) {
        let stepController: BaseController;

        if (this._stepControllers.has(stepName)) {
            stepController = this.getStepController(stepName);
            stepController.setCustomController(customController);
        } else {
            stepController = new BaseController();
            stepController.setCustomController(customController);

            this._stepControllers.set(stepName, stepController);
        }

        stepController.init();
    }

    /**
     * Retorna a referência de um passo
     * @param stepName - Nome do passo
     * @returns {AtlasWizardStep} - Referência do passo
     */
    public getStepReference(stepName: string) {
        return this.getStepController(stepName)?.stepReference;
    }

    /**
     * Retorna todas as referências de passos
     * @returns {Record<string, AtlasWizardStep>} - Objeto com nome do passo e referência
     */
    public getStepsReferences() {
        const references: Record<string, AtlasWizardStep> = {};

        this._steps.forEach((step) => {
            references[step.name] = this.getStepReference(step.name);
        });

        return references;
    }

    /**
     * Retorna a referência do passo atual
     * @returns {AtlasWizardStep} - Referência do passo atual
     */
    public getCurrentStep() {
        return this.getStepReference(this._currentStep);
    }

    /**
     * Navega para um passo a partir do nome dele
     * @param stepName - Nome do passo
     * @param elementToScroll - Elemento que será focado após a navegação
     * @param ignoreValidation - Indica que a validação do passo será ignorada
     */
    public goToStepWithName(stepName: string, elementToScroll?: string, ignoreValidation?: boolean) {
        const stepIndex = this.getStepIndex(stepName);

        this.changeStep(stepIndex, elementToScroll, ignoreValidation);
    }

    /**
     * Habilita ou desabilita os eventos dos uploaders de um passo
     * @internal
     * @param step - Referência do passo que contém os uploaders
     * @param isVisible - Indica se os eventos dos uploaders serão habilitados ou desabilitados
     */
    public toggleUploaderEvents(step: AtlasWizardStep, isVisible: boolean) {
        step.querySelectorAll("atlas-uploader").forEach((uploader) => {
            uploader.toggleAttribute("disabled", !isVisible);
        });
    }

    /**
     * Faz o scroll da wizard para o topo
     */
    public scrollToTop() {
        this.shadowRoot.querySelector(".wizard-body").scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    /**
     * Retorna a referência do botão de "Finalizar" da wizard, caso exista.
     * @returns { AtlasButton | null } - Referência do botão de "Finalizar" da wizard
     */
    public getFinishButton(): AtlasButton | null {
        if (!this.shouldRenderFinishButton()) return null;

        return this.shadowRoot.querySelector(".atlas-wizard-finish-button") as AtlasButton;
    }

    private async onChangeSlot() {
        await this.updateComplete;

        this.syncSteps();
        if (this._steps.length === 0) return;

        this._steps.forEach((step) => {
            step.toggleAttribute("is-modal-wizard", this.isModalWizard);

            let stepController = this.getStepController(step.name);

            if (stepController) {
                stepController.setStepReference(step);
            } else {
                stepController = new BaseController();
                stepController.setStepReference(step);

                this._stepControllers.set(step.name, stepController);
            }

            step.setStepController(stepController);
            stepController.init();
        });

        const activeStep = this._steps.find((step) => step.active && !step.disabled);
        this.setActiveStep(activeStep?.name);
    }

    private setActiveStep(activeStepName: string) {
        const activeStepIndex = this.getStepIndex(activeStepName);
        this._currentStepIndex = activeStepIndex >= 0 ? activeStepIndex : 0;
        this.changeStep(this._currentStepIndex);
    }

    private syncSteps() {
        const stepElements = ["ATLAS-WIZARD-STEP", "ATLAS-WIZARD-INTRO-STEP", "ATLAS-WIZARD-FINISH-STEP"];
        const defaultSlot = this.shadowRoot.querySelector("slot:not([name])") as HTMLSlotElement;

        this._steps = defaultSlot
            .assignedElements()
            .filter((element) => stepElements.includes(element.tagName))
            .map((step: AtlasWizardStep) => step);
    }

    private showCurrentStep() {
        this._steps.forEach((step: AtlasWizardStep) => {
            const isVisible = step.name === this._currentStep;

            step.toggleStep(isVisible);
            this.toggleUploaderEvents(step, isVisible);

            if (isVisible) {
                this.getStepController(step.name).onShowStep();
            }
        });
    }

    private getNextEnabledIndex(stepIndex: number): number {
        const nextStepIndex = stepIndex;

        if (!this._steps[stepIndex].disabled) {
            return nextStepIndex;
        }

        return this.getNextEnabledIndex(stepIndex < this._currentStepIndex ? stepIndex - 1 : stepIndex + 1);
    }

    private async canChangeStep(stepIndex: number, ignoreValidation?: boolean): Promise<boolean> {
        if (stepIndex <= this._currentStepIndex || ignoreValidation) {
            return true;
        }

        const stepController = this.getStepControllerByIndex(this._currentStepIndex);
        const isValid = await stepController.validate();

        return isValid;
    }

    private async changeStep(stepIndex: number, elementToScroll?: string, ignoreValidation?: boolean) {
        const canChange = await this.canChangeStep(stepIndex, ignoreValidation);

        if (!canChange) return;

        if (stepIndex > this._currentStepIndex) {
            const hasSubmitted = await this.getStepControllerByIndex(this._currentStepIndex).onSubmitStep();

            if (!hasSubmitted) return;
        }

        const previousStepIndex = this._currentStepIndex;
        this._currentStepIndex = this.getNextEnabledIndex(stepIndex);
        this._currentStep = this._steps[this._currentStepIndex].name;

        this.getStepControllerByIndex(this._currentStepIndex).setElementToScroll(elementToScroll);
        this.showCurrentStep();

        closeAllAlerts();

        emit(this, "atlas-wizard-change-step", {
            detail: {
                step: this._steps[this._currentStepIndex],
                previousStep: this._steps[previousStepIndex]
            }
        });
    }

    private previousStep() {
        this.changeStep(this._currentStepIndex - 1);
        emit(this, "atlas-wizard-previous-step");
    }

    private nextStep(ignoreValidation?: boolean) {
        this.changeStep(this._currentStepIndex + 1, null, ignoreValidation);
        emit(this, "atlas-wizard-next-step");
    }

    private closeWizard() {
        emit(this, "atlas-wizard-close");
    }

    private async finishWizard() {
        const stepController = this.getStepControllerByIndex(this._currentStepIndex);

        const isValid = await stepController.validate();
        if (!isValid) return;

        const hasSubmitted = await stepController.onSubmitStep();
        if (!hasSubmitted) return;

        emit(this, "atlas-wizard-finish", {
            detail: {
                steps: this._steps.filter((step) => !step.disabled)
            }
        });
    }

    private goToStep(event: CustomEvent) {
        event.stopPropagation();

        const { step, element } = event.detail;
        const stepName = step || (event.target as AtlasWizardStep).name;

        this.goToStepWithName(stepName, element);
    }

    private isReturnDisabled() {
        return this.disableReturn || this.getStepReference(this._currentStep)?.disableReturn;
    }

    private isIntroOrFinishStep() {
        return this.getStepReference(this._currentStep)?.isOffStep();
    }

    private isOnlyHeaderCloseButton() {
        return this.onlyHeaderCloseButton && !this.hideHeaderCloseButton && this.isIntroOrFinishStep();
    }

    private finishStepIsOnlyInfo() {
        const lastStepName = this._steps[this._steps.length - 1].name;
        const lastStep = this.getStepReference(lastStepName);

        if (!lastStep.isOffStep()) return false;

        return (lastStep as AtlasWizardFinishStep).onlyInfo;
    }

    private shouldRenderCloseButton() {
        return !this.hideCloseButton && this._currentStepIndex === 0;
    }

    private shouldRenderBackButton() {
        const stepReference = this.getStepReference(this._currentStep);

        if (stepReference?.hideButtons || this.isReturnDisabled()) {
            return false;
        }

        return this._currentStepIndex > 0 && this._steps.length > 1;
    }

    private shouldRenderFinishButton() {
        const stepReference = this.getStepReference(this._currentStep);

        if (this._steps.length <= 0 || stepReference?.hideButtons) return false;

        let lastStepIndex = this._steps.length - 1;

        if (this.finishStepIsOnlyInfo()) lastStepIndex = this._steps.length - 2;

        return this._currentStepIndex === lastStepIndex;
    }

    private shouldRenderNextButton() {
        if (this._steps.length <= 1) return false;

        let lastStepIndex = this._steps.length - 1;

        if (this.finishStepIsOnlyInfo()) lastStepIndex = this._steps.length - 2;

        return this._currentStepIndex !== lastStepIndex;
    }

    private renderStepper() {
        return when(
            !this.hideStepper && this._steps.length > 0 && !this.isIntroOrFinishStep(),
            () => html`
                <atlas-stepper
                    .steps=${this._steps}
                    current-step=${this._currentStep}
                    current-step-index=${this._currentStepIndex}
                    ?disable-return=${this.isReturnDisabled()}
                ></atlas-stepper>
            `
        );
    }

    private renderCloseButton() {
        return when(
            this.shouldRenderCloseButton(),
            () => html`
                <atlas-button
                    size="md"
                    theme=${this.closeButtonTheme || "secondary"}
                    description=${this.closeButtonLabel || "Fechar"}
                    @atlas-button-click=${this.closeWizard}
                ></atlas-button>
            `
        );
    }

    private renderBackButton() {
        return when(
            this.shouldRenderBackButton(),
            () => html`
                <atlas-button
                    size="md"
                    theme="secondary"
                    description="Voltar"
                    @atlas-button-click=${this.previousStep}
                ></atlas-button>
            `
        );
    }

    private renderNextButton() {
        const stepReference = this.getStepReference(this._currentStep);

        return when(
            this.shouldRenderNextButton(),
            () => html`
                <atlas-button
                    size="md"
                    theme=${stepReference?.nextButtonTheme || "primary"}
                    description=${stepReference?.nextButtonLabel || "Avançar"}
                    @atlas-button-click=${() => this.nextStep()}
                ></atlas-button>
            `
        );
    }

    private renderFinishButton() {
        return when(
            this.shouldRenderFinishButton(),
            () => html`
                <atlas-button
                    size="md"
                    class="atlas-wizard-finish-button"
                    theme=${this.finishButtonTheme || "success"}
                    description=${this.finishButtonLabel || "Finalizar"}
                    @atlas-button-click=${this.finishWizard}
                ></atlas-button>
            `
        );
    }

    private renderExtraButtons() {
        const extraButtons = this.getStepController(this._currentStep)?.getExtraButtons() || [];

        return extraButtons.map(
            (customButton: CustomStepButton) => html`
                <atlas-button
                    size="md"
                    theme="secondary"
                    description=${customButton.description}
                    @atlas-button-click=${customButton.click}
                ></atlas-button>
            `
        );
    }

    private renderHeader() {
        if (this.isOnlyHeaderCloseButton()) return nothing;

        if (this.headerImage) {
            return html`<img src=${this.headerImage} alt=${this.headerImageDescription} />`;
        }

        if (this.header) {
            return html`<h1>${this.header}</h1>`;
        }

        return html`
            <div class="slotted-header">
                <slot name="header"></slot>
            </div>
        `;
    }

    private renderHeaderCloseButton() {
        return when(
            this.isOnlyHeaderCloseButton() || !this.hideHeaderCloseButton,
            () => html`
                <button @click=${this.closeWizard} class="wizard-close-button" aria-label="Fechar">
                    <atlas-icon name="x" size="3x"></atlas-icon>
                </button>
            `
        );
    }

    private renderWizardHeader() {
        const wizardHeaderClass = {
            "wizard-header": true,
            "only-close-button": this.isOnlyHeaderCloseButton()
        };

        return when(
            !this.hideHeader,
            () => html`
                <header class=${classMap(wizardHeaderClass)}>
                    <div class="wizard-header-title">${this.renderHeader()}${this.renderHeaderCloseButton()}</div>
                </header>
            `
        );
    }

    public render() {
        const wizardClass = {
            "wizard": true,
            "is-modal-wizard": this.isModalWizard,
            "is-stepper-hidden": this.hideStepper || this._steps.length === 0 || this.isIntroOrFinishStep()
        };

        const bodyClass = {
            "wizard-body": true,
            "centralized-content": this.getCurrentStep()?.centralizedContent,
            "horizontally-centralized-content": this.getCurrentStep()?.horizontallyCentralizedContent
        };

        return html`
            <div class=${classMap(wizardClass)}>
                ${this.renderWizardHeader()}
                <main class=${classMap(bodyClass)}>
                    ${this.renderStepper()}
                    <div class="container">
                        <slot @slotchange=${this.onChangeSlot}></slot>
                    </div>
                </main>
                <footer class="wizard-footer">
                    ${this.renderCloseButton()} ${this.renderBackButton()} ${this.renderExtraButtons()}
                    ${this.renderNextButton()} ${this.renderFinishButton()}
                </footer>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-wizard": AtlasWizard;
    }
}
