import {css} from "lit-element/lit-element.js";
export default css`.dropdown-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 1rem;
  gap: 0.5rem;
}

@media screen and (min-width: 992px) {
  .dropdown-empty-state {
    height: auto;
    width: auto;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2NvZGVidWlsZC9vdXRwdXQvc3JjNTIxMzU0MzQxL3NyYy9wYWNrYWdlcy93ZWJjb21wb25lbnRzL3NyYy9jb21wb25lbnRzL2Zvcm0vYXRsYXMtc2VsZWN0Iiwic291cmNlcyI6WyJhdGxhcy1zZWxlY3QtZHJvcGRvd24uc2NzcyIsIi4uLy4uLy4uL3N0eWxlcy9taXhpbnMvX2JyZWFrcG9pbnRzLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDSTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FDSUE7RURBQTtJQUNJO0lBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyJAdXNlIFwiLi4vLi4vLi4vc3R5bGVzL21peGlucy9icmVha3BvaW50c1wiO1xuXG4uZHJvcGRvd24tZW1wdHktc3RhdGUge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gICAgaGVpZ2h0OiAxMDAlO1xuICAgIHBhZGRpbmc6IDFyZW07XG4gICAgZ2FwOiAwLjVyZW07XG59XG5cbkBpbmNsdWRlIGJyZWFrcG9pbnRzLmJyZWFrcG9pbnQtdXAtbGcge1xuICAgIC5kcm9wZG93bi1lbXB0eS1zdGF0ZSB7XG4gICAgICAgIGhlaWdodDogYXV0bztcbiAgICAgICAgd2lkdGg6IGF1dG87XG4gICAgfVxufVxuIiwiQG1peGluIGJyZWFrcG9pbnQtdXAtc20ge1xuICAgIEBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDU3NnB4KSB7XG4gICAgICAgIEBjb250ZW50O1xuICAgIH1cbn1cblxuQG1peGluIGJyZWFrcG9pbnQtdXAtbWQge1xuICAgIEBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDc2OHB4KSB7XG4gICAgICAgIEBjb250ZW50O1xuICAgIH1cbn1cblxuQG1peGluIGJyZWFrcG9pbnQtdXAtbGcge1xuICAgIEBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDk5MnB4KSB7XG4gICAgICAgIEBjb250ZW50O1xuICAgIH1cbn1cblxuQG1peGluIGJyZWFrcG9pbnQtdXAteGwge1xuICAgIEBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDEyMDBweCkge1xuICAgICAgICBAY29udGVudDtcbiAgICB9XG59XG5cbkBtaXhpbiBicmVha3BvaW50LXVwLXh4bCB7XG4gICAgQG1lZGlhIHNjcmVlbiBhbmQgKG1pbi13aWR0aDogMTQwMHB4KSB7XG4gICAgICAgIEBjb250ZW50O1xuICAgIH1cbn1cblxuQG1peGluIGJyZWFrcG9pbnQtZG93bi1sZyB7XG4gICAgQG1lZGlhIHNjcmVlbiBhbmQgKG1heC13aWR0aDogOTkycHgpIHtcbiAgICAgICAgQGNvbnRlbnQ7XG4gICAgfVxufVxuIl19 */`;
