import { html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import Tribute from "tributejs";
import { FroalaEditor, type FroalaOptions } from "@/vendors/froala-editor";
import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";

import FormElement, { FormElementProps } from "@/components/form/form-element";
import { WithCharsCounterMixin, WithCharsCounterProps } from "@/internals/mixins/with-chars-counter-mixin";

import { EditorVariable } from "./types";
import "froala-editor/js/languages/pt_br.js";
import "froala-editor/js/plugins/align.min.js";

export type HtmlEditorProps = FormElementProps &
    WithCharsCounterProps & {
        "placeholder": string;
        "inline": boolean;
        "height": number;
        "resizable": boolean;
        "show-text-buttons": boolean;
        "show-paragraph-buttons": boolean;
        "show-miscellaneous-buttons": boolean;
        "show-variables-buttons": boolean;
        "variables": Array<{ key: string; value: string; weight: number }>;
        "variables-trigger": string;
        "maxlength": number;
        "text-only": boolean;
        "disable-line-break": boolean;
    };

/**
 * @event {CustomEvent} atlas-editor-change - Evento disparado quando o valor do editor é alterado
 * @event {CustomEvent} atlas-editor-focus - Evento disparado quando o editor recebe foco
 * @event {CustomEvent} atlas-editor-blur - Evento disparado quando o editor perde foco
 *
 * @tag atlas-editor
 */
@customElement("atlas-editor")
export default class AtlasEditor extends WithCharsCounterMixin(FormElement) {
    /** Texto de ajuda que aparecerá quando o editor estiver vazio */
    @property({ type: String }) placeholder = "";

    /** Indica se o editor deve ser exibido no formato de um input tradicional */
    @property({ type: Boolean }) inline: boolean;

    /** Altura do editor */
    @property({ type: Number }) height: number;

    /** Indica se o editor vai ter resize (vertical) ou não */
    @property({ type: Boolean }) resizable: boolean;

    /** Indica se o editor deve exibir botões para formatação de texto */
    @property({ type: Boolean, attribute: "show-text-buttons" }) showTextButtons: boolean;

    /** Indica se o editor deve exibir botões para formatação de parágrafos */
    @property({ type: Boolean, attribute: "show-paragraph-buttons" }) showParagraphButtons: boolean;

    /** Indica se o editor deve exibir os botões de funções variadas, como Desfazer, Refazer e Selecionar tudo */
    @property({ type: Boolean, attribute: "show-miscellaneous-buttons" }) showMiscellaneousButtons: boolean;

    /** Indica se o botão de variáveis deve aparecer no editor */
    @property({ type: Boolean, attribute: "show-variables-buttons" }) showVariablesButtons: boolean;

    /** Variáveis que podem ser mencionadas no editor */
    @property({ type: Array }) variables: Array<EditorVariable>;

    /** Caracteres que serão utilizados para acionar a menção de variáveis */
    @property({ type: String, attribute: "variables-trigger" }) variablesTrigger: string = "{";

    /** Quantidade máxima de caracteres */
    @property({ type: Number }) maxlength: number;

    /** Indica se o editor vai aceitar apenas texto, desabilitando todas as formatações e retornando o valor como texto puro */
    @property({ type: Boolean, attribute: "text-only" }) textOnly: boolean;

    /** Indica se a quebra de linhas deve estar desabilitada no editor normal */
    @property({ type: Boolean, attribute: "disable-line-break" }) disableLineBreak: boolean;

    @state() private _hasFocus = false;

    @query(".atlas-editor") private _editorElement: HTMLElement;

    private _editorInstance: FroalaEditor;

    private _tributeInstace: Tribute<object>;

    constructor() {
        super();

        this.onEditorInitialized = this.onEditorInitialized.bind(this);
        this.onEditorContentChanged = this.onEditorContentChanged.bind(this);
        this.onEditorKeyDown = this.onEditorKeyDown.bind(this);
        this.onEditorBlur = this.onEditorBlur.bind(this);
        this.onEditorFocus = this.onEditorFocus.bind(this);
        this.onEditorToolbarShow = this.onEditorToolbarShow.bind(this);
        this.onVariablesButtonClick = this.onVariablesButtonClick.bind(this);
        this.onSelectVariable = this.onSelectVariable.bind(this);
        this.onEditorCommandsAfter = this.onEditorCommandsAfter.bind(this);
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.updateComplete.then(() => {
            this.registerTribute();
            this.createHtmlEditor();
        });
    }

    public disconnectedCallback(): void {
        super.disconnectedCallback();

        this._tributeInstace?.detach(this._editorInstance?.el);
        this._editorInstance?.destroy();
    }

    /**
     * @override
     *
     * Obtém o valor do elemento
     * @returns {string} - O valor do elemento
     */
    public getElementValue(): any {
        return this.textOnly ? this.getCleanValue() : this.value;
    }

    /**
     * Retorna o valor do editor sem tags HTML, apenas o texto limpo
     * @returns {string} O texto limpo
     */
    public getCleanValue() {
        if (this._editorInstance?.el) {
            return this._editorInstance.el.innerText;
        }

        const tempContainer = document.createElement("div");
        tempContainer.innerHTML = this.value;

        const innerText = tempContainer.innerText;
        tempContainer.remove();

        return innerText;
    }

    /**
     * Define o foco no editor
     */
    public focus() {
        const textValue = this.getCleanValue().trim();

        if (textValue === "") {
            this._editorInstance.selection.setAtStart(this._editorInstance.$el.get(0), true);
        } else {
            this._editorInstance.selection.setAtEnd(this._editorInstance.$el.get(0), true);
        }

        this._editorInstance.selection.restore();
    }

    /** @internal */
    @Watch("value", true)
    public onChangeValue() {
        if (this._editorInstance.html.get() !== this.value) {
            this._editorInstance.html.set(this.value);
        }

        this.updateCharsCounterWithEditorContent();
        emit(this, "atlas-editor-change", { detail: this.value, trackDisable: true });
    }

    /**
     * @internal
     * @override
     */
    public onChangeDisabled() {
        super.onChangeDisabled();

        if (this.disabled) {
            this._editorInstance.edit.off();
        } else {
            this._editorInstance.edit.on();
        }
    }

    /**
     * @internal
     * @override
     */
    public async applySkeleton() {
        await this.updateComplete;

        const allElements = this.querySelectorAll("*");

        allElements.forEach((element) => {
            element.toggleAttribute("skeleton-loading", this.skeletonLoading);
        });

        emit(this, "atlas-element-change-skeleton", { trackDisable: true });
    }

    private hasVariables() {
        return !!this.variables?.length;
    }

    /**
     * Retorna as variáveis utilizadas no editor
     * @returns {Array<string>} - As variáveis utilizadas
     */
    public getUsedVariables(): string[] {
        const content = this.getCleanValue();
        const usedVariables = content.match(/{{(.*?)}}/g);

        return usedVariables ?? [];
    }

    private getToolbarTextButtons() {
        if (!this.showTextButtons) return {};

        return {
            moreText: {
                buttons: ["bold", "italic", "underline", "strikeThrough"],
                buttonsVisible: 4
            }
        };
    }

    private getToolbarParagraphButtons() {
        if (!this.showParagraphButtons) return {};

        return {
            moreParagraph: {
                buttons: ["alignLeft", "alignCenter", "alignRight", "alignJustify"],
                buttonsVisible: 4
            }
        };
    }

    private getToolbarVariablesButtons() {
        if (!this.showVariablesButtons || !this.hasVariables()) return {};

        return {
            moreVariables: {
                buttons: ["variables"],
                align: "right",
                buttonsVisible: 1
            }
        };
    }

    private getToolbarMiscellaneousButtons() {
        if (!this.showMiscellaneousButtons) return {};

        return {
            moreMisc: {
                buttons: ["undo", "redo", "selectAll"],
                align: "right",
                buttonsVisible: 3
            }
        };
    }

    private registerTribute() {
        if (!this.hasVariables()) return;

        this._tributeInstace = new Tribute({
            trigger: this.variablesTrigger,
            requireLeadingSpace: false,
            values: this.variables,
            noMatchTemplate: () => '<span class="not-found">Não foram encontradas variáveis com esse termo<span>',
            selectTemplate: this.onSelectVariable
        });
    }

    private createHtmlEditor() {
        const froalaConfig: Partial<FroalaOptions> = {
            key: "jUA1yD4C3A1C3A1F1F2qYFa1UQRFQIVc2MSMd1IWPNb1IFd1yD2I2D1B2C7D2C1C5D1C1==",
            attribution: false,
            language: "pt_br",
            pluginsEnabled: ["align", "fixPasteBug"],
            toolbarInline: this.inline,
            multiLine: !this.inline && !this.disableLineBreak,
            placeholderText: this.placeholder || "",
            toolbarVisibleWithoutSelection: true,
            height: this.inline ? null : this.height || 150,
            pastePlain: true,
            toolbarButtons: {
                ...this.getToolbarTextButtons(),
                ...this.getToolbarParagraphButtons(),
                ...this.getToolbarVariablesButtons(),
                ...this.getToolbarMiscellaneousButtons()
            },
            fullPage: false,
            events: {
                "initialized": this.onEditorInitialized,
                "contentChanged": this.onEditorContentChanged,
                "keyup": this.onEditorContentChanged,
                "blur": this.onEditorBlur,
                "focus": this.onEditorFocus,
                "toolbar.show": this.onEditorToolbarShow,
                "variables:click": this.onVariablesButtonClick,
                "commands.after": this.onEditorCommandsAfter
            }
        };

        if (this.textOnly) {
            froalaConfig.shortcutsEnabled = ["undo", "redo"];
            froalaConfig.htmlAllowedTags = ["p", "span"];
        }

        this._editorInstance = new FroalaEditor(this._editorElement, froalaConfig);
    }

    private onEditorInitialized() {
        if (this.hasVariables()) {
            this._tributeInstace.attach(this._editorInstance.el);
        }

        if (this.value) {
            this._editorInstance.html.set(this.value);
        }

        if (this.disabled) {
            this._editorInstance.edit.off();
        }

        this._editorInstance.events.on("keydown", this.onEditorKeyDown, true);
        this.updateCharsCounterWithEditorContent();
    }

    private onEditorContentChanged() {
        this.value = this._editorInstance.html.get();
    }

    private fixVariablesHTML() {
        let content = this._editorInstance.html.get();

        const innerTagsRegex = /{{\s*(<[^>]+>)([^<]+)<\/[^>]+>\s*}}/g;
        content = content.replace(innerTagsRegex, (_, tagStart, variableContent) => {
            return `${tagStart}{{${variableContent}}}</${tagStart.match(/<(\w+)/)[1]}>`;
        });

        const brokenTagsRegex = /<([a-zA-Z0-9]+)[^>]*>\s*\{\{[^}]*\}\}\s*<\/\1>/g;
        const correctTagsRegex = /^<([a-zA-Z]+)[^>]*>\{\{[^<]+?\}\}<\/\1>$/;

        content = content.replace(brokenTagsRegex, (match) => {
            if (correctTagsRegex.test(match)) {
                return match;
            }

            return match.replace(/<[^>]+>/g, "");
        });

        this._editorInstance.html.set(content);
    }

    private onEditorCommandsAfter(command: string) {
        const formattingCommands = ["bold", "italic", "underline", "strikeThrough"];

        if (formattingCommands.includes(command)) {
            this.fixVariablesHTML();
        }
    }

    private onEditorKeyDown(e: KeyboardEvent) {
        if (!this.hasVariables()) return true;

        if (e.key == "Enter" && this._tributeInstace.isActive) {
            return false;
        }

        return true;
    }

    private onEditorBlur() {
        this._hasFocus = false;
        this.reportValidity();

        emit(this, "atlas-editor-blur", { trackDisable: true });
        emit(this, "atlas-form-element-touch", { trackDisable: true });
    }

    private onEditorFocus() {
        setTimeout(() => {
            this._hasFocus = true;

            emit(this, "atlas-editor-focus", { trackDisable: true });
        }, 0);
    }

    private onEditorToolbarShow() {
        if (!this.inline) return true;

        const toolbar = this._editorInstance.$tb.get(0) as HTMLElement;
        const editor = this._editorInstance.el as HTMLElement;
        const { x, y } = editor.getBoundingClientRect();

        setTimeout(() => {
            toolbar.style.display = "block";

            const toolbarTop = y - toolbar.offsetHeight - 20 + window.scrollY;

            toolbar.style.top = `${toolbarTop}px`;
            toolbar.style.left = `${x - 12}px`;
        }, 0);

        return false;
    }

    private onVariablesButtonClick() {
        if (!this._hasFocus) {
            this.focus();
        }

        if (!this._tributeInstace.isActive) {
            // @ts-expect-error
            this._tributeInstace.current.mentionText = "";
            this._tributeInstace.showMenuForCollection(this._editorInstance.el);
        }
    }

    private onSelectVariable(item: any) {
        if (typeof item === "undefined") return null;

        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const container = range.startContainer;

        if (container.nodeType === Node.TEXT_NODE) {
            const text = container.textContent;

            const variableRegex = /{{([^{}]*)}}/g;
            const matches = [...text.matchAll(variableRegex)];

            let foundVariable: { start: number; end: number } = null;

            matches.forEach((match) => {
                const posStart = match.index;
                const posEnd = match.index + match[0].length;

                if (range.startOffset >= posStart && range.startOffset <= posEnd) {
                    foundVariable = { start: posStart, end: posEnd };
                    return;
                }
            });

            if (foundVariable) {
                const before = text.substring(0, foundVariable.start);
                const after = text.substring(foundVariable.end);

                container.textContent = before + item.original.value + after;

                const newCursorPosition = foundVariable.start + item.original.value.length;
                const newRange = document.createRange();

                newRange.setStart(container, newCursorPosition);
                newRange.setEnd(container, newCursorPosition);
                selection.removeAllRanges();
                selection.addRange(newRange);

                this._editorInstance.events.trigger("contentChanged", []);
                return null;
            }
        }

        return item.original.value;
    }

    private updateCharsCounterWithEditorContent() {
        if (!this.enableCharsCounter) return;

        const usedVariables: string[] = this.value ? this.getUsedVariables() : [];
        let content = this.value ? this.getCleanValue() : "";
        let variablesWeight = 0;

        usedVariables.forEach((variable) => {
            content = content.replace(variable, "");

            const variableObject = this.variables.find((v) => v.key === variable);
            variablesWeight += variableObject?.weight || variableObject?.value.length || 0;
        });

        this.updateCharsCounterIfEnabled(content.length + variablesWeight);
    }

    public render() {
        const editorClass = {
            "atlas-editor": true,
            "atlas-editor-inline": this.inline,
            "atlas-editor-disabled": this.disabled,
            "atlas-editor-skeleton": this.skeletonLoading,
            "resizable": this.resizable,
            [`is-${this._status}`]: this.getShowStatus()
        };

        return html`
            <div class=${classMap(editorClass)}></div>
            ${when(!this.disabled, () => this.renderCharsCounter())} ${this.renderStatusMessage()}
        `;
    }

    public createRenderRoot() {
        return this;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-editor": AtlasEditor;
    }
}
