import { customElement, property } from "lit/decorators.js";

import { Watch } from "@/decorators/watch";
import Inputmask from "@/vendors/inputmask-utils";
import AtlasInput, { InputProps } from "@/components/form/atlas-input/atlas-input";

import { convertToDecimalFormat, removeLeadingZeros, removeNegativeSign, removeNonNumeric } from "@/helpers/formatters";
import RangeValidator from "@/internals/validators/range-validator";

export type FloatInputProps = InputProps & {
    "decimal-precision": number;
    "max-value": number;
    "max-value-error-message": string;
    "min-value": number;
    "min-value-error-message": string;
    "allow-negative": boolean;
};

/**
 * @extends atlas-input
 *
 * @tag atlas-float-input
 */
@customElement("atlas-float-input")
export default class AtlasFloatInput extends AtlasInput {
    /** Precisão de casas decimais do input */
    @property({ type: Number, attribute: "decimal-precision" }) decimalPrecision = 2;

    /** Valor máximo que o input pode receber */
    @property({ type: Number, attribute: "max-value" }) maxValue: number;

    /** Mensagem exibida no input caso o valor seja maior que o valor máximo */
    @property({ type: String, attribute: "max-value-error-message" }) maxValueErrorMessage: string;

    /** Valor mínimo que o input pode receber */
    @property({ type: Number, attribute: "min-value" }) minValue: number;

    /** Mensagem exibida no input caso o valor seja menor que o valor mínimo */
    @property({ type: String, attribute: "min-value-error-message" }) minValueErrorMessage: string;

    /** Indica que o campo aceita valores negativos */
    @property({ type: Boolean, attribute: "allow-negative" }) allowNegative = false;

    private _maskInstance: Inputmask.Instance;

    private _maskOptions: Inputmask.Options = {};

    /**
     * @internal
     * @override
     */
    public connectedCallback() {
        super.connectedCallback?.();

        this.fixCarretPosition = this.fixCarretPosition.bind(this);

        this.updateComplete.then(() => {
            this.addValidator(new RangeValidator(this.maxValueErrorMessage, this.minValueErrorMessage));

            if (!this.placeholder) {
                this.placeholder = this.getMaskedPlaceholder();
            }

            this.inputMode = "decimal";

            this.normalizeMinMaxLength();

            this.buildMask();

            this._input.addEventListener("click", this.fixCarretPosition);
        });
    }

    /**
     * @internal
     * @override
     */
    public disconnectedCallback() {
        super.disconnectedCallback?.();

        this._input.removeEventListener("click", this.fixCarretPosition);
    }

    /** @internal */
    @Watch(["maxValueErrorMessage", "minValueErrorMessage"], true)
    public onChangeAttributes() {
        const validator = this.getValidator("range") as RangeValidator;
        validator.maxValueErrorMessage = this.maxValueErrorMessage;
        validator.minValueErrorMessage = this.minValueErrorMessage;

        this.reportValidity();
    }

    /**
     * @internal
     * @override
     */
    public onChangeValue() {
        this.formatValueWithMaskIfNecessary();

        super.onChangeValue();
    }

    /**
     * @override
     * @inheritdoc
     */
    public focus() {
        this._input.focus();
    }

    /**
     * Retorna o valor do campo sem a máscara
     * @returns {string | number} - O valor do campo sem a máscara
     */
    public getUnmaskedValue(): string | number {
        return this._maskInstance.unmaskedvalue();
    }

    /** @override */
    protected handleFocus() {
        super.handleFocus();
        this.fixCarretPosition();
    }

    /** @override */
    protected async handlePaste(event: ClipboardEvent) {
        await this.updateComplete;

        const pastedValue = removeNonNumeric(event.clipboardData.getData("text"), {
            allowNegative: this.allowNegative
        });
        if (!pastedValue) return;

        const { selectionStart, selectionEnd } = this._input;
        const currentValue = this.value ? String(this.value) : this.getMaskedPlaceholder();
        const isFullSelection = selectionStart === 0 && selectionEnd === currentValue.length;

        const newValue = isFullSelection ? pastedValue : this.getCombinedValue(pastedValue);
        const newValueWithoutLeadingZeros = removeLeadingZeros(newValue, { allowNegative: this.allowNegative });

        this.value = convertToDecimalFormat(newValueWithoutLeadingZeros, {
            decimalPrecision: this.decimalPrecision,
            allowNegative: this.allowNegative
        });

        super.handlePaste?.(event);
    }

    private getCombinedValue(pastedValue: string): string {
        const currentValue = this.value ? String(this.value) : this.getMaskedPlaceholder();
        const cleanedCurrentValue = removeNonNumeric(currentValue, { allowNegative: this.allowNegative });
        const combinedValue = cleanedCurrentValue + removeNegativeSign(pastedValue);

        return combinedValue;
    }

    private fixCarretPosition() {
        setTimeout(() => {
            const length = this.value ? this.value.length : this.decimalPrecision + 2;

            if (this._input.setSelectionRange) {
                this._input.setSelectionRange(length, length);
            }
        }, 0);
    }

    private normalizeMinMaxLength() {
        const computedMinlength = this.decimalPrecision + 2;

        if (this.minlength) {
            this.minlength = Math.max(this.minlength, computedMinlength, 0);
        }

        if (this.maxlength) {
            this.maxlength = Math.max(this.maxlength, this.minlength ?? computedMinlength);
        }
    }

    private getValueReplacedDotByComma(value: any): string {
        if (!value) return "";

        const stringValue = String(value);
        const hasDot = stringValue.includes(".");
        const hasComma = stringValue.includes(",");

        if (!hasDot && !hasComma) return stringValue;

        if (!hasComma) return stringValue.replace(".", ",");

        return stringValue.replace(/\./g, "");
    }

    private getMaskedPlaceholder() {
        return this.decimalPrecision <= 0 ? "0" : `0,${"0".repeat(this.decimalPrecision)}`;
    }

    private formatValueWithMaskIfNecessary() {
        if (Inputmask.isValid(this.value, this._maskOptions)) return;

        const normalizedValue = this.getValueReplacedDotByComma(this.value);
        this.value = Inputmask.format(normalizedValue, this._maskOptions);
    }

    private buildMask() {
        this._maskOptions = {
            alias: "float",
            allowMinus: this.allowNegative,
            digits: this.decimalPrecision,
            onKeyDown: (event) => {
                const arrowKeys = ["ArrowLeft", "ArrowUp", "ArrowDown", "ArrowRight"];

                if (arrowKeys.includes(event.key)) {
                    event.preventDefault();
                }
            },
            // @ts-expect-error
            onBeforePaste: () => false
        };

        this._maskInstance = Inputmask(this._maskOptions).mask(this._input);

        // @ts-expect-error
        this._maskInstance.shadowRoot = this.shadowRoot;

        if (this.value) this.formatValueWithMaskIfNecessary();
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-float-input": AtlasFloatInput;
    }
}
