import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { map } from "lit/directives/map.js";
import { range } from "lit/directives/range.js";
import { live } from "lit/directives/live.js";

import LengthValidator from "@/internals/validators/length-validator";
import { emit } from "@/internals/events";
import { Watch } from "@/decorators/watch";
import { InputSize } from "../atlas-input/types";
import FormControl from "../form-control";
import type AtlasInput from "../atlas-input/atlas-input";

import oneTimeCodeStyles from "./atlas-one-time-code.scss";

import "@/components/form/atlas-input/atlas-input";
import "@/components/layout/atlas-layout/atlas-layout";

type Lenght = 4 | 5 | 6;

type OneTimeCodeType = "numeric" | "alphanumeric";

/**
 * @extends FormControl
 *
 * @event {CustomEvent<string>} atlas-one-time-code-fulfilled - Evento disparado quando todos os slots são preenchidos.
 * O evento contém uma string com o código preenchido no detail.
 *
 * @tag atlas-one-time-code
 */
@customElement("atlas-one-time-code")
export default class AtlasOneTimeCode extends FormControl {
    static styles = oneTimeCodeStyles;

    /** Indica a quantidade de slots */
    @property({ type: Number, reflect: true }) length: Lenght = 6;

    /** Define se o código aceito é numérico ou alfanumérico */
    @property({ type: String }) type: OneTimeCodeType = "numeric";

    /** Define o tamanho dos inputs */
    @property({ type: String }) size: InputSize = "md";

    /** Define se o campo é obrigatório */
    @property({ type: Boolean }) required: boolean = true;

    @state() private _digits: Array<string> = [];

    private _slotInputList: Array<AtlasInput> = [];

    private static NUMERIC_REGEX = /^[0-9]{0,1}$/;
    private static ALPHANUMERIC_REGEX = /^[0-9A-Za-z]{0,1}$/;

    constructor() {
        super();

        this.handleBlur = this.handleBlur.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handlePaste = this.handlePaste.bind(this);
        this.addValidator(new LengthValidator(false));
    }

    /** @internal */
    public connectedCallback(): void {
        super.connectedCallback?.();

        this.buildEmptyDigitsList();

        this.updateComplete.then(() => {
            this.buildSlotInputList();
        });
    }

    /** @internal */
    @Watch(["_status", "_valid", "_showStatusMessage"], true)
    public async onStatusChange() {
        await this.updateComplete;

        this.updateInputStatus();
    }

    /** @internal */
    @Watch(["length"], true)
    public onLengthChange() {
        this.buildEmptyDigitsList();
        this.buildSlotInputList();
    }

    /** @internal */
    @Watch(["value"], false)
    public onValueChange() {
        if (this.value === this._digits.join("")) return;

        if (!this.value) {
            this.buildEmptyDigitsList();
            this._slotInputList.forEach((input) => {
                input.value = "";
            });
            return;
        }

        const digits = this.validateAndFilterCode(this.value);
        this.value = digits.join("");
        this.buildDigitsListFromValue(digits);
    }

    /**
     * Retorna o regex de validação de dígitos de acordo com o tipo de código
     * @returns {RegExp} O regex de validação de dígitos
     */
    public getDigitValidationRegex(): RegExp {
        return this.type === "numeric" ? AtlasOneTimeCode.NUMERIC_REGEX : AtlasOneTimeCode.ALPHANUMERIC_REGEX;
    }

    private validateAndFilterCode(code: string): string[] {
        const digits = code.split("");
        const validCodeTypeRegex = this.getDigitValidationRegex();

        return digits
            .filter((digit) => {
                return validCodeTypeRegex.test(digit);
            })
            .slice(0, this.length);
    }

    private buildSlotInputList() {
        this._slotInputList = [...this.shadowRoot.querySelectorAll("atlas-input")] as AtlasInput[];
    }

    private onChangeDigits(): void {
        this.updateValue();
        this.checkValidity();

        if (this.value.length === this.length) {
            emit(this, "atlas-one-time-code-fulfilled", { detail: this._digits, trackDisable: true });
        }
    }

    private buildEmptyDigitsList() {
        this._digits = Array.from({ length: this.length }, (): string => "");
    }

    private buildDigitsListFromValue(value: string[]) {
        this._digits = Array.from({ length: this.length }, (_, index) => value[index] || "");
    }

    private goToFirstEmptyInput(): void {
        const firstEmptyInputIndex = this.getFirstEmptyInputIndex();

        if (firstEmptyInputIndex === null) return;

        const input = this._slotInputList[firstEmptyInputIndex];
        input.focus();
    }

    private goToIndex(index: number): void {
        if (index < 0 || index > this.length) return;

        const firstEmptyInputIndex = this.getFirstEmptyInputIndex();

        const isFirstEmptyInputIndexValid = firstEmptyInputIndex !== null && index > firstEmptyInputIndex;
        const inputIndex = isFirstEmptyInputIndexValid ? firstEmptyInputIndex : index;
        const input = this._slotInputList[inputIndex];

        input.focus();
        this.selectInputValue(input);
    }

    private getInputIndex(input: AtlasInput): number {
        return parseInt(input.dataset.inputIndex, 10);
    }

    private getFirstEmptyInputIndex(): number | null {
        const index = this._digits.findIndex((code) => !code);
        return index === -1 ? null : index;
    }

    private checkIfAllInputsAreFilled(): boolean {
        return this._digits.every(Boolean);
    }

    private updateInputStatus() {
        this._slotInputList.forEach((input) => {
            input._status = this._status;
            input._valid = this._valid;
            input._showStatusMessage = this._showStatusMessage;
        });
    }

    private updateValue(): void {
        this.value = this._digits.filter(Boolean).join("") as string;
    }

    private selectInputValue(input: AtlasInput): void {
        setTimeout(() => {
            input.shadowRoot?.querySelector("input").select();
        }, 0);
    }

    private handleKeyDown(event: CustomEvent): void {
        const target = event.target as AtlasInput;
        const index = this.getInputIndex(target);
        const key = event.detail.key;

        switch (key) {
            case "ArrowRight":
            case "ArrowUp":
                event.preventDefault();
                this.goToIndex(index + 1);
                break;

            case "ArrowLeft":
            case "ArrowDown":
                event.preventDefault();
                this.goToIndex(index - 1);
                break;

            case "Home":
                event.preventDefault();
                this.goToIndex(0);
                break;

            case "End":
                event.preventDefault();
                this.goToIndex(this.length - 1);
                break;

            case "Backspace":
                if (!this._digits[index]) {
                    this._digits[index] = "";
                    this.goToIndex(index - 1);
                }
                break;
        }
    }

    private handlePaste(event: CustomEvent): void {
        event.preventDefault();
        const clipboardData = event.detail.clipboardData?.getData("text") || "";

        if (clipboardData.length > this.length) {
            return;
        }

        const digits = this.validateAndFilterCode(clipboardData);
        this.buildDigitsListFromValue(digits);
        this.onChangeDigits();
        this.goToFirstEmptyInput();
    }

    private handleInput(event: CustomEvent): void {
        const input = event.target as AtlasInput;
        const index = this.getInputIndex(input);
        const value = event.detail;

        const validCodeTypeRegex = this.getDigitValidationRegex();

        if (!validCodeTypeRegex.test(value)) {
            event.preventDefault();
            input.requestUpdate();
            return;
        }

        this._digits[index] = value;

        this.onChangeDigits();
        if (this.checkIfAllInputsAreFilled()) return;

        if (value.length !== 1) {
            return;
        }

        this.goToIndex(index + 1);
    }

    private handleFocus(event: CustomEvent): void {
        const input = event.target as AtlasInput;
        const index = this.getInputIndex(input);
        const hasValue = !!input.value;

        const firstEmptyInputIndex = this.getFirstEmptyInputIndex();
        const allInputsAreFilled = this.checkIfAllInputsAreFilled();

        if (!hasValue && firstEmptyInputIndex !== index && !allInputsAreFilled) {
            this.goToIndex(firstEmptyInputIndex);
            return;
        }

        if (!hasValue) {
            return;
        }

        this.selectInputValue(input);
    }

    private handleBlur(): void {
        setTimeout(() => {
            if (document.activeElement !== this) {
                this.reportValidity();
            }
        }, 0);
    }

    private getInputMode(): "numeric" | "text" {
        return this.type === "numeric" ? "numeric" : "text";
    }

    private renderSlotInputs() {
        const inputClasses = {
            "one-time-code-input": true,
            "sm": this.size === "sm",
            "md": this.size === "md",
            "lg": this.size === "lg"
        };

        return map(
            range(this.length),
            (index) => html`
                <atlas-input
                    class="${classMap(inputClasses)}"
                    ?disabled="${this.disabled}"
                    ignore-validations
                    placeholder="-"
                    size="${this.size}"
                    type="text"
                    value="${live(this._digits[index])}"
                    inputmode="${this.getInputMode()}"
                    @atlas-input-keydown=${this.handleKeyDown}
                    @atlas-input-input="${this.handleInput}"
                    @atlas-input-focus="${this.handleFocus}"
                    @atlas-input-blur="${this.handleBlur}"
                    @atlas-input-paste="${this.handlePaste}"
                    data-input-index="${index}"
                ></atlas-input>
            `
        );
    }

    /** @internal */
    public render() {
        const classes = {
            [`is-${this._status}`]: this.getShowStatus()
        };

        return html`
            <atlas-layout gap="1">
                ${this.renderLabel()}
                <atlas-layout inline mobile-inline gap="2" class=${classMap(classes)}>
                    ${this.renderSlotInputs()}
                </atlas-layout>
                ${this.renderHelperText()} ${this.renderStatusMessage()}
            </atlas-layout>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-one-time-code": AtlasOneTimeCode;
    }
}
