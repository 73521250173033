import {css} from "lit-element/lit-element.js";
export default css`.summary-container {
  margin: 2rem 0;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  gap: 2rem;
}
@media screen and (min-width: 992px) {
  .summary-container {
    grid-template-columns: 1fr 1fr;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2NvZGVidWlsZC9vdXRwdXQvc3JjNTIxMzU0MzQxL3NyYy9wYWNrYWdlcy93ZWJjb21wb25lbnRzL3NyYy9jb21wb25lbnRzL3dpemFyZC9hdGxhcy1zdW1tYXJ5LWNvbnRhaW5lciIsInNvdXJjZXMiOlsiYXRsYXMtc3VtbWFyeS1jb250YWluZXIuc2NzcyIsIi4uLy4uLy4uL3N0eWxlcy9taXhpbnMvX2JyZWFrcG9pbnRzLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDSTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQ01BO0VEWEo7SUFRUSIsInNvdXJjZXNDb250ZW50IjpbIkB1c2UgXCIuLi8uLi8uLi9zdHlsZXMvbWl4aW5zL2JyZWFrcG9pbnRzXCI7XG5cbi5zdW1tYXJ5LWNvbnRhaW5lciB7XG4gICAgbWFyZ2luOiAycmVtIDA7XG4gICAgZGlzcGxheTogZ3JpZDtcbiAgICBncmlkLXRlbXBsYXRlLXJvd3M6IGF1dG87XG4gICAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiAxZnI7XG4gICAgZ2FwOiAycmVtO1xuXG4gICAgQGluY2x1ZGUgYnJlYWtwb2ludHMuYnJlYWtwb2ludC11cC1sZyB7XG4gICAgICAgIGdyaWQtdGVtcGxhdGUtY29sdW1uczogMWZyIDFmcjtcbiAgICB9XG59XG4iLCJAbWl4aW4gYnJlYWtwb2ludC11cC1zbSB7XG4gICAgQG1lZGlhIHNjcmVlbiBhbmQgKG1pbi13aWR0aDogNTc2cHgpIHtcbiAgICAgICAgQGNvbnRlbnQ7XG4gICAgfVxufVxuXG5AbWl4aW4gYnJlYWtwb2ludC11cC1tZCB7XG4gICAgQG1lZGlhIHNjcmVlbiBhbmQgKG1pbi13aWR0aDogNzY4cHgpIHtcbiAgICAgICAgQGNvbnRlbnQ7XG4gICAgfVxufVxuXG5AbWl4aW4gYnJlYWtwb2ludC11cC1sZyB7XG4gICAgQG1lZGlhIHNjcmVlbiBhbmQgKG1pbi13aWR0aDogOTkycHgpIHtcbiAgICAgICAgQGNvbnRlbnQ7XG4gICAgfVxufVxuXG5AbWl4aW4gYnJlYWtwb2ludC11cC14bCB7XG4gICAgQG1lZGlhIHNjcmVlbiBhbmQgKG1pbi13aWR0aDogMTIwMHB4KSB7XG4gICAgICAgIEBjb250ZW50O1xuICAgIH1cbn1cblxuQG1peGluIGJyZWFrcG9pbnQtdXAteHhsIHtcbiAgICBAbWVkaWEgc2NyZWVuIGFuZCAobWluLXdpZHRoOiAxNDAwcHgpIHtcbiAgICAgICAgQGNvbnRlbnQ7XG4gICAgfVxufVxuXG5AbWl4aW4gYnJlYWtwb2ludC1kb3duLWxnIHtcbiAgICBAbWVkaWEgc2NyZWVuIGFuZCAobWF4LXdpZHRoOiA5OTJweCkge1xuICAgICAgICBAY29udGVudDtcbiAgICB9XG59XG4iXX0= */`;
