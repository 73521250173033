import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";

import styles from "./atlas-empty-state.scss";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-illustration/atlas-illustration";
import "@/components/display/atlas-text/atlas-text";
import AtlasElement from "@/components/atlas-element";

export type EmptyStateProps = {
    "icon": string;
    "illustration": string;
    "illustration-alt": string;
    "header": string;
    "description": string;
    "small": boolean;
};

/**
 * @dependency atlas-heading
 * @dependency atlas-icon
 * @dependency atlas-illustration
 * @dependency atlas-text
 *
 * @tag atlas-empty-state
 */
@customElement("atlas-empty-state")
export default class AtlasEmptyState extends AtlasElement {
    static styles = styles;

    /** Ícone exibido no empty-state */
    @property({ type: String }) icon: string;

    /** Ilustração exibida no empty state */
    @property({ type: String }) illustration: string;

    /** Descrição de acessibilidade para a ilustração */
    @property({ type: String, attribute: "illustration-alt" }) illustrationAlt: string;

    /** Título do empty-state */
    @property({ type: String }) header: string;

    /** Descrição do empty-state */
    @property({ type: String }) description: string;

    /** Indica se deve exibir a variação pequena do empty-state */
    @property({ type: Boolean }) small: boolean;

    private _deviceController = new DeviceController(this);

    private renderIllustrationOrIcon() {
        return when(
            !!this.illustration,
            () => html`
                <atlas-illustration
                    name=${this.illustration}
                    alt=${this.illustrationAlt}
                    size=${this._deviceController.isMobile ? "sm" : "md"}
                ></atlas-illustration>
            `,
            () => html`<atlas-icon name=${this.icon} theme="primary" size=${this.small ? "5x" : "6x"}></atlas-icon>`
        );
    }

    private renderMessage() {
        return when(
            !!this.description,
            () => html`<atlas-text size=${this.small ? "xs" : "md"} muted>${this.description}</atlas-text>`,
            () => html`<atlas-text size=${this.small ? "xs" : "md"} muted><slot></slot></atlas-text>`
        );
    }

    public render() {
        const emptyStateClass = {
            "empty-state": true,
            "small": this.small
        };

        return html`
            <div class=${classMap(emptyStateClass)}>
                ${this.renderIllustrationOrIcon()}
                <atlas-heading size=${this.small ? "h6" : "h5"} alignment="center" muted ?hidden=${!this.header}>
                    ${this.header}
                </atlas-heading>
                ${this.renderMessage()}
                <slot name="button"></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-empty-state": AtlasEmptyState;
    }
}
