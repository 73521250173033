import { html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { isEmptySlot } from "@/internals/slot";
import { findElementByTagName } from "@/internals/elements";
import { emit } from "@/internals/events";
import DeviceController from "@/controllers/device-controller";
import TriggerController, { TriggeredElement } from "@/controllers/trigger-controller";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-modal.scss";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-icon-button/atlas-icon-button";
import "@/components/display/atlas-illustration/atlas-illustration";

export type ModalProps = AtlasElementProps & {
    "size": "default" | "large" | "extra-large" | "fullscreen";
    "height": number;
    "open": boolean;
    "disable-auto-close": boolean;
    "hide-close-button": boolean;
    "header": string;
    "header-logo": string;
    "header-img": string;
    "header-illustration": string;
    "header-img-description": string;
};

/**
 * @dependency atlas-heading
 * @dependency atlas-icon-button
 * @dependency atlas-illustration
 *
 * @slot - Conteúdo da modal
 * @slot actions - Botões de ação da modal
 *
 * @event {CustomEvent} atlas-modal-open - Evento disparado quando o modal é exibido
 * @event {CustomEvent} atlas-modal-close - Evento disparado quando o modal é fechado
 *
 * @tag atlas-modal
 */
@customElement("atlas-modal")
export default class AtlasModal extends AtlasElement implements TriggeredElement {
    static styles = styles;

    /** Tamanho da modal */
    @property({ type: String }) size: "default" | "large" | "extra-large" | "fullscreen" = "default";

    /** Altura fixa da modal */
    @property({ type: Number }) height: number;

    /** Indica se a modal está aberta ou não */
    @property({ type: Boolean, reflect: true }) open: boolean;

    /** Indica se o clique fora do modal deve ou não fechar o modal */
    @property({ type: Boolean, reflect: true, attribute: "disable-auto-close" }) disableAutoClose: boolean;

    /** Oculta o botão de fechar "X" */
    @property({ type: Boolean, reflect: true, attribute: "hide-close-button" }) hideCloseButton: boolean;

    /** Título da modal */
    @property({ type: String }) header: string;

    /** Caminho da imagem (do tipo logo) que deve aparecer no lugar do título */
    @property({ type: String, attribute: "header-logo" }) headerLogo: string;

    /** Caminho da imagem que aparece como título do modal */
    @property({ type: String, attribute: "header-img" }) headerImg: string;

    /** Nome da ilustração que aparece como título do modal */
    @property({ type: String, attribute: "header-illustration" }) headerIllustration: string;

    /** Descrição das imagens que aparecem no cabeçalho (Serve tanto para as imagens quanto para a ilustração) */
    @property({ type: String, attribute: "header-img-description" }) headerImgDescription: string;

    /** Remove o elemento do modal do HTML ao fechar o modal */
    @property({ type: Boolean, attribute: "destroy-on-close" }) destroyOnClose: boolean;

    @state() private _isVisible = false;

    @state() private _showFooter = false;

    @state() private _hasSlottedWizard = false;

    @query(".modal")
    private _modal: HTMLElement;

    private _deviceController = new DeviceController(this);

    private _triggerController: TriggerController;

    constructor() {
        super();

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this);
        this.onClickOverlay = this.onClickOverlay.bind(this);

        this._triggerController = new TriggerController(this, "click");
    }

    public connectedCallback() {
        super.connectedCallback?.();

        this.addEventListener("atlas-wizard-close", this.closeModal);
    }

    public disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-wizard-close", this.closeModal);
    }

    /**
     * Abre a modal
     */
    public openModal() {
        this._triggerController.show();
    }

    /**
     * Fecha a modal
     */
    public closeModal() {
        this._triggerController.hide();
    }

    /**
     * @internal
     */
    public onTriggerActivate() {
        this.open = this._triggerController.open;
        this._triggerController.triggerElement?.blur();
    }

    /**
     * @internal
     */
    @Watch("open")
    public onChangeOpenState() {
        if (this.open) {
            this.onOpenModal();
        } else {
            this.onCloseModal();
        }
    }

    private onOpenModal() {
        setTimeout(() => {
            this._isVisible = true;
        }, 0);

        emit(this, "atlas-modal-open");
        document.addEventListener("keydown", this.onDocumentKeyDown);
        document.body.classList.add("disable-scroll-modal");
    }

    private onCloseModal() {
        setTimeout(() => {
            this._isVisible = false;
        }, 0);

        emit(this, "atlas-modal-close");
        document.removeEventListener("keydown", this.onDocumentKeyDown);
        document.body.classList.remove("disable-scroll-modal");

        if (this.destroyOnClose) {
            setTimeout(() => this.remove(), 250);
        }
    }

    private onClickClose() {
        this.closeModal();
    }

    private onDocumentKeyDown(event: KeyboardEvent) {
        if (!this.disableAutoClose && event.key === "Escape") {
            this.closeModal();
        }
    }

    private onClickOverlay(event: PointerEvent) {
        if (!this.open || this.disableAutoClose) return;

        const composedPath = event.composedPath();
        const isModalTarget = composedPath.includes(this._modal);

        if (!isModalTarget) {
            this.closeModal();
        }
    }

    private async onChangeSlottedContent() {
        await this.updateComplete;

        const contentSlot = this.shadowRoot.querySelector("slot:not([name])") as HTMLSlotElement;
        const assignedElements = contentSlot?.assignedElements() || [];
        const slottedWizard = findElementByTagName(assignedElements, "ATLAS-WIZARD");

        slottedWizard?.toggleAttribute("hide-header", true);
        slottedWizard?.toggleAttribute("is-modal-wizard", true);

        this._hasSlottedWizard = !!slottedWizard;
    }

    private onChangeSlottedActions() {
        this._showFooter = !isEmptySlot(this, "actions");
    }

    private renderCloseButton() {
        return when(
            !this.hideCloseButton && !this.headerImg && !this.skeletonLoading,
            () => html`
                <atlas-icon-button
                    icon="x"
                    size="3x"
                    theme="secondary"
                    class="close-button"
                    @click=${this.onClickClose}
                ></atlas-icon-button>
            `
        );
    }

    private renderHeader() {
        if (this.headerLogo) {
            return html`
                <div class="modal-title">
                    <img class="header-logo" src=${this.headerLogo} alt=${this.headerImgDescription} />
                </div>
            `;
        }

        if (this.headerImg) {
            return html`
                <div class="modal-title">
                    <img class="header-img" src=${this.headerImg} alt=${this.headerImgDescription} />
                </div>
            `;
        }

        if (this.headerIllustration) {
            return html`
                <div class="modal-title">
                    <div class="illustration-container">
                        <atlas-illustration
                            name=${this.headerIllustration}
                            alt=${this.headerImgDescription}
                            scale-vertically
                        ></atlas-illustration>
                    </div>
                </div>
            `;
        }

        return html`
            <div class="modal-title">
                <atlas-heading size="h5">${this.header}</atlas-heading>
            </div>
        `;
    }

    public render() {
        const isMobileFullscreen = this._deviceController.isMobile && this._hasSlottedWizard;
        const isFullScreen = this.size === "fullscreen" || isMobileFullscreen;

        const modalOverlayClass = {
            "modal-overlay": true,
            "fade": true,
            "show": this._isVisible
        };

        const modalClass = {
            "modal": true,
            "fade-and-scale": true,
            "show": this._isVisible,
            "with-image": !!this.headerImg,
            "with-illustration": !!this.headerIllustration,
            "with-logo": !!this.headerLogo,
            "show-footer": this._showFooter,
            "is-wizard": this._hasSlottedWizard,
            [`size-${isFullScreen ? "fullscreen" : this.size}`]: !!this.size || isFullScreen
        };

        const modalStyle = {
            height: this.height && !isFullScreen ? `${this.height}px` : null
        };

        return html`
            <div class=${classMap(modalOverlayClass)} @click=${this.onClickOverlay}>
                <div class=${classMap(modalClass)} style=${styleMap(modalStyle)}>
                    ${this.renderCloseButton()} ${this.renderHeader()}
                    <div class="modal-content">
                        <slot @slotchange=${this.onChangeSlottedContent}></slot>
                    </div>
                    <div class="modal-footer">
                        <slot name="actions" @slotchange=${this.onChangeSlottedActions}></slot>
                    </div>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-modal": AtlasModal;
    }
}
