import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { when } from "lit/directives/when.js";

import styles from "./atlas-breadcrumb-item.scss";
import "@/components/display/atlas-icon/atlas-icon";

export type BreadcrumbItemProps = {
    "text": string;
    "icon": string;
    "href": string;
    "is-first-item": boolean;
    "is-last-item": boolean;
};

/**
 * @dependency atlas-icon
 *
 * @tag atlas-breadcrumb-item
 */
@customElement("atlas-breadcrumb-item")
export default class AtlasBreadcrumbItem extends LitElement {
    static styles = styles;

    /** Nome que irá aparecer no breadcrumb */
    @property({ type: String }) text: string;

    /** Ícone que irá aparecer quando o item for o primeiro do breadcrumb */
    @property({ type: String }) icon: string;

    /** Link ao qual o usuário será redirecionado ao clicar no item */
    @property({ type: String }) href: string;

    /**
     * @internal
     * Indica que é o primeiro item do breadcrumb (É definida pelo atlas-breadcrumb)
     */
    @property({ type: Boolean, attribute: "is-first-item" }) isFirstItem: boolean;

    /**
     * @internal
     * Indica que é o último item do breadcrumb (É definida pelo atlas-breadcrumb)
     */
    @property({ type: Boolean, attribute: "is-last-item" }) isLastItem: boolean;

    private renderArrow() {
        return when(
            !this.isFirstItem,
            () => html`<atlas-icon name="chevron-right" size="2x" theme="secondary"></atlas-icon>`
        );
    }

    private renderContent() {
        const icon = when(this.isFirstItem, () => html`<atlas-icon name=${this.icon} size="2x"></atlas-icon>`);

        return when(
            !!this.href && !this.isLastItem,
            () => html`
                <a class="breadcrumb-item-content" href="${this.href}">
                    ${icon}
                    <span>${this.text}</span>
                </a>
            `,
            () => html`
                <div class="breadcrumb-item-content">
                    ${icon}
                    <span>${this.text}</span>
                </div>
            `
        );
    }

    /** @internal */
    public render() {
        return html`<div class="breadcrumb-item">${this.renderArrow()} ${this.renderContent()}</div>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-breadcrumb-item": AtlasBreadcrumbItem;
    }
}
