import { html } from "lit";
import { customElement, property, queryAsync, state } from "lit/decorators.js";
import { map } from "lit/directives/map.js";
import { range } from "lit/directives/range.js";

import { Watch } from "@/decorators/watch";
import type AtlasTableRow from "@/components/table/atlas-table-row/atlas-table-row";

import type AtlasTableCol from "../atlas-table-col/atlas-table-col";
import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-table-body.scss";

export type TableColProps = AtlasElementProps & {
    "has-actions": boolean;
    "selectable": boolean;
};

/**
 * @prop {boolean} has-actions - Indica se a table tem ações
 * @prop {boolean} selectable - Indica se a table permite múltipla seleção
 *
 * @tag atlas-table-body
 */
@customElement("atlas-table-body")
export default class AtlasTableBody extends AtlasElement {
    static styles = styles;

    @property({ type: Boolean, attribute: "has-actions" }) hasActions = false;

    @property({ type: Boolean }) selectable = false;

    @state() private _headerColsProps: any[] = [];

    @queryAsync("slot") private _defaultSlot: HTMLSlotElement;

    async getSlottedRows() {
        return (
            (await this._defaultSlot)
                ?.assignedElements({ flatten: true })
                .filter((element) => element.tagName === "ATLAS-TABLE-ROW") || []
        );
    }

    @Watch(["selectable", "hasActions"])
    async applyPropsInSlots() {
        (await this.getSlottedRows()).forEach((row: AtlasTableRow) => {
            row.toggleAttribute("selectable", this.selectable);
            row.toggleAttribute("has-actions", this.hasActions);
        });
    }

    applyPropsFromHeader(colsProps: any[]) {
        this._headerColsProps = colsProps;
        this.onChangeSlottedRows();
    }

    async onChangeSlottedRows() {
        await this.updateComplete;

        if (!this._headerColsProps || this._headerColsProps.length === 0) return;

        (await this.getSlottedRows()).forEach(async (row: AtlasTableRow) => {
            (await row.getSlottedCols()).forEach((col: AtlasTableCol, index: number) => {
                Object.entries(this._headerColsProps[index]).forEach(([attribute, value]) => {
                    if (typeof value === "boolean") {
                        col.toggleAttribute(attribute, value);
                    } else {
                        col.setAttribute(attribute, `${value}`);
                    }
                });
            });

            row.toggleAttribute("selectable", this.selectable);
            row.toggleAttribute("has-actions", this.hasActions);
        });
    }

    renderElement() {
        return html`<slot @slotchange=${this.onChangeSlottedRows}></slot>`;
    }

    renderSkeleton() {
        return map(
            range(5),
            () => html`
                <atlas-table-row skeleton-loading ?selectable=${this.selectable} ?has-actions=${this.hasActions}>
                    ${this._headerColsProps.map(() => html`<atlas-table-col></atlas-table-col>`)}
                </atlas-table-row>
            `
        );
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-table-body": AtlasTableBody;
    }
}
