import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";
import { getAssetPath } from "@/helpers/base-path";

import AtlasElement, { type AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-avatar.scss";
import "@/components/display/atlas-badge/atlas-badge";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-icon-button/atlas-icon-button";
import "@/components/layout/atlas-layout/atlas-layout";

export type AvatarProps = AtlasElementProps & {
    "image": string;
    "user-name": string;
    "hoverable": boolean;
    "active": boolean;
    "show-carret": boolean;
    "show-icon-mobile": boolean;
    "show-badge": boolean;
};

/**
 * @dependency atlas-icon
 * @dependency atlas-icon-button
 */
@customElement("atlas-avatar")
export default class AtlasAvatar extends AtlasElement {
    static styles = styles;

    /** Imagem que será exibida no avatar */
    @property({ type: String }) image: string;

    /** Nome do usuário (usado para descrição da imagem) */
    @property({ type: String, attribute: "user-name" }) userName: string;

    /** Indica se o componente tem o comportamento de hover, atribuindo um padding adicional e efeitos ao passar o mouse sobre ele */
    @property({ type: Boolean, reflect: true }) hoverable = false;

    /** Indica se o componente está ativo, deve ser utilizado em conjunto com a propriedade `hoverable` */
    @property({ type: Boolean }) active = false;

    /** Indica se deve aparecer uma seta ao lado do avatar. (Útil pra quando é exibido um dropdown ao clicar sobre o avatar) */
    @property({ type: Boolean, attribute: "show-carret" }) showCarret = false;

    /** Indica se quando a tela tiver o tamanho de um dispositivo móvel, um ícone de usuário vai aparecer no lugar do avatar */
    @property({ type: Boolean, attribute: "show-icon-mobile" }) showIconMobile = false;

    /** Indica se deve aparecer um badge acima do avatar */
    @property({ type: Boolean, attribute: "show-badge" }) showBadge = false;

    private deviceController = new DeviceController(this);

    private getAvatarPath() {
        return this.image || getAssetPath("assets/images/user-icon.svg");
    }

    private renderBadge() {
        return when(this.showBadge, () => html`<atlas-badge></atlas-badge>`);
    }

    protected renderSkeleton() {
        return html`
            <atlas-layout gap="1" alignment="center" inline>
                <div class="avatar-img skeleton-circle"></div>
                ${when(this.showCarret, () => html`<atlas-icon></atlas-icon>`)}
            </atlas-layout>
        `;
    }

    protected renderElement() {
        return when(
            this.showIconMobile && this.deviceController.isMobile,
            () => html`
                <atlas-icon-button
                    icon="user"
                    size="3x"
                    ?active=${this.active}
                    ?show-badge=${this.showBadge}
                ></atlas-icon-button>
            `,
            () => html`
                <img class="avatar-img" src=${this.getAvatarPath()} alt=${this.userName} />
                ${this.renderBadge()}
                ${when(this.showCarret, () => html`<atlas-icon name="chevron-down"></atlas-icon>`)}
            `
        );
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-avatar": AtlasAvatar;
    }
}
