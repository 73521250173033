import { html } from "lit";
import { customElement, property, queryAsync } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import type AtlasTableCol from "@/components/table/atlas-table-col/atlas-table-col";
import { TableSort } from "@/components/table/atlas-table/types";
import { emit } from "@/internals/events";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-table-header.scss";
import "@/components/form/atlas-checkbox/atlas-checkbox";
import "@/components/table/atlas-table-col/atlas-table-col";
import { Watch } from "@/decorators/watch";

export type TableHeaderProps = AtlasElementProps & {
    "has-actions": boolean;
    "selectable": boolean;
    "all-selected": boolean;
};

/**
 * @dependency atlas-text
 * @dependency atlas-icon
 * @dependency atlas-checkbox
 *
 * @prop {boolean} has-actions - Indica se a table tem ações
 * @prop {boolean} selectable - Indica se a table permite múltipla seleção
 * @prop {boolean} all-selected - Indica se todos os registros da table estão selecionados
 * @prop {boolean} selection-disabled - Indica que a seleção de todos os registros está desabilitada **É informado automaticamente**
 *
 * @event {CustomEvent} atlas-table-change-sorting - Evento disparado quando é clicado para alterar a ordenação de alguma coluna
 * @event {CustomEvent} atlas-table-select-all - Evento disparado quando é clicado no checkbox para selecionar todos os registros
 * @event {CustomEvent} atlas-table-header-sync-cols - Evento disparado para sincronizar algumas propriedades das colunas do cabeçalho com as colunas do corpo
 *
 * @tag atlas-table-header
 */
@customElement("atlas-table-header")
export default class AtlasTableHeader extends AtlasElement {
    static styles = styles;

    @property({ type: Boolean, attribute: "has-actions" }) hasActions = false;

    @property({ type: Boolean }) selectable = false;

    @property({ type: Boolean, attribute: "all-selected", reflect: true }) allSelected = false;

    @property({ type: Boolean, attribute: "selection-disabled", reflect: true }) selectionDisabled = false;

    @queryAsync("slot") private _defaultSlot: HTMLSlotElement;

    connectedCallback(): void {
        super.connectedCallback?.();

        this.onClickHeaderCol = this.onClickHeaderCol.bind(this);
        this.onClickSelectAllColumn = this.onClickSelectAllColumn.bind(this);
    }

    async getSlottedCols(): Promise<AtlasTableCol[]> {
        const defaultSlot = await this._defaultSlot;
        const assignedElements = defaultSlot?.assignedElements({ flatten: true }) || [];
        return assignedElements.filter((element) => element.tagName === "ATLAS-TABLE-COL") as AtlasTableCol[];
    }

    getColumnNewSort(currentSort: TableSort) {
        let newSort: TableSort = "none";

        if (!currentSort || currentSort === "none") {
            newSort = "asc";
        } else if (currentSort === "asc") {
            newSort = "desc";
        }

        return newSort;
    }

    onClickHeaderCol(event: CustomEvent) {
        const column = event.target as AtlasTableCol;

        if (column.sortable) {
            this.onClickSort(column);
        }
    }

    async onClickSort(column: AtlasTableCol) {
        const newOrder = this.getColumnNewSort(column.getSort());

        const slottedCols = await this.getSlottedCols();

        slottedCols.forEach((col: AtlasTableCol) => {
            col.setSort(column.name === col.name ? newOrder : "none");
        });

        emit(this, "atlas-table-change-sorting", {
            detail: { column: column.name, order: newOrder }
        });
    }

    onClickSelectAllColumn() {
        if (this.selectionDisabled) return;

        setTimeout(() => {
            this.allSelected = !this.allSelected;

            emit(this, "atlas-table-select-all", {
                detail: this.allSelected
            });
        }, 0);
    }

    @Watch(["isHeaderColumn"])
    async applyPropsInSlots() {
        const slottedCols = await this.getSlottedCols();
        slottedCols.forEach((col: AtlasTableCol) => {
            col.toggleAttribute("is-header-column", true);
        });
    }

    async onChangeSlottedCols() {
        await this.updateComplete;

        const colsProps: any[] = [];

        const slottedCols = await this.getSlottedCols();

        slottedCols.forEach((col: AtlasTableCol) => {
            col.toggleAttribute("is-header-column", true);
            col.addEventListener("atlas-table-col-click", this.onClickHeaderCol);

            colsProps.push({
                "size": col.getAttribute("size"),
                "ellipsis": col.hasAttribute("ellipsis"),
                "disable-line-break": col.hasAttribute("disable-line-break"),
                "column-label": col.innerText.trim()
            });
        });

        emit(this, "atlas-table-header-sync-cols", {
            detail: colsProps,
            trackDisable: true
        });
    }

    renderSelectAll() {
        return when(
            this.selectable,
            () => html`
                <atlas-table-col
                    is-header-column
                    is-selection-column
                    ?is-selection-disabled=${this.selectionDisabled}
                    @atlas-table-col-click=${this.onClickSelectAllColumn}
                >
                    <atlas-checkbox ?checked=${this.allSelected} ?disabled=${this.selectionDisabled}></atlas-checkbox>
                </atlas-table-col>
            `
        );
    }

    renderActionsColumn() {
        return when(
            this.hasActions,
            () => html` <atlas-table-col is-header-column is-action-column>Ações</atlas-table-col> `
        );
    }

    render() {
        return html`
            ${this.renderSelectAll()}
            <slot @slotchange=${this.onChangeSlottedCols}></slot>
            ${this.renderActionsColumn()}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-table-header": AtlasTableHeader;
    }
}
