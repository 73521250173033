export const filterEmptyNodes = function (nodes: Array<Node>): Array<Node> {
    return nodes.filter((node) => {
        if (node.nodeName === "#text") {
            return node.textContent.trim() !== "";
        }

        return true;
    });
};

export const closestElementComposed = function (selector: string, base = this) {
    function __closestFrom(el: Element | Document | Window): Element {
        if (!el || el instanceof Document || el instanceof Window) return null;

        const found = el.closest(selector);

        return found || __closestFrom((el.getRootNode() as ShadowRoot).host);
    }

    return __closestFrom(base);
};

export const filterElementsByTagNames = function <K extends Uppercase<keyof HTMLElementTagNameMap>>(
    elements: Element[],
    tagNames: K[]
): HTMLElementTagNameMap[Lowercase<K>][] {
    const tagNamesSet = new Set(tagNames);

    return elements.filter(({ tagName }) => tagNamesSet.has(tagName as K)) as HTMLElementTagNameMap[Lowercase<K>][];
};

export const findElementByTagName = function <TagName extends Uppercase<keyof HTMLElementTagNameMap>>(
    elements: Element[],
    tagName: TagName
): HTMLElementTagNameMap[Lowercase<TagName>] {
    return elements.find((element) => element.tagName === tagName) as HTMLElementTagNameMap[Lowercase<TagName>];
};
