import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { classMap } from "lit/directives/class-map.js";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-helper.scss";

import { emit } from "@/internals/events";
import "@/components/display/atlas-icon/atlas-icon";

export type HelperProps = AtlasElementProps & {
    message: string;
    href: string;
    external: boolean;
};

/**
 * @dependency atlas-icon
 * 
 * @event {CustomEvent} atlas-helper-click - Evento lançado quando houver um clique no helper
 * 
 * @tag atlas-helper
 */
@customElement("atlas-helper")
export default class AtlasHelper extends AtlasElement {
    static styles = styles;

    /** A mensagem que será exibida no helper */
    @property({ type: String }) message: string = "";

    /** Link que será aberto ao clicar no helper */
    @property({ type: String }) href: string = "";

    /** Indica se o link que vai ser aberto ao clicar no helper é um link externo */
    @property({ type: Boolean }) external: boolean;

    private onClickHelper() {
        emit(this, "atlas-helper-click");
    }

    private renderContent() {
        return html`
            <atlas-icon name="help-circle" size="3x" class="helper-icon"></atlas-icon>
            <span class="helper-text">
                ${when(!!this.message, () => this.message, () => html`<slot></slot>`)}
            </span>
            <atlas-icon name="chevron-right" size="3x" class="helper-icon"></atlas-icon>
        `;
    }

    /**
     * @internal
     */
    public render() {
        const helperClass = {
            "atlas-helper": true
        };

        return when(
            !!this.href,
            () => html`
                <a
                    class="${classMap(helperClass)}"
                    href=${this.href}
                    rel="${ifDefined(this.external ? "noreferrer noopener" : undefined)}"
                    target="${ifDefined(this.external ? "_blank" : undefined)}"
                    @click=${this.onClickHelper}
                >
                    ${this.renderContent()}
                </a>
            `,
            () => html`
                <button
                    class="${classMap(helperClass)}"
                    @click=${this.onClickHelper}
                >
                    ${this.renderContent()}
                </button>
            `
        );
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-helper": AtlasHelper;
    }
}
