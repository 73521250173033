import { html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import AtlasElement, { type AtlasElementProps } from "@/components/atlas-element";
import type AtlasSidebarMenuItem from "@/components/structure/atlas-sidebar-menu-item/atlas-sidebar-menu-item";
import DeviceController from "@/controllers/device-controller";
import { filterElementsByTagNames } from "@/internals/elements";
import { emit } from "@/internals/events";

import styles from "./atlas-sidebar-menu-context.scss";

import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-text/atlas-text";
import "@/components/display/atlas-popover/atlas-popover";
import "@/components/structure/atlas-sidebar-menu/atlas-sidebar-menu";

export type SidebarMenuContextProps = AtlasElementProps & {
    "icon": string;
    "text": string;
    "active": boolean;
    "href": string;
    "sidebar-collapsed": boolean;
    "items-visible": boolean;
};

/**
 * Um menu de contexto serve para agrupar itens de menu relacionados. Ele é semelhante a um item de navegação,
 * porém não tem comportamento de link. Ele é utilizado para exibir um menu com os itens de menu relacionados
 * a um contexto específico.
 *
 * @dependency atlas-icon
 * @dependency atlas-text
 * @dependency atlas-popover
 * @dependency atlas-sidebar-menu
 *
 * @event {CustomEvent} atlas-sidebar-menu-context-click - Evento disparado quando o menu de contexto é clicado
 * @event {CustomEvent} atlas-sidebar-menu-context-active - Evento disparado quando o menu de contexto é ativado (pelo menos um item está ativo)
 * @event {CustomEvent} atlas-sidebar-menu-context-change-slotted-items - Evento disparado quando ocorre uma alteração no slot principal do menu de contexto (adição ou remoção de itens, por exemplo)
 * @event {CustomEvent} atlas-sidebar-menu-context-slotted-item-update - Evento disparado quando um item do menu de contexto tem suas propriedades alteradas
 *
 * @tag atlas-sidebar-menu-context
 */
@customElement("atlas-sidebar-menu-context")
export default class AtlasSidebarMenuContext extends AtlasElement {
    static styles = styles;

    /** O ícone associado ao contexto do menu */
    @property({ type: String }) icon: string;

    /** O nome do contexto */
    @property({ type: String }) text: string;

    /** Indica que o menu de contexto está ativo */
    @property({ type: Boolean, reflect: true }) active = false;

    /** O link associado ao menu de contexto (serve como atalho para algum item associado ao contexto) */
    @property({ type: String }) href: string;

    /**
     * @internal
     * Indica que os itens do menu de contexto estão visíveis
     * É definido pelo componente `atlas-new-sidebar`
     */
    @property({ type: Boolean, attribute: "items-visible", reflect: true }) itemsVisible = false;

    /**
     * @internal
     * Indica que a sidebar está colapsada
     */
    @property({ type: Boolean, attribute: "sidebar-collapsed", reflect: true }) sidebarCollapsed = false;

    @query("slot:not([name])") private _mainSlot: HTMLSlotElement;

    @state() private _slottedItems: AtlasSidebarMenuItem[] = [];

    private _activeSlottedItem: AtlasSidebarMenuItem | null = null;

    private _deviceController = new DeviceController(this);

    /** @internal */
    public constructor() {
        super();

        this.bindMethods();
    }

    public disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this._mainSlot?.removeEventListener("atlas-element-update", this.onSlottedItemsUpdateProperties);
    }

    /** @internal */
    public syncActive() {
        this._activeSlottedItem = this._slottedItems.find((slottedItem) => slottedItem.active) || null;

        this.active = this._activeSlottedItem !== null;

        if (!this.active) return;

        this.emitActivateEvent();
    }

    /**
     * @internal
     * Retorna os itens de menu associados ao menu de contexto
     * @returns {AtlasSidebarMenuItem[]} - Os itens de menu associados ao menu de contexto
     */
    public getItems(): AtlasSidebarMenuItem[] {
        return this._slottedItems;
    }

    /**
     * @internal
     * Ativa o menu de contexto a partir de um item de menu ativo
     */
    public activate(item: AtlasSidebarMenuItem) {
        if (!item || item === this._activeSlottedItem) return;

        this._activeSlottedItem = item;

        this._activeSlottedItem.toggleAttribute("active", true);

        this.active = true;

        this.emitActivateEvent();
    }

    /**
     * @internal
     * Desativa o menu de contexto
     */
    public deactivate() {
        if (!this.active) return;

        this._activeSlottedItem?.toggleAttribute("active", false);

        this.active = false;
    }

    private bindMethods() {
        this.onClick = this.onClick.bind(this);
        this.onContentSlotChanged = this.onContentSlotChanged.bind(this);
        this.onSlottedItemsUpdateProperties = this.onSlottedItemsUpdateProperties.bind(this);
    }

    private emitActivateEvent() {
        emit(this, "atlas-sidebar-menu-context-active");
    }

    private getTheme() {
        return this.active ? "primary" : "secondary";
    }

    private getSlottedItemByHref() {
        if (!this.href || this._slottedItems.length === 0) return null;

        return this._slottedItems.find((item) => item.href === this.href) || null;
    }

    private async onContentSlotChanged() {
        await this.updateComplete;

        const assignedElements = this._mainSlot?.assignedElements() || [];
        const items = filterElementsByTagNames(assignedElements, ["ATLAS-SIDEBAR-MENU-ITEM"]);

        this._slottedItems = [...items];

        this._mainSlot?.removeEventListener("atlas-element-update", this.onSlottedItemsUpdateProperties);
        this._mainSlot?.addEventListener("atlas-element-update", this.onSlottedItemsUpdateProperties);

        this.syncActive();

        emit(this, "atlas-sidebar-menu-context-change-slotted-items", { trackDisable: true });
    }

    private onClick() {
        const anchorItem = this.getSlottedItemByHref();

        if (anchorItem) {
            anchorItem.click();
            this.activate(anchorItem);
        }

        emit(this, "atlas-sidebar-menu-context-click");
    }

    private async onSlottedItemsUpdateProperties(event: CustomEvent<Map<string, any>>) {
        const item = event.target as AtlasSidebarMenuItem;

        const popoverItem = await this.getPopoverItem(item);

        const cloneItem = item.cloneNode(true) as AtlasSidebarMenuItem;
        cloneItem.toggleAttribute("in-menu-context", true);
        cloneItem.toggleAttribute("in-menu-context-popover", true);

        popoverItem?.replaceWith(cloneItem);

        emit(this, "atlas-sidebar-menu-context-slotted-item-update", { detail: { item }, trackDisable: true });
    }

    private async getPopoverItem(item: AtlasSidebarMenuItem) {
        await this.updateComplete;

        if (!this.hasPopover()) return null;

        const popover = this.shadowRoot.querySelector("atlas-popover");
        const selector = `atlas-sidebar-menu-item[value="${item.value}"][text="${item.text}"]`;
        const popoverItem = popover.querySelector(selector) as AtlasSidebarMenuItem;

        return popoverItem;
    }

    private hasPopover() {
        return !this._deviceController.isMobile && !this.href && (this.sidebarCollapsed || !this.itemsVisible);
    }

    private renderIcon() {
        return html`
            <atlas-icon class="atlas-sidebar-menu-context-icon" name=${this.icon} theme=${this.getTheme()}>
            </atlas-icon>
        `;
    }

    private renderText() {
        return html`
            <atlas-text
                class="atlas-sidebar-menu-context-text"
                ?bold=${this.active && !this._deviceController.isMobile}
                size=${this._deviceController.isMobile ? "md" : "xs"}
                theme=${this.getTheme()}
            >
                ${this.text}
            </atlas-text>
        `;
    }

    private renderRightArrow() {
        return when(
            this._deviceController.isMobile,
            () => html` <atlas-icon name="chevron-right" theme=${this.getTheme()}></atlas-icon> `
        );
    }

    private renderContentSlot() {
        return html`
            <slot class="atlas-sidebar-menu-context-content-slot" @slotchange=${this.onContentSlotChanged}> </slot>
        `;
    }

    private renderPopover() {
        const popoverItems = this._slottedItems.map((item) => item.cloneNode(true) as AtlasSidebarMenuItem);

        popoverItems.forEach((item) => {
            item.toggleAttribute("in-menu-context", true);
            item.toggleAttribute("in-menu-context-popover", true);
        });

        return when(
            this.hasPopover(),
            () => html`
                <atlas-popover
                    width="284"
                    max-height="400"
                    trigger="hover"
                    in-menu-context
                    placement="right-start"
                    id="atlas-sidebar-menu-context-popover"
                >
                    <div class="atlas-sidebar-menu-context-popover-content">
                        <atlas-sidebar-menu> ${popoverItems} </atlas-sidebar-menu>
                    </div>
                </atlas-popover>
            `
        );
    }

    /** @internal */
    public render() {
        const menuContextClass = {
            "atlas-sidebar-menu-context": true,
            "active": this.active
        };

        return html`
            <div class="atlas-sidebar-menu-context-wrapper" data-atlas-popover="atlas-sidebar-menu-context-popover">
                <button class=${classMap(menuContextClass)} @click=${this.onClick}>
                    ${this.renderIcon()} ${this.renderText()} ${this.renderRightArrow()}
                </button>
                ${this.renderContentSlot()} ${this.renderPopover()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-sidebar-menu-context": AtlasSidebarMenuContext;
    }
}
