import { html, HTMLTemplateResult, LitElement } from "lit";
import { property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import type { Theme } from "@/internals/theme";

import type { BadgeProps } from "@/components/display/atlas-badge/atlas-badge";
import type { BadgeType } from "@/components/display/atlas-badge/types";
import type { OverlayPlacement, OverlayTrigger } from "@/components/display/overlay";

import type { Placement } from "@floating-ui/dom";

import type { Constructor, WithPrefix } from "../util-types";

import "@/components/display/atlas-badge/atlas-badge";

export type WithBadgeProps = WithPrefix<BadgeProps, "badge">;

export declare class WithBadgeInterface {
    badgeType: BadgeType;

    badgeText: string;

    badgeIcon: string;

    badgeTheme: Theme;

    badgeIsCounter: boolean;

    badgeShowZero: boolean;

    badgeTooltip: string;

    badgeTooltipPlacement: Placement;

    badgeTooltipTrigger: OverlayTrigger;

    getAllBadgeProps(): Partial<WithBadgeProps>;

    hasBadge(): boolean;

    renderBadge(): HTMLTemplateResult;
}

export const WithBadgeMixin = <T extends Constructor<LitElement>>(superClass: T) => {
    /**
     * @dependency atlas-badge
     */
    class WithBadgeClass extends superClass implements WithBadgeInterface {
        /** Tipo do tema do badge */
        @property({ type: String, attribute: "badge-type" }) badgeType: BadgeType = "filled";

        /** Texto de conteúdo do badge */
        @property({ type: String, attribute: "badge-text" }) badgeText: string;

        /** Nome do ícone de apoio visual do badge */
        @property({ type: String, attribute: "badge-icon" }) badgeIcon: string;

        /** Tema do badge */
        @property({ type: String, attribute: "badge-theme" }) badgeTheme: Theme;

        /** Indica se é um badge de contador */
        @property({ type: Boolean, attribute: "badge-is-counter" }) badgeIsCounter: boolean = false;

        /** Indica se o badge deve mostrar o valor 0 quando ele for um contador */
        @property({ type: Boolean, attribute: "badge-show-zero" }) badgeShowZero: boolean = false;

        /** Texto do tooltip do badge */
        @property({ type: String, attribute: "badge-tooltip" }) badgeTooltip: string;

        /** A posição em relação ao badge que o tooltip será exibido */
        @property({ type: String, attribute: "badge-tooltip-placement" }) badgeTooltipPlacement: OverlayPlacement =
            "bottom";

        /** O gatilho que irá acionar o tooltip do badge */
        @property({ type: String, attribute: "badge-tooltip-trigger" }) badgeTooltipTrigger: OverlayTrigger =
            "hover focus";

        getAllBadgeProps(): Partial<WithBadgeProps> {
            return {
                "badge-type": this.badgeType,
                "badge-text": this.badgeText,
                "badge-icon": this.badgeIcon,
                "badge-theme": this.badgeTheme,
                "badge-is-counter": this.badgeIsCounter,
                "badge-show-zero": this.badgeShowZero,
                "badge-tooltip": this.badgeTooltip,
                "badge-tooltip-placement": this.badgeTooltipPlacement,
                "badge-tooltip-trigger": this.badgeTooltipTrigger
            };
        }

        hasBadge(): boolean {
            return !!this.badgeText;
        }

        renderBadge(): HTMLTemplateResult {
            return when(
                !!this.hasBadge(),
                () => html`
                    <atlas-badge
                        icon=${this.badgeIcon}
                        text=${this.badgeText}
                        theme=${this.badgeTheme || "primary"}
                        type=${this.badgeType}
                        tooltip=${this.badgeTooltip}
                        tooltip-placement=${this.badgeTooltipPlacement}
                        tooltip-trigger=${this.badgeTooltipTrigger}
                        ?is-counter=${this.badgeIsCounter}
                        ?show-zero=${this.badgeShowZero}
                    ></atlas-badge>
                `
            );
        }
    }

    return WithBadgeClass as Constructor<WithBadgeInterface> & T;
};
