import {css} from "lit-element/lit-element.js";
export default css`:host([width=full]) {
  width: 100%;
}

:host([width=xs]) {
  width: 96px;
}

:host([width=sm]) {
  width: 152px;
}

:host([width=md]) {
  width: 264px;
}

:host([width=lg]) {
  width: 380px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2NvZGVidWlsZC9vdXRwdXQvc3JjNTIxMzU0MzQxL3NyYy9wYWNrYWdlcy93ZWJjb21wb25lbnRzL3NyYy9jb21wb25lbnRzL2Zvcm0vYXRsYXMtc2VsZWN0Iiwic291cmNlcyI6WyIuLi8uLi8uLi9zdHlsZXMvY29tcG9uZW50cy9faW5wdXQtd2lkdGguc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFTSTtFQUNJLE9BVk87OztBQVNYO0VBQ0ksT0FWTzs7O0FBU1g7RUFDSSxPQVZPOzs7QUFTWDtFQUNJLE9BVk87OztBQVNYO0VBQ0ksT0FWTyIsInNvdXJjZXNDb250ZW50IjpbIiRpbnB1dC13aWR0aHM6IChcbiAgICBmdWxsOiAxMDAlLFxuICAgIHhzOiA5NnB4LFxuICAgIHNtOiAxNTJweCxcbiAgICBtZDogMjY0cHgsXG4gICAgbGc6IDM4MHB4XG4pO1xuXG5AZWFjaCAkd2lkdGgsICR2YWx1ZSBpbiAkaW5wdXQtd2lkdGhzIHtcbiAgICA6aG9zdChbd2lkdGg9XCIjeyR3aWR0aH1cIl0pIHtcbiAgICAgICAgd2lkdGg6ICR2YWx1ZTtcbiAgICB9XG59XG4iXX0= */`;
