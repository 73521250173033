import { html } from "lit";
import { property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import { WithPopoverMixin, WithPopoverProps } from "@/internals/mixins/with-popover-mixin";
import FormElement, { FormElementProps } from "./form-element";

import "./atlas-helper-text/atlas-helper-text";
import "@/components/display/atlas-icon-button/atlas-icon-button";
import "@/components/display/atlas-text/atlas-text";

export type FormControlProps = FormElementProps &
    WithPopoverProps & {
        "label": string;
        "helper-text": string;
        "hide-optional": boolean;
    };

type WrapHelperTextSlotProps = {
    assignedElements: Element[];
    isToggleHelper?: boolean;
    isInputHelper?: boolean;
};

export default class FormControl extends WithPopoverMixin(FormElement) {
    /** A label que ficará acima do input determinando o nome do mesmo*/
    @property({ type: String }) label = "";

    /** O texto auxiliar que pode ser utilizado no input */
    @property({ type: String, attribute: "helper-text" }) helperText = "";

    /** Indica se o texto (Opcional) deve ser oculto da label */
    @property({ type: Boolean, attribute: "hide-optional" }) hideOptional: boolean;

    protected _hasSlottedHelperText = false;

    protected renderHelperText(isToggleHelper?: boolean, isInputHelper?: boolean) {
        return when(
            this.helperText,
            () => html`
                <atlas-helper-text id=${this.id} ?is-toggle-helper=${isToggleHelper} ?is-input-helper=${isInputHelper}>
                    ${this.helperText}
                </atlas-helper-text>
            `,
            () => html`
                <slot
                    name="helper-text"
                    @slotchange=${() => this.onHelperTextSlotChange(isToggleHelper, isInputHelper)}
                ></slot>
            `
        );
    }

    protected renderLabel() {
        return when(
            this.label !== "",
            () => html`
                <label for="${this.id}" class="form-label">
                    <atlas-text>${this.label} ${this.renderOptional()}</atlas-text>
                    ${this.renderPopoverInfoIcon()}
                </label>
            `
        );
    }

    private wrapHelperTextSlot({ assignedElements, isToggleHelper, isInputHelper }: WrapHelperTextSlotProps) {
        const wrapper = document.createElement("atlas-helper-text");

        wrapper.setAttribute("id", this.id);
        wrapper.setAttribute("slot", "helper-text");
        wrapper.toggleAttribute("is-toggle-helper", isToggleHelper);
        wrapper.toggleAttribute("is-input-helper", isInputHelper);

        assignedElements[0].before(wrapper);

        assignedElements.forEach((element) => {
            element.removeAttribute("slot");

            wrapper.appendChild(element);
        });
    }

    private onHelperTextSlotChange(isToggleHelper?: boolean, isInputHelper?: boolean) {
        const helperTextSlot = this.shadowRoot.querySelector("slot[name=helper-text]") as HTMLSlotElement;
        const assignedElements = helperTextSlot?.assignedElements() || [];
        const slottedHelperTexts = assignedElements.filter((element) => element.tagName === "ATLAS-HELPER-TEXT");

        if (slottedHelperTexts.length > 0) {
            slottedHelperTexts.forEach((element) => {
                element.setAttribute("id", this.id);
                element.toggleAttribute("is-toggle-helper", isToggleHelper);
                element.toggleAttribute("is-input-helper", isInputHelper);
            });

            this._hasSlottedHelperText = true;

            return;
        }

        if (assignedElements.length > 0) {
            this.wrapHelperTextSlot({ assignedElements, isToggleHelper, isInputHelper });
            this._hasSlottedHelperText = true;

            return;
        }

        this._hasSlottedHelperText = false;
    }

    private renderOptional() {
        return when(
            !this.required && !this.hideOptional,
            () => html`<span class="form-label-optional">(Opcional)</span>`
        );
    }
}
