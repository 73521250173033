import {css} from "lit-element/lit-element.js";
export default css`.breadcrumb {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.breadcrumb .breadcrumb-items {
  display: flex;
  gap: 0.5rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2NvZGVidWlsZC9vdXRwdXQvc3JjNTIxMzU0MzQxL3NyYy9wYWNrYWdlcy93ZWJjb21wb25lbnRzL3NyYy9jb21wb25lbnRzL2Rpc3BsYXkvYXRsYXMtYnJlYWRjcnVtYiIsInNvdXJjZXMiOlsiYXRsYXMtYnJlYWRjcnVtYi5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0k7RUFDQTtFQUNBOztBQUVBO0VBQ0k7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5icmVhZGNydW1iIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgZ2FwOiAwLjI1cmVtO1xuXG4gICAgLmJyZWFkY3J1bWItaXRlbXMge1xuICAgICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgICBnYXA6IDAuNXJlbTtcbiAgICB9XG59XG4iXX0= */`;
