import { customElement, property } from "lit/decorators.js";

import Inputmask from "@/vendors/inputmask-utils";
import AtlasInput, { InputProps } from "@/components/form/atlas-input/atlas-input";

import { removeNonNumeric } from "@/helpers/formatters";
import RangeValidator from "@/internals/validators/range-validator";

export type IntegerInputProps = InputProps & {
    "max-value": number;
    "max-value-error-message": string;
    "min-value": number;
    "min-value-error-message": string;
    "allow-negative": boolean;
};

/**
 * @extends atlas-input
 *
 * @tag atlas-integer-input
 */
@customElement("atlas-integer-input")
export default class AtlasIntegerInput extends AtlasInput {
    /** Valor máximo que o input pode receber */
    @property({ type: Number, attribute: "max-value" }) maxValue: number;

    /** Mensagem exibida no input caso o valor seja maior que o valor máximo */
    @property({ type: String, attribute: "max-value-error-message" }) maxValueErrorMessage: string;

    /** Valor mínimo que o input pode receber */
    @property({ type: Number, attribute: "min-value" }) minValue: number;

    /** Mensagem exibida no input caso o valor seja menor que o valor mínimo */
    @property({ type: String, attribute: "min-value-error-message" }) minValueErrorMessage: string;

    /** Indica que o campo aceita valores negativos */
    @property({ type: Boolean, attribute: "allow-negative" }) allowNegative = false;

    /** Indica que o campo aceita zeros à esquerda */
    @property({ type: Boolean, attribute: "allow-leading-zero" }) allowLeadingZero = false;

    private _maskInstance: Inputmask.Instance;

    private _maskOptions: Inputmask.Options = {};

    /**
     * @internal
     * @override
     */
    public connectedCallback() {
        super.connectedCallback?.();

        this.updateComplete.then(() => {
            this.buildMask();
        });
    }

    /**
     * @internal
     * @override
     */
    public onChangeValue() {
        this.formatValueWithMaskIfNecessary();

        super.onChangeValue();
    }

    /**
     * Retorna o valor do campo sem a máscara
     * @returns {string | number} - O valor do campo sem a máscara
     */
    public getUnmaskedValue(): string | number {
        return this._maskInstance.unmaskedvalue();
    }

    protected async handlePaste(event: ClipboardEvent) {
        if (this.allowLeadingZero) {
            super.handlePaste?.(event);
            return;
        }

        await this.updateComplete;

        const pastedValue = removeNonNumeric(event.clipboardData.getData("text"), {
            allowNegative: this.allowNegative
        });
        if (!pastedValue) return;

        const { selectionStart, selectionEnd } = this._input;
        const currentValue = this.value ? String(this.value) : "";

        const isFullSelection = selectionStart === 0 && selectionEnd === currentValue.length;

        if (isFullSelection) {
            this.value = pastedValue;
        } else {
            this.value = this.getCombinedValue(pastedValue, selectionStart, selectionEnd);
        }

        super.handlePaste?.(event);
    }

    private getCombinedValue(pastedValue: string, selectionStart: number, selectionEnd: number): string {
        const currentValue = this.value ? String(this.value) : "";

        const combinedValue = currentValue.slice(0, selectionStart) + pastedValue + currentValue.slice(selectionEnd);

        return removeNonNumeric(combinedValue, { allowNegative: this.allowNegative });
    }

    private formatValueWithMaskIfNecessary() {
        if (!Inputmask.isValid(this.value, this._maskOptions)) {
            this.value = Inputmask.format(this.value, this._maskOptions);
        }
    }

    private buildMask() {
        this.inputMode = "numeric";
        if (this.allowLeadingZero) this.placeholder = this.placeholder ?? "0".repeat(this.maxlength || 0);

        this._maskOptions = {
            allowMinus: this.allowNegative,
            alias: this.allowLeadingZero ? "onlyNumericDigits" : "integer"
        };

        if (!this.allowLeadingZero) {
            // @ts-expect-error
            this._maskOptions.onBeforePaste = () => false;
        }

        this._maskInstance = Inputmask(this._maskOptions).mask(this._input);

        // @ts-expect-error
        this._maskInstance.shadowRoot = this.shadowRoot;

        this.addValidator(new RangeValidator(this.maxValueErrorMessage, this.minValueErrorMessage));

        if (this.value) this.formatValueWithMaskIfNecessary();
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-integer-input": AtlasIntegerInput;
    }
}
