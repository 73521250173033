import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";
import { Watch } from "@/decorators/watch";
import { filterEmptyNodes } from "@/internals/elements";

import AtlasElement from "@/components/atlas-element";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-text/atlas-text";
import styles from "./atlas-fixed-alert.scss";

export type FixedAlertProps = {
    header: string;
    type: "success" | "error" | "warning" | "info";
    size: "sm" | "md";
    icon: "string";
    inline: boolean;
};

/**
 * @dependency atlas-heading
 * @dependency atlas-icon
 * @dependency atlas-text
 *
 * @tag atlas-fixed-alert
 */
@customElement("atlas-fixed-alert")
export default class AtlasFixedAlert extends AtlasElement {
    static styles = styles;

    /**  Título do alerta */
    @property({ type: String }) header: string;

    /** Tipo do alerta */
    @property({ type: String }) type: "success" | "error" | "warning" | "info" = "info";

    /** Tamanho do alerta */
    @property({ type: String }) size: "sm" | "md" = "md";

    /** Ícone do alerta (caso nenhum seja especificado, será renderizado o ícone padrão de cada tema) */
    @property({ type: String }) icon: string;

    /** Indica que o conteúdo do alerta deve ser disposto lado a lado */
    @property({ type: Boolean, reflect: true }) inline: boolean = false;

    @state() private _hasSlottedContent = false;

    @state() private _skeletonLoadingHeight: number | null = null;

    private _deviceController = new DeviceController(this);

    /** @internal */
    @Watch("skeletonLoading", true)
    public updateHeightOnSkeletonLoading() {
        if (!this.skeletonLoading) return;

        this._skeletonLoadingHeight = this.getBoundingClientRect().height;
    }

    private getAlertIcon() {
        if (this.icon) return this.icon;

        return {
            success: "check",
            error: "alert-triangle",
            warning: "alert-triangle",
            info: "info"
        }[this.type];
    }

    private getAlertIconSize() {
        return this._deviceController.isMobile && this.size === "sm" ? "2x" : "3x";
    }

    private getAlertTheme() {
        return {
            success: "success",
            error: "danger",
            warning: "warning",
            info: "info",
            primary: "primary"
        }[this.type];
    }

    private getAlertTextSize() {
        if (this.size === "sm") return this._deviceController.isMobile ? "xs" : "sm";

        return "md";
    }

    private getAlertHeadingSize() {
        return this._deviceController.isMobile && this.size === "sm" ? "h6" : "h5";
    }

    private async onChangeSlottedContent() {
        await this.updateComplete;

        if (this.skeletonLoading) return;

        const slottedNodes = this.shadowRoot.querySelector("slot").assignedNodes({ flatten: true });
        const filteredNodes = filterEmptyNodes(slottedNodes);

        this._hasSlottedContent = filteredNodes.length > 0;
    }

    /** @override */
    protected renderSkeleton() {
        const containerStyles = {
            height: this._skeletonLoadingHeight ? `${this._skeletonLoadingHeight}px` : null
        };

        return html` <div class="fixed-alert skeleton" style=${styleMap(containerStyles)}></div> `;
    }

    private renderHeader() {
        return when(
            !!this.header,
            () => html`
                <atlas-heading size=${this.getAlertHeadingSize()} theme=${this.getAlertTheme()} theme-variation="800">
                    ${this.header}
                </atlas-heading>
            `
        );
    }

    /** @override */
    protected renderElement() {
        const alertClass = {
            "fixed-alert": true,
            "inline": this.inline,
            [`alert-${this.type}`]: true,
            [`alert-${this.size}`]: true,
            "has-content": this._hasSlottedContent
        };

        return html`
            <div class=${classMap(alertClass)}>
                <atlas-icon name=${this.getAlertIcon()} size=${this.getAlertIconSize()}></atlas-icon>
                <div class="alert-content">
                    ${this.renderHeader()}
                    <atlas-text theme=${this.getAlertTheme()} theme-variation="800" size=${this.getAlertTextSize()}>
                        <slot @slotchange=${this.onChangeSlottedContent}></slot>
                    </atlas-text>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-fixed-alert": AtlasFixedAlert;
    }
}
