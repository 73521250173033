import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { emit } from "@/internals/events";
import { Watch } from "@/decorators/watch";

import { isEmptySlot } from "@/internals/slot";

import TriggerController, { type TriggeredElement } from "@/controllers/trigger-controller";
import AtlasElement, { type AtlasElementProps } from "@/components/atlas-element";

import styles from "./atlas-offcanvas.scss";

import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-image/atlas-image";

export type OffcanvasProps = AtlasElementProps & {
    "open": boolean;
    "header": string;
    "header-image": string;
    "header-image-description": string;
    "remove-close-button": boolean;
    "prevent-close-overlay": boolean;
    "remove-padding": boolean;
    "filter": boolean;
};

/**
 * @dependency atlas-icon
 * @dependency atlas-heading
 * @dependency atlas-image
 *
 * @slot - Slot padrão para o conteúdo do offcanvas
 * @slot actions - Slot para ações do offcanvas
 *
 * @event {CustomEvent} atlas-offcanvas-open - Evento disparado quando o offcanvas é exibido
 * @event {CustomEvent} atlas-offcanvas-close - Evento disparado quando o offcanvas é fechado
 *
 * @tag atlas-offcanvas
 */
@customElement("atlas-offcanvas")
export default class AtlasOffcanvas extends AtlasElement implements TriggeredElement {
    static styles = styles;

    /** Define se Offcanvas está aberto */
    @property({ type: Boolean, reflect: true }) open: boolean;

    /** Define o título do cabeçalho do offcanvas */
    @property({ type: String }) header: string;

    /** Imagem que irá aparecer no cabeçalho do offcanvas, no lugar do título */
    @property({ type: String, attribute: "header-image" }) headerImage: string;

    /** Texto descritivo da imagem do cabeçalho */
    @property({ type: String, attribute: "header-image-description" }) headerImageDescription: string;

    /** Define se o botão de fechar o offcanvas deve ser removido */
    @property({ type: Boolean, attribute: "remove-close-button" }) removeCloseButton: boolean;

    /** Define se o offcanvas deve prevenir o fechamento quando clicado fora */
    @property({ type: Boolean, attribute: "prevent-close-overlay" }) preventCloseOverlay: boolean;

    /** Remove o padding do conteúdo do offcanvas */
    @property({ type: Boolean, attribute: "remove-padding" }) removePadding: boolean;

    /**
     * @internal
     * Define que offcanvas é utilizado dentro do contexto de filtro
     */
    @property({ type: Boolean }) filter: boolean;

    @state() private _showFooter: boolean = false;

    private _triggerController: TriggerController;

    constructor() {
        super();

        this._triggerController = new TriggerController(this, "click");
        this.onClickBackdropOffcanvas = this.onClickBackdropOffcanvas.bind(this);
    }

    /**
     * @internal
     */
    @Watch("open")
    public onOpenChange() {
        document.body.classList.toggle("disable-scroll-offcanvas", this.open);

        if (this.open) {
            this.onOpenOverlay();
        } else {
            this.onCloseOverlay();
        }
    }

    /**
     * Mostra o offcanvas
     */
    public show() {
        this._triggerController.show();
    }

    /**
     * Esconde o offcanvas
     */
    public hide() {
        this._triggerController.hide();
    }

    /**
     * Alterna a visibilidade do offcanvas
     */
    public toggle() {
        this._triggerController.toggle();
    }

    /**
     * @internal
     */
    public onTriggerActivate() {
        this.open = this._triggerController.open;
    }

    protected async onOpenOverlay() {
        await this.updateComplete;

        setTimeout(() => {
            emit(this, `atlas-offcanvas-open`, { trackDisable: true });
        }, 0);
    }

    protected async onCloseOverlay() {
        await this.updateComplete;

        setTimeout(() => {
            emit(this, `atlas-offcanvas-close`, { trackDisable: true });
        }, 0);
    }

    private onClickBackdropOffcanvas(event: PointerEvent) {
        if (!this.open || this.preventCloseOverlay) {
            return;
        }

        const composedPath = event.composedPath();
        const isOffCanvasTarget = composedPath.includes(this.shadowRoot.querySelector(".atlas-offcanvas"));

        if (!isOffCanvasTarget) {
            this.hide();
        }
    }

    private onChangeSlottedActions() {
        this._showFooter = !isEmptySlot(this, "actions");
    }

    private renderTitle() {
        const titleClass = {
            "with-close-button": !this.removeCloseButton
        };

        if (this.headerImage) {
            return html` <atlas-image src="${this.headerImage}" alt="${this.headerImageDescription}"></atlas-image>`;
        }

        return html`<atlas-heading size="h5" class="${classMap(titleClass)}" ellipsis>${this.header}</atlas-heading>`;
    }

    private renderHeader() {
        const headerClass = {
            "atlas-offcanvas-header": true,
            "with-image": !!this.headerImage
        };

        return html`<div class="${classMap(headerClass)}">${this.renderTitle()} ${this.renderCloseButton()}</div>`;
    }

    private renderCloseButton() {
        return when(
            !this.removeCloseButton,
            () => html`
                <button class="close-button" @click=${this.hide}>
                    <atlas-icon name="x" size="3x" theme="secondary"></atlas-icon>
                </button>
            `
        );
    }

    /** @internal */
    public render() {
        const offCanvasBackdropClass = {
            "atlas-offcanvas-backdrop": true,
            "show": this.open
        };

        const offCanvasClass = {
            "atlas-offcanvas": true,
            "show-footer": this._showFooter
        };

        const offCanvasContentClass = {
            "atlas-offcanvas-content": true,
            "remove-padding": this.removePadding,
            "filter": this.filter
        };

        return html`
            <div class="${classMap(offCanvasBackdropClass)}" @click=${this.onClickBackdropOffcanvas}>
                <div class="${classMap(offCanvasClass)}">
                    ${this.renderHeader()}
                    <div class="${classMap(offCanvasContentClass)}">
                        <slot></slot>
                    </div>
                    <div class="atlas-offcanvas-footer">
                        <slot name="actions" @slotchange=${this.onChangeSlottedActions}></slot>
                    </div>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-offcanvas": AtlasOffcanvas;
    }
}
