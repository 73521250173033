import { html, unsafeStatic } from "lit/static-html.js";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";
import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";
import { Theme } from "@/internals/theme";

import type { GroupedButtonProps, MoreButtonType } from "./types";
import type { IconButtonSize } from "../atlas-icon-button/types";
import type { ButtonType } from "../atlas-button/types";

import AtlasElement, { type AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-button-group.scss";

import "@/components/display/atlas-button/atlas-button";
import "@/components/display/atlas-dropdown/atlas-dropdown";
import "@/components/display/atlas-dropdown-item/atlas-dropdown-item";

export type ButtonGroupProps = AtlasElementProps & {
    "gap": number;
    "group-after": number;
    "group-all": boolean;
    "more-button-type": MoreButtonType;
    "more-button-theme": Theme;
    "more-button-size": IconButtonSize;
    "loading": boolean;
    "loading-text": string;
};

/**
 * @dependency atlas-button
 * @dependency atlas-dropdown
 * @dependency atlas-dropdown-item
 *
 * @slot - Slot para adicionar botões ao grupo
 *
 * @tag atlas-button-group
 */
@customElement("atlas-button-group")
export default class AtlasButtonGroup extends AtlasElement {
    static styles = styles;

    /** Propriedade que define o espaço entre elementos (Deve ser um número entre 0 e 9) */
    @property({ type: Number }) gap: number = 4;

    /** Número de botões que devem ser exibidos antes de criar a opção "Mais" */
    @property({ type: Number, attribute: "group-after" }) groupAfter: number;

    /** Define que todos os botões serão agrupados */
    @property({ type: Boolean, attribute: "group-all" }) groupAll: boolean;

    /** Tipo do botão "Mais opções" */
    @property({ type: String, attribute: "more-button-type" }) moreButtonType: MoreButtonType = "filled";

    /** Tema do botão "Mais opções" */
    @property({ type: String, attribute: "more-button-theme" }) moreButtonTheme: Theme = "primary";

    /** (Para o tipo `icon`) Define o tamanho do botão */
    @property({ type: String, attribute: "more-button-size" }) moreButtonSize: IconButtonSize = "3x";

    /** Indica se o dropdown de "Mais opções" está em estado de loading */
    @property({ type: Boolean }) loading: boolean;

    /** Texto que é exibido dentro do dropdown de "Mais opções" quando ele esta em estado de loading */
    @property({ type: String, attribute: "loading-text" }) loadingText: string = "Carregando ações";

    @state() private _buttons: GroupedButtonProps[] = [];

    private _deviceController = new DeviceController(this);

    public connectedCallback(): void {
        super.connectedCallback?.();

        this.extractSlottedButtonsProps = this.extractSlottedButtonsProps.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);

        this.updateComplete.then(() => {
            this.shadowRoot
                .querySelector("slot")
                .addEventListener("atlas-element-update", this.extractSlottedButtonsProps);
        });
    }

    /**
     * Abre o dropdown de "Mais opções"
     */
    public openMoreOptions() {
        this.shadowRoot.querySelector("atlas-dropdown")?.show();
    }

    /**
     * Fecha o dropdown de "Mais opções"
     */
    public closeMoreOptions() {
        this.shadowRoot.querySelector("atlas-dropdown")?.hide();
    }

    /** @internal */
    @Watch("moreButtonType", true)
    public updateDropdownRef() {
        this.updateComplete.then(() => {
            this.shadowRoot.querySelector("atlas-dropdown")?.syncTriggerElement();
        });
    }

    private getButtonTheme(button: GroupedButtonProps): Theme {
        return (button.props.theme as Theme) || "primary";
    }

    private getCountBeforeMore() {
        if (this.groupAll || this._deviceController.isMobile) return 0;

        if (this.groupAfter) return this.groupAfter;

        return this._buttons.length;
    }

    private getShowMoreOptions() {
        return this._buttons.length > 1 && this._buttons.length > this.getCountBeforeMore();
    }

    private extractSlottedButtonsProps() {
        const slottedButtons = this.shadowRoot.querySelector("slot").assignedElements();

        this._buttons = slottedButtons.map((button: HTMLElement) => {
            const tag = button.tagName.toLowerCase();
            const attrs = button.attributes;
            const extractedProps: { [key: string]: any } = {};

            for (let i = 0; i < attrs.length; i++) {
                extractedProps[attrs[i].nodeName] = attrs[i].nodeValue;
            }

            return {
                tag,
                props: extractedProps,
                element: button
            };
        });
    }

    private onButtonClick(event: PointerEvent | CustomEvent) {
        const target = event.currentTarget as HTMLElement;
        const buttonIndex = parseInt(target.dataset.buttonIndex, 10);
        const buttonElement = this._buttons[buttonIndex]?.element;

        if (buttonElement.hasAttribute("disabled") || buttonElement.hasAttribute("loading")) return;

        const buttonGroupClickEvent = emit(this, "atlas-button-group-button-click", {
            detail: { button: buttonElement },
            trackDisable: true
        });

        if (buttonElement.hasAttribute("href")) event.stopPropagation();

        if (buttonGroupClickEvent.defaultPrevented) event.preventDefault();
    }

    private renderButtonsBeforeGroup() {
        let buttonsBeforeGroup = this._buttons.slice(0, this.getCountBeforeMore());

        if (buttonsBeforeGroup.length === 0 && this._buttons.length === 1) {
            buttonsBeforeGroup = [this._buttons[0]];
        }

        return buttonsBeforeGroup.map((button, index) => {
            const tag = unsafeStatic(button.tag);

            button.props.theme = this.getButtonTheme(button);

            let attributes = Object.entries(button.props).map((entry) => `${entry[0]}="${entry[1]}"`);

            if (button.tag === "atlas-icon-button") {
                attributes = attributes.filter((attr) => !attr.includes("size"));
                attributes.push("hoverable");
                attributes.push(`size="${this.moreButtonSize}"`);
            }

            /* eslint-disable lit/binding-positions, lit/no-invalid-html */
            return html`
                <${tag}
                    ${unsafeStatic(attributes.join(" "))}
                    data-button-index=${index}
                    @click=${this.onButtonClick}
                    data-track-disable
                ></${tag}>
            `;
            /* eslint-enable lit/binding-positions, lit/no-invalid-html */
        });
    }

    private renderGroupedButtons() {
        const groupedButtons = this._buttons.slice(this.getCountBeforeMore());

        return groupedButtons.map((button, index) => {
            const itemTheme = this.getButtonTheme(button);

            return html`
                <atlas-dropdown-item
                    class=${button.props.class}
                    theme=${itemTheme}
                    icon=${button.props.icon}
                    ?disabled=${"disabled" in button.props}
                    ?loading=${"loading" in button.props}
                    ?hidden=${"hidden" in button.props}
                    tooltip=${button.props.tooltip}
                    data-button-index=${this.getCountBeforeMore() + index}
                    @atlas-dropdown-item-click=${this.onButtonClick}
                    data-track-disable
                    href=${ifDefined(button.props.href)}
                    ?external=${"is_external_link" in button.props}
                >
                    ${button.props.description}
                </atlas-dropdown-item>
            `;
        });
    }

    private renderMoreButton() {
        if (!this.getShowMoreOptions()) {
            return null;
        }

        return when(
            this.moreButtonType === "icon",
            () => html`
                <atlas-icon-button
                    icon="three-dots"
                    data-atlas-dropdown="button-group-dropdown"
                    size=${this.moreButtonSize}
                    theme=${this.moreButtonTheme}
                    aria-label="Ações"
                    hoverable
                ></atlas-icon-button>
            `,
            () => html`
                <atlas-button
                    icon="three-dots"
                    data-atlas-dropdown="button-group-dropdown"
                    type=${this.moreButtonType as ButtonType}
                    theme=${this.moreButtonTheme}
                    aria-label="Ações"
                ></atlas-button>
            `
        );
    }

    private renderMoreDropdown() {
        return when(
            this.getShowMoreOptions(),
            () => html`
                <atlas-dropdown
                    id="button-group-dropdown"
                    no-gap
                    auto-close
                    ?loading=${this.loading}
                    loading-text=${this.loadingText}
                >
                    ${this.renderGroupedButtons()}
                </atlas-dropdown>
            `
        );
    }

    /** @internal */
    public render() {
        const buttonGroupClass = {
            "button-group": true,
            [`gap-${this.gap}`]: !!this.gap
        };

        return html`
            <div class=${classMap(buttonGroupClass)}>${this.renderButtonsBeforeGroup()}${this.renderMoreButton()}</div>
            <slot @slotchange=${this.extractSlottedButtonsProps}></slot>
            ${this.renderMoreDropdown()}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-button-group": AtlasButtonGroup;
    }
}
