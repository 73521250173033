import { customElement, property } from "lit/decorators.js";

import DeviceController from "@/controllers/device-controller";
import { emit } from "@/internals/events";

import AtlasSelect, { type SelectProps } from "../atlas-select/atlas-select";

export type AutocompleteProps = SelectProps & {
    "trigger-key-count": number;
};

/**
 * @event {CustomEvent} atlas-autocomplete-change - Evento disparado quando o valor do autocomplete é alterado
 *
 * @tag atlas-autocomplete
 */
@customElement("atlas-autocomplete")
export default class AtlasAutocomplete extends AtlasSelect {
    /** Número de caracteres que precisam ser digitados para ativar a busca do autocomplete */
    @property({ type: Number, attribute: "trigger-key-count" }) triggerKeyCount: number = 1;

    /** Mensagem que é exibida no dropdown quando o autocomplete não possui opções */
    @property({ type: String, attribute: "empty-state-text" }) emptyStateText: string =
        "Nenhum resultado encontrado. Tente novamente com outros termos.";

    private _deviceController = new DeviceController(this);

    /** @internal */
    public async connectedCallback() {
        await super.connectedCallback();

        this._hasDoneFirstSearch = true;
        this.onSelectChange = this.onSelectChange.bind(this);

        this.addEventListener("atlas-select-change", this.onSelectChange);
    }

    /** @internal */
    public disconnectedCallback() {
        this.removeEventListener("atlas-select-change", this.onSelectChange);
    }

    /** @override */
    protected shouldTriggerSearch() {
        return this._inputValue.length >= this.triggerKeyCount;
    }

    /** @override */
    protected shouldOpenDropdown() {
        return this._deviceController.isMobile || this._inputValue.length >= this.triggerKeyCount;
    }

    /** @override */
    protected hasSearch() {
        return true;
    }

    /** @override */
    protected onDropdownClose() {
        super.onDropdownClose();
        this._selectOptions = [];
    }

    /** @override */
    protected onSelectDropdownChange(event: CustomEvent) {
        super.onSelectDropdownChange(event);

        setTimeout(() => {
            this._selectDropdown.closeDropdown();
        }, 0);
    }

    /** @override */
    protected onSelectDropdownInputKeyDown() {
        super.onSelectDropdownInputKeyDown();

        setTimeout(() => {
            if (this._deviceController.isMobile && this._inputValue.length < this.triggerKeyCount) {
                this._selectOptions = [];
            }
        }, 0);
    }

    private onSelectChange(event: CustomEvent) {
        emit(this, "atlas-autocomplete-change", event.detail);
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-autocomplete": AtlasAutocomplete;
    }
}
