import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";
import { WithTooltipMixin, WithTooltipProps } from "@/internals/mixins/with-tooltip-mixin";

import { Theme } from "@/internals/theme";
import styles from "./atlas-dropdown-item.scss";

import "@/components/display/atlas-badge/atlas-badge";
import "@/components/display/atlas-icon/atlas-icon";

export type DropdownItemProps = WithTooltipProps & {
    "href": string;
    "icon": string;
    "theme": Theme;
    "external": boolean;
    "active": boolean;
    "disabled": boolean;
    "is-new": boolean;
    "loading": boolean;
};

/**
 * @dependency atlas-icon
 * @dependency atlas-badge
 *
 * @slot default - Slot padrão, usado para definir o texto do item
 *
 * @event {CustomEvent} atlas-dropdown-item-click - Evento lançado ao clicar sobre o item
 * @event {CustomEvent} atlas-changed-is-new - Evento lançado quando o atributo novo é alterado (Usado para integração com outros componentes)
 *
 * @tag atlas-dropdown-item
 */
@customElement("atlas-dropdown-item")
export default class AtlasDropdownItem extends WithTooltipMixin(LitElement) {
    static styles = styles;

    /** Link que será aberto ao clicar no item */
    @property({ type: String }) href: string;

    /** Ícone que irá aparecer ao lado esquerdo do texto */
    @property({ type: String }) icon: string;

    /** Tema que será aplicado no item, serve para customizar suas cores, baseado no tema passado */
    @property({ type: String }) theme: Theme;

    /** Indica se o link que vai ser aberto ao clicar sobre o item é um link externo */
    @property({ type: Boolean }) external = false;

    /** Indica se o item está ativo */
    @property({ type: Boolean }) active = false;

    /** Indica se o item está desabilitado */
    @property({ type: Boolean }) disabled = false;

    /** Indica se deve ser exibido a tag "Novo" ao lado do item */
    @property({ type: Boolean, attribute: "is-new", reflect: true }) isNew = false;

    /** Indica se o item está em estado de loading */
    @property({ type: Boolean }) loading: boolean;

    /** @internal */
    @Watch("isNew", true)
    public onChangeTagNew() {
        emit(this, "atlas-changed-is-new", { trackDisable: true });
    }

    private handleItemClick(event: PointerEvent) {
        if (this.disabled || this.loading) {
            return;
        }

        if (this.href) event.stopPropagation();

        const itemClick = emit(this, "atlas-dropdown-item-click");

        if (itemClick.defaultPrevented) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    private getThemeClass(): string {
        return this.theme ? `dropdown-item-${this.theme}` : "";
    }

    private renderIcon() {
        return when(
            !!this.icon || this.loading,
            () => html`<atlas-icon name=${this.loading ? "loader" : this.icon} size="2x"></atlas-icon>`
        );
    }

    /** @internal */
    public render() {
        const itemClass = {
            "dropdown-item": true,
            [this.getThemeClass()]: true,
            "active": this.active,
            "disabled": this.disabled || this.loading,
            "loading": this.loading
        };

        return html`
            <div class="dropdown-item-wrapper" data-atlas-tooltip="dropdown-item-tooltip">
                <a
                    class=${classMap(itemClass)}
                    href="${ifDefined(this.href || undefined)}"
                    rel="${ifDefined(this.external ? "noreferrer noopener" : undefined)}"
                    target="${ifDefined(this.external ? "_blank" : undefined)}"
                    @click=${this.handleItemClick}
                >
                    ${this.renderIcon()}
                    <span>
                        <slot></slot>
                    </span>
                    ${when(this.isNew, () => html`<atlas-badge text="Novo"></atlas-badge>`)}
                </a>
            </div>
            ${this.renderTooltip("dropdown-item-tooltip")}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-dropdown-item": AtlasDropdownItem;
    }
}
