import { html } from "lit";
import { customElement, property, queryAssignedElements, state } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import AtlasElement, { type AtlasElementProps } from "@/components/atlas-element";
import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";

import sidebarMenuStyles from "./atlas-sidebar-menu-item.scss";

import "@/components/layout/atlas-collapse/atlas-collapse";
import "@/components/structure/atlas-sidebar-menu/atlas-sidebar-menu";
import "@/components/display/atlas-badge/atlas-badge";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-tooltip/atlas-tooltip";

export type SidebarMenuItemProps = AtlasElementProps & {
    "value": string;
    "icon": string;
    "text": string;
    "href": string;
    "target": string;
    "active": boolean;
    "isNew": boolean;
    "owner": string;
    "nested": boolean;
    "shrinked": boolean;
    "in-menu-context-popover": boolean;
    "in-menu-context": boolean;
};

/**
 * @dependency atlas-collapse
 * @dependency atlas-sidebar-menu
 * @dependency atlas-badge
 * @dependency atlas-icon
 * @dependency atlas-tooltip
 *
 * @slot - Usado para incluir os itens filhos como um submenu
 *
 * @event {CustomEvent} atlas-menu-item-click - Evento disparado quando o item é clicado
 * @event {CustomEvent} atlas-menu-item-collapse - Evento disparado quando o item é colapsado
 *
 */
@customElement("atlas-sidebar-menu-item")
export default class AtlasSidebarMenuItem extends AtlasElement {
    static styles = sidebarMenuStyles;

    /** O valor do item */
    @property({ type: String }) value: string;

    /** O ícone do item (exibido ao lado esquerdo do nome) */
    @property({ type: String }) icon: string;

    /** O nome do item */
    @property({ type: String }) text: string;

    /** O link que será aberto ao clicar no item */
    @property({ type: String }) href: string;

    /** Indica onde o link deve ser aberto */
    @property({ type: String }) target: string;

    /** Indica que o item está ativo */
    @property({ type: Boolean, reflect: true }) active = false;

    /** Indica que o item é novo */
    @property({ type: Boolean, attribute: "is-new" }) isNew = false;

    /**
     * @internal
     * O atributo `value` do item pai
     */
    @property({ type: String }) owner!: string;

    /**
     * @internal
     * Indica que o item está em um submenu */
    @property({ type: Boolean }) nested = false;

    /**
     * @internal
     * Indica que o item está em uma versão encolhida do menu */
    @property({ type: Boolean }) shrinked = false;

    /**
     * @internal
     * Indica que o item está dentro de um popover de menu, adicionando estilos específicos para este caso.
     */
    @property({ type: Boolean, attribute: "in-menu-context-popover", reflect: true }) inMenuContextPopover = false;

    /**
     * @internal
     * Indica que o item está dentro de um contexto de menu, adicionando estilos específicos para este caso.
     */
    @property({ type: Boolean, attribute: "in-menu-context", reflect: true }) inMenuContext = false;

    @state() private _collapsed = true;

    @state() private _hasSlottedItems = false;

    @state() private _isNewSlottedItemsCount = 0;

    @state() private _hasTooltip = false;

    @queryAssignedElements({ selector: "atlas-sidebar-menu-item", flatten: true })
    private _slottedItems!: Array<AtlasSidebarMenuItem>;

    /** @internal */
    @Watch("active", true)
    public onChangeActive() {
        this.updateOwnerItemActive();
        this.updateSlottedItemsActive();
    }

    /** @internal */
    @Watch("isNew")
    public async onChangeIsNew() {
        await this.updateComplete;

        if (!this._slottedItems) return;

        this._isNewSlottedItemsCount = this._slottedItems.filter((item) => item.hasAttribute("is-new")).length;
    }

    /** @internal */
    @Watch(["shrinked", "nested"], false)
    public async setTooltip() {
        await this.updateComplete;

        setTimeout(() => {
            if (this.shrinked) {
                this._hasTooltip = true;
                return;
            }

            this._hasTooltip = this.checkIfTextOverflows() && !this.nested;
        }, 500);
    }

    /**
     * Altera o estado de colapso do item de menu (colapsado ou expandido)
     * Deve ser utilizado caso o item possua filhos
     * @param {boolean} collapsed - Novo estado de colapso do item
     */
    public setCollapsed(collapsed: boolean) {
        this._collapsed = collapsed;
    }

    /**
     * Executa o clique do mouse no item de menu
     */
    public click() {
        const anchor = this.shadowRoot.querySelector(".item-button") as HTMLAnchorElement;
        anchor?.click();
    }

    private onClickItem(event: PointerEvent) {
        if (!this._hasSlottedItems) {
            emit(this, "atlas-menu-item-click", { detail: { item: this.value, owner: this.owner } });
            return;
        }

        event.preventDefault();

        this.setCollapsed(!this._collapsed);

        emit(this, "atlas-menu-item-collapse", { detail: { item: this.value, collapsed: this._collapsed } });
    }

    private async onChangeSlottedItems() {
        await this.updateComplete;

        this._hasSlottedItems = this._slottedItems && this._slottedItems.length > 0;

        if (!this._hasSlottedItems) return;

        this._slottedItems.forEach((item) => {
            item.setAttribute("owner", this.value);
        });

        if (this.active) this.setCollapsed(false);
    }

    private updateOwnerItemActive() {
        if (!this.owner || !this.active) return;

        const rootNode = this.assignedSlot.getRootNode() as ShadowRoot;
        const host = rootNode.host as AtlasSidebarMenuItem;

        host.toggleAttribute("active", true);
    }

    private updateSlottedItemsActive() {
        if (!this._hasSlottedItems) return;

        this.setCollapsed(!this.active);

        if (!this.active) this._slottedItems.forEach((item) => item.removeAttribute("active"));
    }

    private checkIfTextOverflows() {
        const itemText = this.shadowRoot.querySelector(".item-text");
        const contentWidth = itemText.getBoundingClientRect().width;

        return Math.ceil(contentWidth) < itemText.scrollWidth;
    }

    private renderCollapseIcon() {
        return when(
            this._hasSlottedItems,
            () => html`<atlas-icon name="chevron-down" size="2x" class="collapse-icon"></atlas-icon>`
        );
    }

    private renderBadge() {
        return when(
            this.isNew || this._isNewSlottedItemsCount > 0,
            () => html`<atlas-badge text="${this._isNewSlottedItemsCount || 1}" is-counter></atlas-badge>`
        );
    }

    private renderTooltip() {
        return html`
            <atlas-tooltip id="menu-item-tooltip" placement="right" trigger="hover" ?disabled=${!this._hasTooltip}>
                ${this.text}
            </atlas-tooltip>
        `;
    }

    private renderNestedMenu() {
        return html`
            <atlas-collapse ?show=${!this._collapsed}>
                <atlas-sidebar-menu
                    nested
                    ?shrinked=${this.shrinked}
                    tabindex=${ifDefined(this._collapsed ? "-1" : undefined)}
                >
                    <slot @slotchange=${this.onChangeSlottedItems}></slot>
                </atlas-sidebar-menu>
            </atlas-collapse>
        `;
    }

    private renderAnchor() {
        return html`
            <a
                href=${ifDefined(this.href)}
                class="item-button"
                role="button"
                data-atlas-tooltip="menu-item-tooltip"
                target=${ifDefined(this.target ? this.target : undefined)}
                @click=${this.onClickItem}
            >
                <atlas-icon name="${this.icon}" size="3x"></atlas-icon>
                <span class="item-text">${this.text}</span>
                ${this.renderBadge()} ${this.renderCollapseIcon()}
            </a>
        `;
    }

    /** @internal */
    public render() {
        const itemClass = {
            "sidebar-menu-item": true,
            "collapsed": this._collapsed,
            "active": this.active,
            "nested": this.nested,
            "shrinked": this.shrinked,
            "in-menu-context-popover": this.inMenuContextPopover
        };

        return html`
            <div class="${classMap(itemClass)}">
                ${this.renderAnchor()} ${this.renderTooltip()} ${this.renderNestedMenu()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-sidebar-menu-item": AtlasSidebarMenuItem;
    }
}
