import {css} from "lit-element/lit-element.js";
export default css`.section-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

::slotted(atlas-section) {
  margin-bottom: 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2NvZGVidWlsZC9vdXRwdXQvc3JjNTIxMzU0MzQxL3NyYy9wYWNrYWdlcy93ZWJjb21wb25lbnRzL3NyYy9jb21wb25lbnRzL2xheW91dC9hdGxhcy1zZWN0aW9uLWdyb3VwIiwic291cmNlcyI6WyJhdGxhcy1zZWN0aW9uLWdyb3VwLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSTtFQUNBO0VBQ0E7OztBQUdKO0VBQ0kiLCJzb3VyY2VzQ29udGVudCI6WyIuc2VjdGlvbi1ncm91cCB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGdhcDogMC41cmVtO1xufVxuXG46OnNsb3R0ZWQoYXRsYXMtc2VjdGlvbikge1xuICAgIG1hcmdpbi1ib3R0b206IDA7XG59XG4iXX0= */`;
