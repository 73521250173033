import {css} from "lit-element/lit-element.js";
export default css`:host([width=full]) {
  width: 100%;
}

:host([width=xs]) {
  width: 96px;
}

:host([width=sm]) {
  width: 152px;
}

:host([width=md]) {
  width: 264px;
}

:host([width=lg]) {
  width: 380px;
}

.atlas-multiselect-container {
  position: relative;
  cursor: pointer;
}
.atlas-multiselect-container.has-search {
  cursor: text;
}
.atlas-multiselect-container atlas-tag {
  position: absolute;
  left: 0.75rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2NvZGVidWlsZC9vdXRwdXQvc3JjNTIxMzU0MzQxL3NyYy9wYWNrYWdlcy93ZWJjb21wb25lbnRzL3NyYy9jb21wb25lbnRzL2Zvcm0vYXRsYXMtbXVsdGlzZWxlY3QiLCJzb3VyY2VzIjpbIi4uLy4uLy4uL3N0eWxlcy9jb21wb25lbnRzL19pbnB1dC13aWR0aC5zY3NzIiwiYXRsYXMtbXVsdGlzZWxlY3Quc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFTSTtFQUNJLE9BVk87OztBQVNYO0VBQ0ksT0FWTzs7O0FBU1g7RUFDSSxPQVZPOzs7QUFTWDtFQUNJLE9BVk87OztBQVNYO0VBQ0ksT0FWTzs7O0FDRWY7RUFDSTtFQUNBOztBQUVBO0VBQ0k7O0FBR0o7RUFDSTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiJGlucHV0LXdpZHRoczogKFxuICAgIGZ1bGw6IDEwMCUsXG4gICAgeHM6IDk2cHgsXG4gICAgc206IDE1MnB4LFxuICAgIG1kOiAyNjRweCxcbiAgICBsZzogMzgwcHhcbik7XG5cbkBlYWNoICR3aWR0aCwgJHZhbHVlIGluICRpbnB1dC13aWR0aHMge1xuICAgIDpob3N0KFt3aWR0aD1cIiN7JHdpZHRofVwiXSkge1xuICAgICAgICB3aWR0aDogJHZhbHVlO1xuICAgIH1cbn1cbiIsIkBpbXBvcnQgXCIuLi9hdGxhcy1zZWxlY3QvYXRsYXMtc2VsZWN0XCI7XG5cbi5hdGxhcy1tdWx0aXNlbGVjdC1jb250YWluZXIge1xuICAgIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgICBjdXJzb3I6IHBvaW50ZXI7XG5cbiAgICAmLmhhcy1zZWFyY2gge1xuICAgICAgICBjdXJzb3I6IHRleHQ7XG4gICAgfVxuXG4gICAgYXRsYXMtdGFnIHtcbiAgICAgICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgICAgICBsZWZ0OiAwLjc1cmVtO1xuICAgIH1cbn1cbiJdfQ== */`;
