import { html } from "lit";
import { customElement, property, query, queryAsync } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { getAllFormElements } from "@/helpers/form";
import { showConfirmation } from "@/helpers/notifications";
import { closeAllOverlays } from "@/helpers/overlay";
import { emit } from "@/internals/events";
import { type WithBadgeProps } from "@/internals/mixins/with-badge-mixin";

import type AtlasButton from "@/components/display/atlas-button/atlas-button";
import type AtlasForm from "@/components/form/atlas-form/atlas-form";
import AtlasPanel, { type PanelProps } from "@/components/layout/atlas-panel/atlas-panel";

import styles from "./atlas-form-panel.scss";
import "@/components/display/atlas-button/atlas-button";
import "@/components/form/atlas-form/atlas-form";
import "@/components/layout/atlas-layout/atlas-layout";
import "@/components/layout/atlas-toolbar/atlas-toolbar";

export type FormPanelProps = PanelProps & {
    "submit-button-label": string;
    "action": string;
    "method": "get" | "post";
    "target": string;
    "editing": boolean;
    "hide-badge": boolean;
    "ignore-reset-on-cancel": boolean;
    "show-confirmation-on-cancel": boolean;
};

/**
 * @dependency atlas-button
 * @dependency atlas-form
 * @dependency atlas-layout
 * @dependency atlas-toolbar
 *
 * @event {CustomEvent} atlas-form-panel-start-editing - Evento lançado quando a edição do formulário é iniciada
 * @event {CustomEvent} atlas-form-panel-end-editing - Evento lançado quando a edição do formulário é finalizada
 * @event {CustomEvent} atlas-form-panel-cancel - Evento lançado quando o cancel do formulário será executado
 * @event {CustomEvent} atlas-form-panel-submit - Evento lançado quando o submit do formulário será executado
 *
 * @tag atlas-form-panel
 */
@customElement("atlas-form-panel")
export default class AtlasFormPanel extends AtlasPanel {
    static styles = styles;

    /** Label do botão que faz o submit do formulário do painel */
    @property({ type: String, attribute: "submit-button-label" }) submitButtonLabel: string;

    /** URL que será alvo do formulário */
    @property({ type: String }) action: string;

    /** Método HTTP que será usado para o envio do formulário (get | post) */
    @property({ type: String }) method: "get" | "post" = "get";

    /** Onde será exibido o corpo da resposta do formulário */
    @property({ type: String }) target: string;

    /** Indica se o panel está em modo de edição, se passado ao renderizar o elemento, o painel já vem em modo de edição */
    @property({ type: Boolean }) editing: boolean = false;

    /** Indica se a badge "Em edição" deve ser oculta do painel */
    @property({ type: Boolean, attribute: "hide-badge" }) hideBadge: boolean = false;

    /** Indica se ao clicar em cancelar o formulário não deve ser resetado */
    @property({ type: Boolean, attribute: "ignore-reset-on-cancel" }) ignoreResetOnCancel: boolean = false;

    /** Indica se deve ser exibido um modal de confirmação ao clicar em cancelar */
    @property({ type: Boolean, attribute: "show-confirmation-on-cancel" }) showConfirmationOnCancel: boolean = false;

    @query(".action-button-submit") private _submitButton: AtlasButton;

    @query(".action-button-cancel") private _cancelButton: AtlasButton;

    @queryAsync("atlas-form") private _panelForm: AtlasForm;

    private _intersectionObserver: IntersectionObserver;

    private _mutationObserver: MutationObserver;

    private _badgePropsBeforeEditing: Partial<WithBadgeProps> = {};

    public connectedCallback(): void {
        super.connectedCallback?.();

        this.onActionButtonClick = this.onActionButtonClick.bind(this);
        this.onChangeEditing = this.onChangeEditing.bind(this);
        this.onStartEditing = this.onStartEditing.bind(this);
        this.onEndEditing = this.onEndEditing.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.observeFooterActions = this.observeFooterActions.bind(this);

        this.addEventListener("atlas-button-click", this.onActionButtonClick);
        this.addEventListener("atlas-dropdown-item-click", this.onActionButtonClick);
        this.addEventListener("atlas-form-submit", this.onFormSubmit);

        this._deviceController.setScreenChangeCallback(this.observeFooterActions);

        this.updateComplete.then(() => {
            this.onActionsSlotChange();
            this.observeContentChange();
        });
    }

    public disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-button-click", this.onActionButtonClick);
        this.removeEventListener("atlas-dropdown-item-click", this.onActionButtonClick);
        this.removeEventListener("atlas-form-submit", this.onFormSubmit);

        this._intersectionObserver?.disconnect();
        this._mutationObserver?.disconnect();
    }

    /**
     * Cancela a edição do painel
     */
    public async cancelEditing() {
        this.editing = false;

        if (!this.ignoreResetOnCancel) {
            (await this._panelForm).reset();
        }
    }

    /**
     * Finaliza o modo de edição do painel
     */
    public finishEditing() {
        this.editing = false;
    }

    /**
     * Ativa os botões do painel
     */
    public enablePanelButtons() {
        this._submitButton.loading = false;
        this._cancelButton.enable();
    }

    /**
     * @override
     */
    protected onActionsSlotChange() {
        if (this.editing) {
            this._hasSlottedActions = true;
            return;
        }

        super.onActionsSlotChange();
    }

    private onActionButtonClick(event: CustomEvent) {
        const target = event.target as HTMLElement;

        if (target.hasAttribute("data-panel-start-editing")) {
            this.editing = true;
        }
    }

    private onStartEditing() {
        this.togglePanelFormElements(true);
        this.enablePanelButtons();

        this._badgePropsBeforeEditing = this.getAllBadgeProps();
        this.updatePanelBadge();

        emit(this, "atlas-form-panel-start-editing", { trackDisable: true });
    }

    private onEndEditing() {
        this.togglePanelFormElements(false);
        this.updatePanelBadge();

        this._badgePropsBeforeEditing = {};

        emit(this, "atlas-form-panel-end-editing", { trackDisable: true });
    }

    private onFormSubmit(event: CustomEvent) {
        event.stopPropagation();

        this._submitButton.loading = true;
        this._cancelButton.disable();

        const submitEvent = emit(this, "atlas-form-panel-submit", { trackDisable: true });

        if (submitEvent.defaultPrevented) {
            event.preventDefault();
        }
    }

    /** @internal */
    @Watch("editing")
    public async onChangeEditing() {
        await this.updateComplete;
        closeAllOverlays();
        this.observeFooterActions();

        if (this.editing) {
            this.onStartEditing();
        } else {
            this.onEndEditing();
        }
    }

    private async onClickCancelButton() {
        const cancelEvent = emit(this, "atlas-form-panel-cancel", { trackDisable: true });
        if (cancelEvent.defaultPrevented) return;

        if (!this.showConfirmationOnCancel || !this.hasUpdatedFormField()) {
            this.cancelEditing();
            return;
        }

        showConfirmation({
            illustration: "triangle-exclamation-mark-siren",
            size: "large",
            title: "Descartar alterações",
            content:
                "Algumas alterações não foram salvas. Ao cancelar, todas as informações serão perdidas. Tem certeza que deseja descartar as alterações?",
            confirmButton: {
                theme: "danger",
                description: "Descartar alterações"
            },
            disableAutoClose: true,
            hideCloseButton: true,
            onConfirm: async (modal) => {
                modal.closeModal();
                this.cancelEditing();
            }
        });
    }

    private async onClickSubmitButton() {
        (await this._panelForm).submit();
    }

    private hasUpdatedFormField() {
        return getAllFormElements(this).some((element) => element.hasUpdatedValue());
    }

    private togglePanelFormElements(enable: boolean) {
        setTimeout(() => {
            getAllFormElements(this).forEach((element) => {
                if (enable && element.hasAttribute("data-atlas-form-panel-keep-disabled")) return;

                element.toggleDisabled(!enable);
            });
        }, 0);
    }

    private async observeFooterActions() {
        await this.updateComplete;

        if (!this._deviceController.isMobile || !this.editing) {
            this._intersectionObserver?.disconnect();
            return;
        }

        this._intersectionObserver = new IntersectionObserver(this.onIntersection, {
            root: null,
            threshold: 0
        });

        this._intersectionObserver.observe(this.shadowRoot.querySelector(".footer-actions-anchor"));
    }

    private async observeContentChange() {
        await this.updateComplete;

        this._mutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
            const hasUpdatedContent = mutations.some((mutation) => mutation.type === "childList");

            if (hasUpdatedContent) {
                this.togglePanelFormElements(this.editing);
            }
        });

        this._mutationObserver.observe(this, { childList: true, subtree: true });
    }

    private onIntersection(entries: IntersectionObserverEntry[]) {
        entries.forEach((entry: IntersectionObserverEntry) => {
            entry.target.classList.toggle("is-intersecting", entry.isIntersecting || entry.boundingClientRect.top < 0);
        });
    }

    private updatePanelBadge() {
        if (this.editing && !this.hideBadge) {
            this.setAttribute("badge-type", "outlined");
            this.setAttribute("badge-text", "Em edição");
            this.setAttribute("badge-icon", "pencil");
            this.setAttribute("badge-theme", "primary");
            this.setAttribute("badge-tooltip", "");
            return;
        }

        Object.entries(this._badgePropsBeforeEditing).forEach(([prop, value]) => {
            if (typeof value === "boolean") {
                this.toggleAttribute(prop, value);
            } else if (value) {
                this.setAttribute(prop, value);
            } else {
                this.removeAttribute(prop);
            }
        });
    }

    private renderFormPanelActions() {
        return html`
            <atlas-layout
                gap="4"
                inline
                mobile-inline
                slot=${ifDefined(!this._deviceController.isMobile ? "actions" : undefined)}
            >
                <atlas-button
                    description="Cancelar"
                    theme="secondary"
                    @atlas-button-click=${this.onClickCancelButton}
                    class="action-button-cancel"
                ></atlas-button>
                <atlas-button
                    description=${this.submitButtonLabel || "Salvar"}
                    theme="success"
                    @atlas-button-click=${this.onClickSubmitButton}
                    class="action-button-submit"
                ></atlas-button>
            </atlas-layout>
        `;
    }

    private renderFooterActions() {
        return when(
            this._deviceController.isMobile && this.editing,
            () => html`
                <div class="footer-actions-anchor">
                    <div class="footer-actions">${this.renderFormPanelActions()}</div>
                </div>
            `
        );
    }

    /**
     * @override
     */
    protected renderContent() {
        return html`
            <atlas-form action=${this.action} method=${this.method} target=${this.target}>
                <atlas-layout gap="6"> ${super.renderContent()} ${this.renderFooterActions()} </atlas-layout>
            </atlas-form>
        `;
    }

    /**
     * @override
     */
    protected renderActionsToolbar() {
        if (this._deviceController.isMobile && this.editing) return null;

        return html`
            <atlas-toolbar only-actions ?has-visibility-icon=${this.hasVisibilityIcon}>
                ${when(
                    this.editing,
                    () => this.renderFormPanelActions(),
                    () => html`<slot slot="actions" name="actions" @slotchange=${this.onActionsSlotChange}></slot>`
                )}
            </atlas-toolbar>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-form-panel": AtlasFormPanel;
    }
}
