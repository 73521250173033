import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { TableSort } from "@/components/table/atlas-table/types";
import { emit } from "@/internals/events";
import DeviceController from "@/controllers/device-controller";

import { WithPopoverMixin, WithPopoverProps } from "@/internals/mixins/with-popover-mixin";
import { WithVisibilityIconMixin, WithVisiblityIconProps } from "@/internals/mixins/with-visibility-icon-mixin";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-table-col.scss";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-text/atlas-text";
import "@/components/display/atlas-tooltip/atlas-tooltip";

const ComposedClass = WithVisibilityIconMixin(WithPopoverMixin(AtlasElement));

export type TableColProps = AtlasElementProps &
    WithVisiblityIconProps &
    WithPopoverProps & {
        "name": string;
        "size": "sm" | "md" | "lg";
        "ellipsis": boolean;
        "sortable": boolean;
        "is-header-column": boolean;
        "is-selection-column": boolean;
        "is-action-column": boolean;
        "column-label": string;
        "disable-line-break": boolean;
    };

/**
 * @dependency atlas-icon
 * @dependency atlas-text
 *
 * @prop {string} name - Nome da coluna (Usado para ordenação)
 * @prop {"sm" | "md" | "lg"} size - Tamanho da coluna, serve também para definir qual será o tamanho mínimo que a coluna irá possuir (Se passado no cabeçalho as colunas das linhas da table também herdam essa propriedade)
 * @prop {boolean} ellipsis - Indica se a coluna deve possuir ellipsis quando o texto exceder o tamanho da coluna (Se passado no cabeçalho as colunas das linhas da table também herdam essa propriedade)
 * @prop {boolean} sortable - Indica se a coluna possui ordenação (Apenas para o cabeçalho)
 * @prop {boolean} is-header-column - Indica se a coluna está no cabeçalho **(É informado automáticamente)**
 * @prop {boolean} is-selection-column - Indica se a coluna é de seleção de registros **(É informado automaticamente)**
 * @prop {boolean} is-selection-disabled - Indica que a coluna (de seleção de registros) está desabilitada para seleção **(É informado automaticamente)**
 * @prop {boolean} is-action-column - Indica se a coluna é de ações **(É informado automaticamente)**
 * @prop {string} column-label - Label da coluna **(É informado automaticamente, usando o texto da coluna de cabeçalho)**
 * @prop {boolean} disable-line-break - Indica se o conteúdo da coluna não deve quebrar linhas
 *
 * @tag atlas-table-col
 */
@customElement("atlas-table-col")
export default class AtlasTableCol extends ComposedClass {
    static styles = styles;

    @property({ type: String }) name: string;

    @property({ type: String, reflect: true }) size: "sm" | "md" | "lg";

    @property({ type: Boolean }) ellipsis: boolean;

    @property({ type: Boolean }) sortable: boolean;

    @property({ type: Boolean, attribute: "is-header-column", reflect: true }) isHeaderColumn: boolean;

    @property({ type: Boolean, attribute: "is-selection-column", reflect: true }) isSelectionColumn: boolean;

    @property({ type: Boolean, attribute: "is-selection-disabled", reflect: true }) isSelectionDisabled: boolean;

    @property({ type: Boolean, attribute: "is-action-column", reflect: true }) isActionColumn: boolean;

    @property({ type: String, attribute: "column-label" }) columnLabel: string;

    @property({ type: Boolean, attribute: "disable-line-break" }) disableLineBreak: boolean = false;

    @property({ type: String }) sort: TableSort = "none";

    private _deviceController = new DeviceController(this);

    getSort() {
        return this.sort;
    }

    setSort(sort: TableSort) {
        this.sort = sort;
    }

    onClickCol(event: PointerEvent) {
        if (this.isSelectionColumn && this.isSelectionDisabled) return;

        event.preventDefault();
        emit(this, "atlas-table-col-click", { trackDisable: true });
    }

    getSortingIcon(sort: TableSort) {
        switch (sort) {
            case "asc":
                return "chevron-up";
            case "desc":
                return "chevron-down";
            default:
                return "sort";
        }
    }

    renderColumnLabel() {
        return when(
            this._deviceController.isMobile && this.columnLabel,
            () => html` <span class="column-label"> ${this.columnLabel} </span> `
        );
    }

    renderContent() {
        return when(
            this.isHeaderColumn && !this.isSelectionColumn,
            () => html`
                <atlas-text size="xs" muted>
                    <slot></slot>
                </atlas-text>
                ${this.renderPopoverInfoIcon()}
                <atlas-icon name=${this.getSortingIcon(this.sort)} size="2x" theme="secondary"></atlas-icon>
            `,
            () => html`
                <atlas-text
                    size="sm"
                    class="column-content"
                    ?ellipsis=${this.ellipsis && !this._deviceController.isMobile}
                >
                    <slot></slot>
                </atlas-text>
            `
        );
    }

    renderElement() {
        const columnClass = {
            "atlas-table-column": true,
            "header-column": this.isHeaderColumn,
            "is-selection": this.isSelectionColumn,
            "is-selection-disabled": this.isSelectionDisabled,
            "is-actions": this.isActionColumn,
            "has-visibility-icon": this.hasVisibilityIcon,
            "sortable": this.sortable,
            "ellipsis": this.ellipsis,
            [`size-${this.size}`]: !!this.size && this.isHeaderColumn && !this.isSelectionColumn && !this.isActionColumn
        };

        if (this.isHeaderColumn || this.isSelectionColumn || this.isActionColumn) {
            return html`
                <button
                    class=${classMap(columnClass)}
                    @click=${this.onClickCol}
                    tabindex=${!this.sortable ? "-1" : "1"}
                >
                    ${this.renderContent()}
                </button>
            `;
        }

        if (this.hasVisibilityIcon) {
            return html`
                <button class=${classMap(columnClass)} @click=${this.onClickCol} tabindex="-1">
                    ${this.renderVisibilityIcon()}
                </button>
            `;
        }

        return html` <div class=${classMap(columnClass)}>${this.renderColumnLabel()}${this.renderContent()}</div> `;
    }

    renderSkeleton() {
        return html`
            <div class="atlas-table-column">
                <div class="column-content skeleton"></div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-table-col": AtlasTableCol;
    }
}
