import { html, nothing } from "lit";
import { customElement, property, queryAssignedElements, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import Cookies from "js-cookie";

import AtlasElement, { type AtlasElementProps } from "@/components/atlas-element";
import type AtlasButtonBase from "@/components/display/atlas-button/atlas-button-base";
import type AtlasSidebarMenuContext from "@/components/structure/atlas-sidebar-menu-context/atlas-sidebar-menu-context";
import type AtlasSidebarMenuItem from "../atlas-sidebar-menu-item/atlas-sidebar-menu-item";
import DeviceController from "@/controllers/device-controller";
import { Watch } from "@/decorators/watch";
import { getAssetPath } from "@/helpers/base-path";
import { SIDEBAR_COLLAPSE_COOKIE } from "@/internals/cookies";
import { emit } from "@/internals/events";
import { getProductInfo, type Product } from "@/internals/product-info";
import { isEmptySlot } from "@/internals/slot";

import type { AtlasNewSidebarView } from "./types";
import styles from "./atlas-new-sidebar.scss";

import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-icon-button/atlas-icon-button";
import "@/components/display/atlas-text/atlas-text";
import "@/components/layout/atlas-layout/atlas-layout";
import "@/components/structure/atlas-sidebar-menu/atlas-sidebar-menu";

export type SidebarProps = AtlasElementProps & {
    "product": Product;
    "product-logo": string;
    "product-logo-small": string;
    "home-path": string;
    "collapsed": boolean;
    "default-collapsed": boolean;
};

/**
 * @dependency atlas-heading
 * @dependency atlas-icon
 * @dependency atlas-icon-button
 * @dependency atlas-layout
 * @dependency atlas-sidebar-menu
 * @dependency atlas-text
 *
 * @slot default - Slot padrão, utilizado para definir os elementos (atlas-sidebar-menu-context) que serão exibidos na barra lateral
 *
 * @event {CustomEvent} atlas-sidebar-toggle - Evento disparado quando a sidebar é colapsada ou expandida
 *
 * @tag atlas-new-sidebar
 */
@customElement("atlas-new-sidebar")
export default class AtlasNewSidebar extends AtlasElement {
    static styles = styles;

    /** O produto do Asaas que está renderizando a sidebar */
    @property({ type: String }) product: Product = "asaas";

    /** Logo que será exibida na versão expandida da sidebar (Caso não seja definida, usa a logo padrão do produto) */
    @property({ type: String, attribute: "product-logo" }) productLogo: string;

    /** Logo que será exibida na versão colapsada da sidebar (Caso não seja definida, usa a logo padrão do produto) */
    @property({ type: String, attribute: "product-logo-small" }) productLogoSmall: string;

    /** Link da página inicial da aplicação, é aberto ao clicar na logo presente na sidebar */
    @property({ type: String, attribute: "home-path" }) homePath: string;

    /** Indica que a barra lateral está recolhida */
    @property({ type: Boolean, reflect: true }) collapsed = false;

    /** Indica que a barra lateral iniciará recolhida por padrão */
    @property({ type: Boolean, attribute: "default-collapsed" }) defaultCollapsed = false;

    @queryAssignedElements({ selector: "atlas-sidebar-menu-context" })
    private _slottedMenuContexts!: AtlasSidebarMenuContext[];

    @state() private _view: AtlasNewSidebarView = "menu-context";

    @state() private _visibleMenuContext: AtlasSidebarMenuContext | null = null;

    @state() private _hasSlottedHeader = false;

    private _activeMenuContext: AtlasSidebarMenuContext | null = null;

    private _lastVisibleMenuContext: AtlasSidebarMenuContext | null = null;

    private _deviceController: DeviceController;

    public constructor() {
        super();

        this.bindMethods();
        this._deviceController = new DeviceController(this, this.onChangeScreenType);
    }

    public connectedCallback(): void {
        super.connectedCallback?.();

        this.addEventListener("atlas-sidebar-menu-context-active", this.onMenuContextActive);
        this.addEventListener("atlas-sidebar-menu-context-click", this.onMenuContextClick);
        this.addEventListener("atlas-sidebar-menu-context-slotted-item-update", this.onSlottedItemUpdateProperties);
        this.addEventListener("atlas-sidebar-menu-context-change-slotted-items", this.onChangeSlottedItems);

        this.onChangeScreenType();
        this.initCookies();
    }

    public disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-sidebar-menu-context-active", this.onMenuContextActive);
        this.removeEventListener("atlas-sidebar-menu-context-click", this.onMenuContextClick);
        this.removeEventListener("atlas-sidebar-menu-context-slotted-item-update", this.onSlottedItemUpdateProperties);
        this.removeEventListener("atlas-sidebar-menu-context-change-slotted-items", this.onChangeSlottedItems);
        document.removeEventListener("click", this.onDocumentClick);
    }

    /**
     * Alterna o estado de recolhimento da barra lateral
     * @param {{ forceState?: boolean, updateCookie?: boolean }} options - Opções para a alteração do estado de recolhimento
     * @param {boolean} options.forceState - Força o estado de recolhimento da barra lateral
     * @param {boolean} options.updateCookie - Atualiza o cookie que armazena o estado de recolhimento da barra lateral
     */
    public toggleCollapse({ forceState, updateCookie }: { forceState?: boolean; updateCookie?: boolean } = {}) {
        const hasForceState = typeof forceState === "boolean";

        const newCollapsedValue = hasForceState ? forceState : !this.collapsed;

        if (updateCookie) this.setCollapsedCookie(newCollapsedValue);

        if (hasForceState && this.collapsed === forceState) return;

        this.collapsed = newCollapsedValue;

        emit(this, "atlas-sidebar-toggle", { detail: { collapsed: this.collapsed } });
    }

    @Watch("collapsed")
    public async onCollapsedChange() {
        await this.updateComplete;

        this.updateActionButtonAttributes();
        this.syncSidebarCollapsed();
        this.bindDocumentClick();

        const newView = this._deviceController.isMobile || this.collapsed ? "menu-context" : "menu-item";
        const sidebarCollapseDurationMs = newView === "menu-item" ? 0 : 500;

        setTimeout(() => this.setMenuView(newView), sidebarCollapseDurationMs);
    }

    @Watch("_currentMenuContext", true)
    public async onCurrentMenuContextChange() {
        await this.updateComplete;

        this._slottedMenuContexts.forEach((context) => {
            context.toggleAttribute("items-visible", context === this._visibleMenuContext);
        });
    }

    private bindMethods() {
        this.onChangeScreenType = this.onChangeScreenType.bind(this);
        this.onMobileHeaderButtonClick = this.onMobileHeaderButtonClick.bind(this);
        this.onToggleCollapseButtonClick = this.onToggleCollapseButtonClick.bind(this);
        this.onHeaderSlotChange = this.onHeaderSlotChange.bind(this);
        this.onMenuContextActive = this.onMenuContextActive.bind(this);
        this.onMenuContextClick = this.onMenuContextClick.bind(this);
        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onSlottedItemUpdateProperties = this.onSlottedItemUpdateProperties.bind(this);
        this.onChangeSlottedItems = this.onChangeSlottedItems.bind(this);
    }

    private isMenuContextView() {
        return this._view === "menu-context";
    }

    private isMenuItemView() {
        return this._view === "menu-item";
    }

    private getVisibleMenuItems() {
        const visibleMenuItems = this._visibleMenuContext?.getItems() || [];

        const menuItems = visibleMenuItems.map((item) => item.cloneNode(true) as AtlasSidebarMenuItem);

        menuItems.forEach((item) => {
            item.toggleAttribute("in-menu-context", true);
        });

        return menuItems;
    }

    private setMenuView(view: AtlasNewSidebarView, context: AtlasSidebarMenuContext = null) {
        const firstContext = this._slottedMenuContexts[0];
        const displayedContext = context || this._lastVisibleMenuContext || this._activeMenuContext || firstContext;

        this._view = displayedContext === null ? "menu-context" : view;
        this._lastVisibleMenuContext = this._visibleMenuContext;
        this._visibleMenuContext = this._view === "menu-item" ? displayedContext : null;
    }

    private syncSidebarCollapsed() {
        this._slottedMenuContexts?.forEach((context) => {
            context.toggleAttribute("sidebar-collapsed", this.collapsed);
        });
    }

    private async updateActionButtonAttributes() {
        await this.updateComplete;

        if (!this._hasSlottedHeader) return;

        const buttonTagNames = ["ATLAS-BUTTON", "ATLAS-DROPDOWN-BUTTON"];

        const headerSlot = this.shadowRoot.querySelector("slot[name=header]") as HTMLSlotElement;
        const assignedElements = headerSlot?.assignedElements() || [];
        const button = assignedElements.find(({ tagName }) => buttonTagNames.includes(tagName)) as AtlasButtonBase;

        if (!button) return;

        button.toggleAttribute("pill", this.collapsed);
        button.toggleAttribute("block", !this.collapsed);
        button.toggleAttribute("hide-description", this.collapsed);
        button.toggleAttribute("hide-tooltip", !this.collapsed);
        button.toggleAttribute("hide-icon", !this.collapsed);

        if (this.product === "asaas") {
            button.toggleAttribute("split", !this.collapsed);
            button.toggleAttribute("hide-arrow", this.collapsed);
        }
    }

    private bindDocumentClick() {
        if (this._deviceController.isMobile && !this.collapsed) {
            document.addEventListener("click", this.onDocumentClick);
            return;
        }

        document.removeEventListener("click", this.onDocumentClick);
    }

    private initCookies() {
        if (this._deviceController.isMobile) return;

        const cookieValue = this.getCollapsedCookieValue();

        if (cookieValue !== null) {
            this.toggleCollapse({ forceState: cookieValue });
            return;
        }

        this.toggleCollapse({ forceState: this.defaultCollapsed, updateCookie: true });
    }

    private getCollapsedCookieValue() {
        const cookieValue = Cookies.get(SIDEBAR_COLLAPSE_COOKIE.name);

        return typeof cookieValue === "string" ? cookieValue === "true" : null;
    }

    private setCollapsedCookie(value: boolean) {
        Cookies.set(SIDEBAR_COLLAPSE_COOKIE.name, value.toString(), SIDEBAR_COLLAPSE_COOKIE.config);
    }

    private async getMenuItem(item: AtlasSidebarMenuItem) {
        await this.updateComplete;

        if (!this._visibleMenuContext) return null;

        const menu = this.shadowRoot.querySelector("#atlas-sidebar-menu");
        const selector = `atlas-sidebar-menu-item[value="${item.value}"][text="${item.text}"]`;
        const menuItem = menu.querySelector(selector) as AtlasSidebarMenuItem;

        return menuItem;
    }

    private onDocumentClick(event: PointerEvent) {
        if (this.collapsed) return;

        const sidebarElement = this.shadowRoot.querySelector(".atlas-sidebar");

        if (event.composedPath().includes(sidebarElement)) return;

        this.toggleCollapse({ forceState: true, updateCookie: true });
    }

    private onChangeScreenType() {
        this.toggleCollapse({ forceState: this._deviceController.isMobile ? true : !!this.getCollapsedCookieValue() });
    }

    private onMobileHeaderButtonClick() {
        if (this.isMenuContextView()) {
            this.toggleCollapse();
            return;
        }

        this.setMenuView("menu-context");
    }

    private onToggleCollapseButtonClick() {
        this.toggleCollapse({ updateCookie: true });
    }

    private async onHeaderSlotChange() {
        await this.updateComplete;

        this._hasSlottedHeader = !isEmptySlot(this, "header");

        this.updateActionButtonAttributes();
    }

    private onMenuContextActive(event: CustomEvent) {
        const newActiveMenuContext = event.target as AtlasSidebarMenuContext;

        if (this._activeMenuContext && newActiveMenuContext !== this._activeMenuContext) {
            this._activeMenuContext.deactivate();
        }

        this._lastVisibleMenuContext = this._visibleMenuContext;
        this._activeMenuContext = newActiveMenuContext;
        this._visibleMenuContext = newActiveMenuContext;
    }

    private onMenuContextClick(event: CustomEvent) {
        if (this.collapsed) return;

        const context = event.target as AtlasSidebarMenuContext;

        if (context.href && this._deviceController.isMobile) return;

        this.setMenuView("menu-item", context);
    }

    private async onSlottedItemUpdateProperties(event: CustomEvent<{ item: AtlasSidebarMenuItem }>) {
        const { item } = event.detail;

        const menuItem = await this.getMenuItem(item);

        const cloneItem = item.cloneNode(true) as AtlasSidebarMenuItem;
        cloneItem.toggleAttribute("in-menu-context", true);

        menuItem?.replaceWith(cloneItem);
    }

    private onChangeSlottedItems(event: CustomEvent) {
        if (!this._visibleMenuContext || event.target !== this._visibleMenuContext) return;

        this.requestUpdate("_currentMenuContext", this._visibleMenuContext, { hasChanged: () => true });
    }

    private renderMobileHeaderHeading() {
        return when(
            this._deviceController.isMobile && this.isMenuItemView(),
            () => html`<atlas-text theme="primary">Menu Principal</atlas-text>`
        );
    }

    private renderMobileSidebarContent() {
        const contentClass = {
            "atlas-sidebar-mobile-content": true,
            [`${this._view}-view`]: true
        };

        return when(
            this._deviceController.isMobile,
            () => html`
                <div class="atlas-sidebar-mobile-header">
                    <atlas-icon-button
                        icon=${this.isMenuItemView() ? "arrow-left" : "x"}
                        size="3x"
                        theme="primary"
                        @atlas-icon-button-click=${this.onMobileHeaderButtonClick}
                    ></atlas-icon-button>
                    ${this.renderMobileHeaderHeading()}
                </div>
                <div class=${classMap(contentClass)}>
                    <slot class="atlas-sidebar-mobile-menu-context-view"></slot>
                    <div class="atlas-sidebar-mobile-menu-item-view">
                        <atlas-text size="lg" bold theme="primary">${this._visibleMenuContext?.text}</atlas-text>
                        <atlas-sidebar-menu id="atlas-sidebar-menu"> ${this.getVisibleMenuItems()} </atlas-sidebar-menu>
                    </div>
                </div>
            `
        );
    }

    private renderToggleCollapseButton() {
        const buttonClass = {
            "atlas-sidebar-toggle-collapse-button": true,
            "collapsed": this.collapsed
        };

        return when(
            !this._deviceController.isMobile,
            () => html`
                <button class=${classMap(buttonClass)} @click=${this.onToggleCollapseButtonClick}>
                    <atlas-icon name="chevron-left" size="3x" theme="primary"></atlas-icon>
                </button>
            `
        );
    }

    private renderProductLogo() {
        if (this._deviceController.isMobile) return nothing;

        const { alt, logoDefault, logoMini, name, homePage } = getProductInfo(this.product);

        const logoSmallSrc = this.productLogoSmall || getAssetPath(logoMini);
        const logDefaultSrc = this.productLogo || getAssetPath(logoDefault);

        const logoClass = {
            "atlas-sidebar-product-logo-wrapper": true,
            "collapsed": this.collapsed
        };

        return html`
            <div class=${classMap(logoClass)}>
                <a href=${this.homePath || homePage} class="atlas-sidebar-product-logo" title=${name}>
                    <img class="image default" src=${logDefaultSrc} title=${name} alt=${alt} />
                    <img class="image small" src=${logoSmallSrc} title=${name} alt=${alt} />
                </a>
            </div>
        `;
    }

    private renderHeaderSlot() {
        const buttonClass = {
            "atlas-sidebar-header-wrapper": true,
            "collapsed": this.collapsed,
            "has-slotted-header": this._hasSlottedHeader
        };

        return when(
            !this._deviceController.isMobile,
            () => html`
                <div class=${classMap(buttonClass)}>
                    <div class="atlas-sidebar-header">
                        <slot name="header" @slotchange=${this.onHeaderSlotChange}></slot>
                    </div>
                </div>
            `
        );
    }

    private renderFixedSidebar() {
        const fixedSidebarClass = {
            "atlas-fixed-sidebar": true,
            "collapsed": this.collapsed
        };

        return html`
            <div class=${classMap(fixedSidebarClass)}>
                <atlas-layout auto gap="2" justify="center" alignment="center" class="atlas-fixed-sidebar-content">
                    <slot></slot>
                </atlas-layout>
            </div>
        `;
    }

    private renderCollapsibleSidebar() {
        const collapsibleSidebarClass = {
            "atlas-collapsible-sidebar": true,
            "collapsed": this.collapsed
        };

        return html`
            <div class=${classMap(collapsibleSidebarClass)}>
                <atlas-sidebar-menu id="atlas-sidebar-menu" class="atlas-collapsible-sidebar-menu">
                    ${this.getVisibleMenuItems()}
                </atlas-sidebar-menu>
            </div>
        `;
    }

    private renderDesktopSidebarContent() {
        return when(
            !this._deviceController.isMobile,
            () => html`
                ${this.renderProductLogo()} ${this.renderHeaderSlot()} ${this.renderToggleCollapseButton()}
                <atlas-layout inline mobile-inline>
                    ${this.renderFixedSidebar()} ${this.renderCollapsibleSidebar()}
                </atlas-layout>
            `
        );
    }

    /** @internal */
    public render() {
        const sidebarClass = {
            "atlas-sidebar": true,
            "collapsed": this.collapsed,
            "has-slotted-header": this._hasSlottedHeader,
            [`${this._view}-view`]: true
        };

        return html`
            <aside class="${classMap(sidebarClass)}">
                ${this.renderMobileSidebarContent()} ${this.renderDesktopSidebarContent()}
            </aside>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-new-sidebar": AtlasNewSidebar;
    }
}
